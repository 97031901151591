<template>

    <div v-if="loading">
        <Loading :message="loadingMsg"/>
    </div>
    
    <div v-if="!loading">
        <div class="px-3 py-2 -ml-4 -mr-4 mb-3 text-center font-semibold text-pink-800 bg-pink-200 shadow-sm">
            Recent Inventories
        </div>

        <div class="flex items-center justify-center">
            <div class="w-full md:w-2/3">
                <div v-for="migration in migrations" :key="migration.id" class="mb-3 shadow-lg bg-pink-100 rounded-md">
                    <div class="px-2 py-2 shadow-md">
                        <div class="h-1/5 w-full flex justify-between items-center py-1 bg-pink-800 text-pink-50 rounded-t-md">
                            <div class="w-10/12 pl-2 font-bold">
                                {{migration.lot.name}}
                            </div>

                            <div class="w-2/12 pr-2 flex flex-1 justify-end font-bold">
                                {{migration.inventory_amount}}
                            </div>
                        </div>

                        <div class="h-1/5 px-2 pt-2 flex items-center justify-center bg-pink-300 text-pink-800 text-center">
                            <div class="truncate">
                                {{migration.product.name}}
                            </div>

                            <div class="pl-1 font-bold">
                                ({{migration.product.product_code}})
                            </div>
                            
                        </div>

                        <div class="h-1/5 flex items-center justify-start py-1 bg-pink-300 text-pink-900 text-sm font-semibold">
                            <div class="px-2 bg-pink-400 text-pink-50">
                                F - {{migration.from_location.name}}
                            </div>

                            
                        </div>
                        
                        
                        <div class="h-1/5 flex items-center justify-between py-1 bg-pink-300 text-pink-900 text-sm font-semibold">
                            <div class="px-2 bg-pink-500 text-pink-50">
                                T - {{migration.to_location.name}}
                            </div>

                            <div class="pr-2" :class="setStateColor(migration.state)">
                                {{migration.state}}
                            </div>
                        </div>

                        <div class="h-1/5 w-full flex items-center justify-between py-1 bg-pink-600 text-pink-50 font-semibold rounded-b-md">
                            <div @click="openInventory(migration)" class="w-1/2 flex items-center justify-start border-r-2 cursor-pointer">
                                <div class="px-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                                    </svg>
                                </div>

                                <div class="px-3">
                                    View Details
                                </div>

                            </div>

                            <div @click="changeState(migration)" class="w-1/2 flex items-center justify-end cursor-pointer">
                                <div class="px-3">
                                    Change State
                                </div>

                                <div class="px-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                                    </svg>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
    
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import getRecentInventories from '../../composables/inventorycount/getRecentInventories'
import getLotInfo from '../../composables/inventorycount/getLotInfo'
import inventorycount from '../../store/inventorycount'
import Loading from '../Loading.vue'

export default {
    name: 'RecentInventories',
    components: {
        Loading,
    },
    setup(props, context) {

        const loading = ref(false)
        const loadingMsg = ref("Please wait ...")

        const migrations = computed(() => inventorycount.state.migration_list)

        onMounted(async () => {
            loading.value = true            
            await getRecentInventories()
            loading.value = false
            console.log(inventorycount.state.migration_list)
        })

        const setStateColor = (state) => {
            if (state == 'pending' || state == 'processed') {
                return "mr-2 px-2 py-1 bg-pink-700 text-pink-50"
            }

            if (state == 'done') {
                return "mr-2 px-2 py-1 bg-red-700 text-red-50"
            }
        }

        const changeState = (migration) => {

        }

        // lot_details - we will generate from current data, but we shall store the one that used to produce migrations as well
        const openInventory = async (migration) => {

            // location_list?

            inventorycount.state.migration = migration
            inventorycount.state.migration_id = migration.id
            inventorycount.state.showloading = true
            inventorycount.state.breadcrumb = JSON.parse(migration.breadcrumb)
            await getLotInfo(migration.lot.name)
            inventorycount.state.showsearch = false
            inventorycount.state.showproduct = true
            inventorycount.state.current_nav = 'count'    
            inventorycount.state.showloading = false
            inventorycount.state.inv_qty = migration.inventory_amount
            
        }

        return {
            loading,
            loadingMsg,
            migrations,
            setStateColor,
            openInventory,
            changeState
        }

    }   
}
</script>