import { ref } from 'vue'

const getLotsForProduct = (url, product_code, lot_name) => {

    const lotData = ref([])
    const lotError = ref(null)

    const loadLots = async () => {
        try {

            let res = await fetch(url + 'api/v2/product/' + product_code + '/lots/' + lot_name, {
                                    method: "GET",
                                    headers: {
                                        'api_token': localStorage.getItem('api_token'),
                                    }
                                })
            let return_val = await res.json()

            if (return_val.success) {

                lotData.value = return_val

            } else {

                lotError.value = return_val.message

            }
        }
        catch (err) {
            lotError.value = err.message
        }
    }

    return {
        lotData, lotError, loadLots
    }

}

export default getLotsForProduct