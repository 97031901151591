<template>
    <div>
        <div class="text-center text-pink-800">
            Please select your operation for Product
        </div>
        <div class="flex items-center justify-center mt-4">
            <div class="grid grid-cols-1 gap-2">
                <button @click="setCheck()">
                    <div class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        Check Barcode
                    </div>
                </button>
                <button @click="setSave()">
                    <div class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        Save Barcode
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BarcodeScanSave',
    methods: {
        setCheck() {
            this.$emit('barcode:check', true)
        },
        setSave() {
            this.$emit('barcode:save', true)
        }
    }
}
</script>