<template>
    <div class="relative min-h-screen md:flex">
      <!-- nav bar -->
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />
      <!-- content -->
      <div class="flex-1 px-4 py-10">

          <!-- select location starts -->
          
          <div v-if="!location_id">
            <div v-if="!loading && location_ids">
                <div class="text-center text-pink-800">
                    Please select your location for Product Stock
                </div>
                <div class="flex items-center justify-center mt-4">
                    <div class="grid grid-cols-1 gap-2">
                        <button v-for="location_id in location_ids" :key="location_id.id" @click="selectLocation(location_id.id)">
                            <div class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                
                                {{location_id.name}}
                            
                            </div>
                        </button>
                        
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="text-center text-pink-700">
                        ... loading locations, please wait ...
                </div>
            </div>
          </div>

        <!-- select location ends -->

        <!-- input product code starts -->

        <div v-if="location_id && !code_selected">

            <label for="product_code_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-2 rounded-sm shadow-sm mt-4 mb-1">Enter Product Code or Name</label>
            <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                <input type="text" name="product_code_name" id="product_code_name" autocomplete="off" @keyup.enter="fetchProductStock" @input="fetchProducts" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" v-model="product_code" />
            </div>

            <div v-if="filteredProducts && !fetchProductLoading">
                <ul class="text-center text-pink-600 font-bold mt-2">
                    <li v-for="filteredProduct in filteredProducts" :key="filteredProduct._source.product_code" @click="setProduct(filteredProduct._source.product_code)" class="border px-2 py-2 bg-pink-200 rounded shadow-md mt-1">
                        <div>{{filteredProduct._source.product_name}}</div>
                        <div class="text-xs">SKU {{filteredProduct._source.product_code}}</div>
                    </li>
                </ul>
            </div>

            <div v-if="fetchProductLoading" class="flex items-center justify-center py-3 px-6 mt-4 font-bold text-pink-700">
                <div>
                    <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                <div class="px-4">
                    Loading products ...
                </div>
            </div>

        </div>

        

        <!-- input product code ends -->

        <!-- get the stock report for the product starts -->

        <div v-if="location_id && code_selected">

            <div v-if="!productLoading">

                <div v-if="!total_products">


                    <div class="flex items-center justify-between">
                        <div>
                            <button @click="resetButton()" class="bg-pink-600 flex justify-center items-center w-24 rounded-md shadow-sm py-2 px-4 hover:bg-pink-500 hover:text-pink-200 hover:shadow-md">
                                <div class="px-2 text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-pink-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                                    </svg>
                                </div>
                                <div class="pr-4 text-center text-pink-100 font-bold">
                                    Reset
                                </div>
                                
                            </button>
                            
                        </div>
                        <div class="text-center text-pink-800 pr-1">
                            Total Quantity for <span class="font-bold italic">{{product_code}}</span> is <span class="font-bold">{{ total_products }}</span>
                        </div>
                    </div>

                </div>

                <div v-else>
                    <div class="flex items-center justify-between">
                        <div>
                            <button @click="resetButton()" class="bg-pink-600 flex justify-center items-center w-24 rounded-md shadow-sm py-2 px-4 hover:bg-pink-500 hover:text-pink-200 hover:shadow-md">
                                <div class="px-2 text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-pink-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                                    </svg>
                                </div>
                                <div class="pr-4 text-center text-pink-100 font-bold">
                                    Reset
                                </div>
                                
                            </button>
                            
                        </div>
                        <div class="text-center text-pink-800 pr-1">
                            Total Quantity for <span class="font-bold italic">{{product_code}}</span> is <span class="font-bold underline">{{ total_products }}</span>
                        </div>
                    </div>

                    <div class="text-center text-pink-700 px-4 pt-4 pb-2 font-bold">
                        {{product_name}}
                    </div>

                    <div class="mt-2">
                        <div class="text-center text-pink-700 underline font-bold px-4 py-4">
                            Qty Table
                        </div>
                        <div class="grid grid-cols-5 gap-1">

                            <div class="col-span-2 flex items-center justify-center bg-pink-200 px-8 py-4 rounded-sm shadow-sm border-b-2 border-pink-700 font-bold text-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                Lot
                            </div>

                            <div  class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-sm shadow-sm border-b-2 border-pink-700 font-bold text-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                Qty
                            </div>

                            <div  class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-sm shadow-sm border-b-2 border-pink-700 font-bold text-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                Rsr
                            </div>

                            <div  class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-sm shadow-sm border-b-2 border-pink-700 font-bold text-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                Rack
                            </div>
                            
                        </div>

                        <div v-for="product_lot in product_lot_list" :key="product_lot.lot_name">
                            <div class="grid grid-cols-5 gap-1 mt-2">
                                <div class="col-span-2 text-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-semibold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                    <div class="block">{{product_lot.lot_name}}</div>
                                    <div class="block text-xs text-pink-900 font-bold">
                                        Exp: {{product_lot.removal_date}}
                                    </div>
                                    <div class="block text-xs text-pink-800">
                                        Grn: {{product_lot.incoming_date}}
                                    </div>
                                </div> 

                                <div class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                    {{product_lot.quantity}}
                                </div>

                                <div class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-semibold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                    {{product_lot.reserved_quantity}}
                                </div> 

                                <div class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-semibold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                    {{product_lot.rack_id}}
                                </div> 
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div v-else-if="productLoading">
                <div class="text-center text-pink-700 font-bold animate-bounce">
                    ... loading product lots, please wait ...
                </div>
            </div>

        </div>

    </div>

    <!-- get the stock report for the product ends -->


      
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
    name: 'ProductStock',
    components: {
        Navbar,
    },
    props: ['passed_location_id', 'passed_product_code'],
    data() {
        return {
            loading: false,
            goBack: false,
            location_ids: [],
            location_id: false,
            product_code: '',
            filteredProducts: [],
            code_selected: false,
            product_lot_list: [],
            total_products: 0,
            productLoading: false,
            product_name: '',
            fetchProductLoading: false
        }
        
    },

    watch: {
        passed_location_id(newVal, oldVal) {
            this.location_id = newVal
        },
        passed_product_code(newVal, oldVal) {
            this.product_code = newVal
            this.fetchProductStock()
        }
    },

    methods: {

        resetButton() {
            this.code_selected = false
            this.total_products = 0
            this.product_lot_list = []
            this.location_id = false
            this.product_code = ''
            this.product_name = ''
            this.filteredProducts = []
        },

        setProduct(code) {
            this.product_code = code
            this.fetchProductStock()
        },

        async fetchProducts() {

            if (this.product_code.length != 0) {
                this.fetchProductLoading = true
                let url = ''
                if (this.location_id == '13') {
                    url = process.env.VUE_APP_BACKEND_URL
                } else {
                    url = process.env.VUE_APP_STORE_URL
                }
                const res = await fetch(url + 'api/v2/product/'+this.product_code+'/search', {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
                const data = await res.json()
                if (data.success) {
                    this.filteredProducts = data.product_list
                } else {
                    if (data.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        this.$router.push({ name: 'Login' })
                    }
                }
                this.fetchProductLoading = false
            }
        },

        async fetchProductStock() {
            this.code_selected = true
            this.productLoading = true

            let url = ''
            if (this.location_id == '13') {
                url = process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_STORE_URL
            }

            const res = await fetch(url + 'api/v2/product/'+this.location_id+'/'+this.product_code+'/stock', {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
            const data = await res.json()

            if (data.success) {

                this.product_lot_list = data.product_lot_list
                this.total_products = data.quantity
                this.productLoading = false
                this.product_name = data.product_name

            } else {
                if (data.message == 'API Token Invalid') {
                    localStorage.removeItem("api_token");
                    this.$router.push({ name: 'Login' })
                }
            }

        },
        async fetchLocations() {
            // let url = ''
            // if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
            //         url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
            //     } else {
            //         url = process.env.VUE_APP_BACKEND_URL
            //     }
            // const res = await fetch(url + 'api/v2/locations/list', {
            //                     method: "GET",
            //                     headers: {
            //                         'api_token': localStorage.getItem('api_token'),
            //                     }
            //                 })
            // const data = await res.json()
            const data = {
                success: true,
                location_list: [
                    {
                        'id': 13,
                        'name': "WH/Stock"
                    }
                ]
            }
            return data
        },
        selectLocation(location_id) {
            this.location_id = location_id
        }
    },

    async created() {

        if (!localStorage.hasOwnProperty('api_token')) {
            this.$router.push({ name: 'Login' })
        }

        if (this.passed_location_id && this.passed_product_code) {

            this.location_id = this.passed_location_id
            this.product_code = this.passed_product_code
            this.fetchProductStock()

        } else {
            window.scrollTo(0, 0);
            this.loading = true
            const data = await this.fetchLocations()
            if (data.success) {
                this.location_ids = data.location_list
                this.loading = false
            } else {
                this.loading = false
                localStorage.removeItem("api_token");
                this.$router.push({ name: 'Login' })
            }
        }
        
        
        
        if ((window.history.length - this.$root.historyCount) > 0) {
            this.goBack = true
        }
    }
}
</script>