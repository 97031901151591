<template>

    <div class="relative min-h-screen md:flex">
      <!-- nav bar -->
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />
      <!-- content -->
      <div class="flex-1 px-4 py-10">
        <div v-if="!loading">
            <div v-if="!posted">
                <div v-if="error" class="text-center text-pink-700 bg-pink-200 rounded mb-4 px-4 py-3">
                    {{ message }}
                </div>
                <label for="lot_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mb-1">Lot Name</label>
                <div class=" border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                    <input type="text" name="lot_name" id="lot_name" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_name" disabled="1" />
                </div>

                <label for="product_code" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Product Code</label>
                <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                    <input type="text" name="product_code" id="product_code" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="product_code" disabled="1" />
                </div>

                <label for="rackid" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Rack</label>
                <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                    <input type="text" name="rackid" id="rackid" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="rackid" disabled="1" />
                </div>

                <label for="current_quantity" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Current Lot Quantity</label>
                <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                    <input type="text" name="current_quantity" id="current_quantity" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="current_quantity" disabled="1" />
                </div>

                <label for="qty" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Inventoried Quantity</label>
                <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                    <input type="text" name="inventoried_quantity" id="inventoried_quantity" autocomplete="off" class="block w-full border-0 p-0 bg-white text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" v-model="quantity" @focus="error=false"/>
                </div>

                

                <!-- <label for="current_reserve" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Current Lot Reserve</label>
                <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                    
                    <input type="text" name="current_reserve" id="current_reserve" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="current_reserve" disabled="1" />
                </div> -->

                <!-- add product information component -->
                <!-- do you really need to fetch these data again? we already have it -->
                <GetProductLotDetails :product="product_code" :lot="lot_name" :lotLoading="lotLoading" :error="lotError" :message="lotMessage" :product_lot_list="product_lot_list" :total_lots="total_lots" :total_product_quantity="total_product_quantity" :product_name="product_name" />

                <!-- add recent inventory information -->
                <GetProductRecentInventory :lot="lot_name" :recentLoading="lotLoading" :error="lotError" :message="lotMessage" :recent_inventory="recent_inventory" :total_recent_inventory="total_recent_inventory" :total_recent_inventoried_quantity="total_recent_inventoried_quantity" />

                <div>
                    <button @click="submitLotDetails" class="w-full bg-pink-600 text-pink-100 px-8 py-4 rounded-lg shadow-sm mt-4 font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">Submit</button>
                </div>

            </div>

            <div v-else class="bg-pink-200 text-pink-900 px-8 py-4 rounded-md shadow-xl">
                <div class="text-center py-2 font-bold text-2xl">
                    Inventory count request is posted!
                </div>

                <div class="text-center font-semibold py-3 mt-3">
                    Current stat for the Product Code : {{ product_code }}
                </div>

                <div class="text-center font-bold">
                    Total Available Quantity : {{ total_available_quantity}}
                </div>

                <div class="text-center font-bold">
                    Total Inventoried Quantity : {{ total_inventoried_quantity}}
                </div>

                <div v-if="total_difference > 0" class="text-center py-1">
                    You should inventory <strong>{{abs_difference}}</strong> more!
                </div>

                <div v-if="total_difference < 0" class="text-center py-1">
                    You have found <strong>{{abs_difference}}</strong> more products than the inventory originally had! Please make sure, you haven't made double entries. 
                </div>

                <div v-if="total_difference == 0" class="text-center py-1">
                    Inventory has no difference till far, do we have anymore product?
                </div>

                <div class="text-center py-2">
                    <button @click="goBackButton" class="bg-pink-600 text-pink-100 rounded-md shadow-md mt-4 px-6 py-2 font-bold hover:bg-pink-700 hover:text-pink-50 hover:shadow-lg">Go Back</button>
                </div>
            </div>
        </div>
        <div v-else-if="loading">
            <div class="text-center text-pink-700 font-bold animate-bounce">
                ... posting inventory count, please wait ...
            </div>
        </div>
      </div>
    </div>

</template>

<script>
import Navbar from '../components/Navbar.vue'
import GetProductLotDetails from './GetProductLotDetails.vue'
import GetProductRecentInventory from './GetProductRecentInventory.vue'

export default {
    name: 'LotDetails',
    components: {
        Navbar,
        GetProductLotDetails,
        GetProductRecentInventory,
    },
    props: [
        'lot_name',
        'rackid',
        'product_code',
        'location_id'
    ],
    data() {
        return {
            quantity: 0,
            current_quantity: 0,
            current_reserve: 0,
            error: false,
            message: '',
            posted: true,
            loading: false,
            goBack: false,
            recent_inventory: [],
            project_id: 0,
            total_available_quantity: 0.0,
            total_inventoried_quantity: 0.0,
            total_difference: 0.0,
            abs_difference: 0.0,
            lotLoading: false, // we need to pass this to GetProductLotDetails & GetProductRecentInventory
            lotError: false, // we need to pass this to GetProductLotDetails & GetProductRecentInventory
            lotMessage: '', // we need to pass this to GetProductLotDetails & GetProductRecentInventory
            product_lot_list: [], // we need to pass this to GetProductLotDetails
            total_lots: 0, // we need to pass this to GetProductLotDetails
            total_product_quantity: 0, // we need to pass this to GetProductLotDetails
            product_name: '', // we need to pass this to GetProductLotDetails
            recent_inventory: [], // we need to pass this to GetProductRecentInventory
            total_recent_inventory: 0, // we need to pass this to GetProductRecentInventory
            total_recent_inventoried_quantity: 0, // we need to pass this to GetProductRecentInventory
            
        }
    },
    watch: {
        quantity(newVal, oldVal) {
            // check if we have the lot in the list or not
            this.total_recent_inventoried_quantity = 0
            let check = 0
            this.recent_inventory.forEach((lot) => {
                if (lot.lot_id == this.lot_name) {
                    lot.qty = newVal
                    check = 1
                }
                if (lot.qty.length !== 0) {
                    this.total_recent_inventoried_quantity += parseInt(lot.qty)
                }
            });

            if (check == 0) {
                // we add the lot in the array
                const lot_dict = {
                    'lot_id': this.lot_name,
                    'rack_id': this.rackid,
                    'qty': this.quantity,
                }
                this.recent_inventory.push(lot_dict)
                this.total_recent_inventory = this.recent_inventory.length
                this.total_recent_inventoried_quantity = parseInt(this.total_recent_inventoried_quantity) + parseInt(this.quantity)
            }

        },
    },
    methods: {
        goBackButton() {
            this.$router.go(-1)
        },
        async submitLotDetails() {
            this.loading = true
            const data = {
                        "params": {
                            "product_code": this.product_code,
                            "lot_name": this.lot_name,
                            "rackid": this.rackid,
                            "location_id": this.location_id,
                            "quantity": this.quantity,
                            "project_id": this.project_id,
                        }
                    }
            try {
                let url = ''

                if (this.location_id == '13') {
                    url = process.env.VUE_APP_BACKEND_URL
                } else {
                    url = process.env.VUE_APP_STORE_URL
                }

                const res = await fetch(url + "api/v2/lot/queue", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': '*',
                        'api_token': localStorage.getItem('api_token'),
                    },
                    body: JSON.stringify(data),
                    redirect: 'follow',
                });
                const result = await res.json()
                this.loading = false
                const r = JSON.parse(result.result)

                if (r.success) {
                    this.error = false
                    this.posted = true
                    this.total_available_quantity = r.total_available_quantity
                    this.total_inventoried_quantity = r.total_inventoried_quantity
                    this.total_difference = this.total_available_quantity - this.total_inventoried_quantity
                    this.abs_difference = Math.abs(this.total_difference)
                    window.scrollTo(0, 0);
                } else {
                    if (r.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        this.$router.push({ name: 'Login' })
                    }
                    this.error = true
                    this.message = r.message
                }

            } catch(error) {
                console.log(error)
            }
        },

        async getLotQty() {
            let url = ''

            if (this.location_id == '13') {
                url = process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_STORE_URL
            }

            const res = await fetch(url + 'api/v2/lot/'+this.rackid+'/'+this.location_id+'/'+this.lot_name+'/stock', {
                                            method: "GET",
                                            headers: {
                                                'api_token': localStorage.getItem('api_token'),
                                            }
                                        })
            const data = await res.json()
            return data
        },

        async fetchProductStock() {
            this.lotLoading = true

            let url = ''

            if (this.location_id == '13') {
                url = process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_STORE_URL
            }

            const res = await fetch(url + 'api/v2/product/'+this.location_id+'/'+this.product_code+'/stock', {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
            const data = await res.json()

            this.lotLoading = false

            if (data.success) {
                
                this.product_lot_list = data.product_lot_list
                this.total_lots = this.product_lot_list.length
                this.total_product_quantity = data.quantity
                this.product_name = data.product_name

                this.recent_inventory = data.recent_inventory
                this.total_recent_inventory = this.recent_inventory.length
                this.total_recent_inventoried_quantity = data.total_recent_inventoried_quantity

            } else {

                this.lotError = true
                this.lotMessage = data.message

            }

        }
    },
    async created() {
        if (!localStorage.hasOwnProperty('api_token')) {
            this.$router.push({ name: 'Login' })
        }

        if (!localStorage.hasOwnProperty('project_id')) {
            this.$router.push({ name: 'SelectProject' })
        }

        this.project_id = localStorage.getItem('project_id')
        console.log(this.project_id)

        this.posted = false
        this.error = false
        this.message = ''
        this.loading = false
        const data = await this.getLotQty()
        if (data.success) {
            this.current_quantity = data.current_quantity
            this.current_reserve = data.current_reserve
            this.quantity = data.queued_inventory
        } else {
            if (data.message == 'API Token Invalid') {
                localStorage.removeItem("api_token");
                this.$router.push({ name: 'Login' })
            }
        }

        const prodStock = await this.fetchProductStock()

        if ((window.history.length - this.$root.historyCount) > 0) {
          this.goBack = true
        }
    }
}
</script>