<template>
    <div class="mt-6">

        <div v-if="error" class="bg-pink-200 text-pink-700 rounded-md shadow-sm px-4 py-2 mt-4 text-center font-bold">
            {{message}}
        </div>

        <div v-if="!loading && !error">
            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Barcode</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{barcode_id}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Product Name</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{product_name}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">SKU</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{product_code}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Total Found</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{total_found}}</span>
                </div>
            </div>
        </div>

        <div v-if="loading & !error" class="text-pink-700 text-center py-6 flex justify-center items-center">
            <div>
                <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            <div class="px-4">
                We are checking the barcode ...
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BarcodeShow',
    props: ['barcode_id'],
    data() {
        return {
            product_name: '',
            product_code: '',
            total_found: '',
            error: false,
            message: '',
            loading: false,
        }
    },
    methods: {
        async fetchBarcodeInfo() {
            let url = process.env.VUE_APP_BACKEND_URL

            const res = await fetch(url + 'api/v2/product/'+this.barcode_id+'/barcode', {
                                            method: "GET",
                                            headers: {
                                                'api_token': localStorage.getItem('api_token'),
                                            }
                                        })

            const data = await res.json()

            return data

        },
    },
    async created() {
        this.error = false
        this.message = ''
        this.loading = true
        const data = await this.fetchBarcodeInfo()
        if (data.success) {
            this.product_name = data.product_name
            this.product_code = data.product_code
            this.total_found = data.total_found
        } else {
            this.error = true
            this.message = data.message
        }
        this.loading = false

        // this.product_name = 'Skin Cafe 100% Pure Coconut Oil'
        // this.product_code = '1001'
        // this.total_found = 2
    }
}
</script>