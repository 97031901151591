import { createApp } from 'vue'
import { createStore } from 'vuex'

import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

const store = createStore({
    state() {
        return {
            return_location_id: 0,
            return_location_name: '',
            selected_return_lot: '',
            global_location_id: '',
            backend_url: '',
            current_lot_id: '',
        }
    },
    mutations: {

        setCurrentLotId(state, payload) {
            state.current_lot_id = payload
        },

        setBackendUrl(state, payload) {
            state.backend_url = payload
        },

        setGlobalLocationId(state, payload) {
            state.global_location_id = payload
        },

        setReturnLocationId(state, location) {
            state.return_location_id = location.id
            state.return_location_name = location.name
        },
        resetReturnLocationId(state) {
            state.return_location_id = 0
            state.return_location_name = ''
        },
        resetSelectedReturnLot(state) {
            state.selected_return_lot = ''
        },
        setSelectedReturnLot(state, payload) {
            state.selected_return_lot = payload
        }
    }
})

const app = createApp(App)

app.use(store)

app.use(router).mount('#app')
