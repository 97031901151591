<template>
    <div class="px-10 bg-pink-800 text-md font-bold text-pink-100 w-full text-center py-1 mt-4 mb-1">Lot Stock by Locations</div>
    <div class="hidden sm:grid grid-cols-2 gap-2 text-center text-pink-50 mt-4">
        <!-- table header goes here -->
        <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
            Location
        </div>
        <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
            Stock
        </div>
    </div>

    <!-- stock content goes here -->
    <div v-for="lot_detail in lot_details.stock" :key="lot_detail.location_name" class="grid grid-cols-1 sm:grid-cols-2 gap-1 sm:gap-2 text-center text-pink-50 mt-3 sm:mt-1">
        <label for="location_name" class="inline-block px-10 bg-pink-500 text-sm font-medium text-pink-50 w-full text-center pb-2 pt-1 -mb-2 sm:hidden">Location</label>
        <div v-if="lot_detail.location_id == breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 font-extrabold py-2 sm:py-0.5 px-1 rounded-md">
            <span>
                {{lot_detail.location_name}}
            </span>
        </div>
        <div v-if="lot_detail.location_id != breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md">
            <span>
                {{lot_detail.location_name}}
            </span>
        </div>

        <label for="product_lot_stock" class="inline-block px-10 bg-pink-500 text-sm font-medium text-pink-50 w-full text-center pb-2 pt-1 -mb-2 sm:hidden">Stock</label>
        <div v-if="lot_detail.location_id != breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md">
            <span>{{lot_detail.stock}}</span>
        </div>

        <div v-if="lot_detail.location_id == breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 font-extrabold py-2 sm:py-0.5 px-1 rounded-md">
            <span>{{lot_detail.stock}}</span>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import inventorycount from '../../store/inventorycount'

export default {
    name: 'LotStockDetails',
    setup() {

        const lot_details = computed(() => inventorycount.state.lot_details)

        const breadcrumb = computed(() => inventorycount.state.breadcrumb)

        return {
           lot_details,
           breadcrumb
        }

    }
}
</script>