import { ref } from 'vue'

const getProductMovements = (loc_id, date_span, sale_days_diff) => {

    const result = ref([])
    const error = ref(null)

    let url = ''
    if (loc_id == 13) {
        url = process.env.VUE_APP_BACKEND_URL
    } else {
        url = process.env.VUE_APP_STORE_URL
    }

    const load = async () => {
        try {

            const data = {
                "params": {
                    "date_span": date_span,
                    "sale_days_diff": sale_days_diff
                }
            }

            let res = await fetch(url + 'api/v2/movements/' + loc_id, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'api_token': localStorage.getItem('api_token'),
                                        'Origin': '*'
                                    },
                                    body: JSON.stringify(data),
                                    redirect: 'follow'
                                })

            let return_result = await res.json()
            const r = return_result.result

            if (r.success) {

                result.value = r

            } else {

                error.value = r.message

            }
        }
        catch (err) {
            error.value = err.message
        }
    }

    return {
        result, error, load
    }

}

export default getProductMovements