<template>
    <div class="-mt-5 mb-2 px-3 py-2 md:flex justify-center items-center md:justify-end hidden">

        <button @click="setActiveFunc('refresh')" class="flex items-center justify-between text-left px-1 py-2 mr-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">

            <div v-if="refreshing" class="pl-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
            </div>

            <div v-if="!refreshing" class="pl-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
            </div>



            <div class="px-2">
                Refresh
            </div>
        </button>

        <button @click="setActiveFunc('stock')" class="text-left px-3 py-2 mr-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Stock
        </button>

        <button @click="setActiveFunc('count')" class="text-left px-3 py-2 mr-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Count
        </button>

        <button @click="setActiveFunc('transfer')" class="text-left px-3 py-2 mr-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Transfer
        </button>

        <button @click="setActiveFunc('requisition')" class="text-left px-3 py-2 mr-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Requisition
        </button>

        <button @click="setActiveFunc('recent')" class="text-left px-3 py-2 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Recent
        </button>
    </div>

    

    <div class="-mt-7 mb-2 md:hidden">
        <button @click="setActiveFunc('refresh')" class="flex items-center justify-between w-full text-left px-1 py-2 mb-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            <div class="px-2">
                Refresh
            </div>
            <div v-if="refreshing" class="pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
            </div>

            <div v-if="!refreshing" class="pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
            </div>
        </button>

        <button @click="setActiveFunc('stock')" :class="{ hidden : current_nav != 'stock' && shrinkAll}" class="w-full text-left px-3 py-2 mb-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Stock
        </button>

        <button @click="setActiveFunc('count')" :class="{ hidden : current_nav != 'count' && shrinkAll}" class="w-full text-left px-3 py-2 mb-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Count
        </button>

        <button @click="setActiveFunc('transfer')" :class="{ hidden : current_nav != 'transfer' && shrinkAll}" class="w-full text-left px-3 py-2 mb-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Transfer
        </button>

        <button @click="setActiveFunc('requisition')" :class="{ hidden : current_nav != 'requisition' && shrinkAll}" class="w-full text-left px-3 py-2 mb-1 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Requisition
        </button>

        <button @click="setActiveFunc('recent')" :class="{ hidden : current_nav != 'recent' && shrinkAll}" class="w-full text-left px-3 py-2 bg-pink-100 text-pink-700 font-bold hover:bg-pink-200 hover:text-pink-900">
            Recent
        </button>
    </div>
</template>

<script>

import { ref, watch, computed } from 'vue'
import inventorycount from '../../store/inventorycount'
import getLocations from '../../composables/inventorycount/getLocations'

export default {
    name: 'InventoryHeader',
    emits: ["showsearchIH", "showproductIH"],
    setup(props, context) {
        const current_nav = computed(() => inventorycount.state.current_nav)

        const currentActive = ref('')
        const shrinkAll = ref(true)

        const requisitionActive = ref(false)

        const refreshing = ref(false)

        const setActiveFunc = async (button_val) => {

            // you shall reset the shared reactive object values, because the data should change on new navigation

            if (button_val == 'refresh') {

                refreshing.value = true

                inventorycount.state.current_nav = 'count'
                inventorycount.state.filteredLots = []
                inventorycount.state.breadcrumb = [{
                    'id': 13,
                    'name': 'WH/Stock'
                }];
                await getLocations('13')

                shrinkAll.value = !shrinkAll.value
                inventorycount.state.lot_details = []

                inventorycount.state.showproduct = false
                inventorycount.state.showsearch = false

                context.emit("showsearchIH", {"status": false})
                context.emit("showproductIH", {"status": false})

                refreshing.value = false
                
            } else {
                if (button_val == current_nav.value) {

                    shrinkAll.value = !shrinkAll.value
                    
                } else {
                    
                    shrinkAll.value = true

                }

                if (button_val == 'count') {
                    inventorycount.state.showproduct = false
                    inventorycount.state.showsearch = false

                    context.emit("showsearchIH", {"status": false})
                    context.emit("showproductIH", {"status": false})

                    inventorycount.state.breadcrumb = [{
                        'id': 13,
                        'name': 'WH/Stock'
                    }];
                    await getLocations('13')
                }

                inventorycount.state.current_nav = button_val
            }
            
            
        }



        return {
            current_nav,
            setActiveFunc,
            currentActive,
            shrinkAll,
            refreshing,
        }
    }
}

</script>