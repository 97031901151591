import { ref } from 'vue'

const getProductLotByLocation = (url, loc_id, product_code) => {

    const data = ref([])
    const error = ref(null)

    const load = async () => {
        try {

            let res = await fetch(url + 'api/v2/product/' + loc_id +'/' + product_code + '/stock', {
                                    method: "GET",
                                    headers: {
                                        'api_token': localStorage.getItem('api_token'),
                                    }
                                })
            let return_val = await res.json()

            if (return_val.success) {

                data.value = return_val

            } else {

                error.value = return_val.message

            }
        }
        catch (err) {
            error.value = err.message
        }
    }

    return {
        data, error, load
    }

}

export default getProductLotByLocation