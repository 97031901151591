<template>
    <div>

        <div v-if="error" class="bg-pink-200 text-pink-700 rounded-md shadow-sm px-4 py-2 mt-4 text-center font-bold">
            {{message}}
        </div>

        <div v-if="lotLoading" class="text-pink-700 text-center py-6 flex justify-center items-center">
            <div>
                <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            <div class="px-4">
                We are loading the lot data ...
            </div>
        </div>

        <div v-if="!posted && !submitLoading && !lotLoading">
            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Lot</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{selected_lot_id}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Product Code</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{product_code}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Product Name</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{product_name}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Location</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{location_name}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Previous Rack</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{rack_ids}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Replace or Add Rack?</span>
                </div>

                <div class="w-2/3 col-span-2 flex items-center py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <select id="rack" name="rack" class="focus:outline-none sm:text-sm rounded-md bg-white w-full" v-model="rack_type">
                        <option disabled value="">Select Option</option>
                        <option>Replace</option>
                        <option>Add</option>
                    </select>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">New Rack</span>
                </div>

                <div class="w-2/3 col-span-2 flex items-center py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <select id="rack" name="rack" class="focus:outline-none sm:text-sm rounded-md bg-white w-full" v-model="selected_rack_id">
                        <option disabled value="">Select Option</option>
                        <option v-for="rack_lot in rack_lots" :key="rack_lot.id">{{rack_lot.name}}</option>
                    </select>
                </div>
            </div>

            <button class="w-full text-center bg-pink-600 mt-4 rounded-md shadow-md px-4 py-2 text-pink-100 font-bold" @click="submitLotRack()">
                Submit
            </button>
        </div>

        <div v-if="submitLoading" class="text-pink-700 text-center py-6 flex justify-center items-center">
            <div>
                <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            <div class="px-4">
                Your request is submitting
            </div>
        </div>

        <div v-if="posted">
            <div class="text-pink-700 text-center py-6 px-4">
                You requested to <strong>{{rack_type}}</strong> rack <strong>{{selected_rack_id}}</strong> for lot <strong>{{selected_lot_id}}</strong> is now submitted.
            </div>
            <button class="w-full bg-pink-600 text-pink-100 font-bold px-4 py-2 mt-2 rounded-md shadow-md" @click="resetAll()">
                Go Back
            </button>
        </div>

        
    </div>
</template>

<script>
export default {
    name: 'RackLotForm',
    props: ['lot_id', 'location_id', 'location_name'],
    data() {
        return {
            product_code: '',
            product_name: '',
            rack_ids: '',
            message: '',
            error: '',
            selected_lot_id: '',
            rack_type: '',
            rack_lots: [],
            selected_rack_id: '',
            posted: false,
            submitLoading: false,
            lotLoading: false
        }
    },
    methods: {
        resetAll() {
            this.$emit('lotrack:reset', true)
        },
        async fetchLotRack() {
            let url = ''
            if (this.location_id == '13') {
                url = process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_STORE_URL
            }

            const res = await fetch(url + 'api/v2/racklots/'+this.location_id+'/rackid/'+this.lot_id, {
                                            method: "GET",
                                            headers: {
                                                'api_token': localStorage.getItem('api_token'),
                                            }
                                        })

            const data = await res.json()

            return data

        },

        async fetchLots() {
            let url = ''
            if (this.location_id == '13') {
                url = process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_STORE_URL
            }

            const res = await fetch(url + 'api/v2/racklots/'+this.location_id, {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
            const data = await res.json()
            return data
        },

        async submitLotRack() {
            this.submitLoading = true
            const data = {
                        "params": {
                            "lot_name": this.selected_lot_id,
                            "product_code": this.product_code,
                            "rack_id": this.selected_rack_id,
                            "location_id": this.location_id,
                            "rack_type": this.rack_type,
                        }
                    }
            try {
                let url = ''

                if (this.location_id == '13') {
                    url = process.env.VUE_APP_BACKEND_URL
                } else {
                    url = process.env.VUE_APP_STORE_URL
                }

                const res = await fetch(url + "api/v2/lot/rack", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': '*',
                        'api_token': localStorage.getItem('api_token'),
                    },
                    body: JSON.stringify(data),
                    redirect: 'follow',
                });
                const result = await res.json()
                const r = JSON.parse(result.result)
                this.submitLoading = false

                if (r.success) {
                    this.posted = true
                    window.scrollTo(0, 0);
                } else {
                    if (r.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        this.$router.push({ name: 'Login' })
                    }
                    this.error = true
                    this.message = r.message
                }

            } catch(error) {
                console.log(error)
            }
        }
    },
    async created() {
        
        // get the rack information from the server, like the current racks
        if (this.lot_id && this.location_id) {
            this.lotLoading = true
            const data = await this.fetchLotRack()
            console.log(data)
            if (data.success) {
                this.selected_lot_id = data.lot_name
                this.product_code = data.product_code
                this.product_name = data.product_name
                this.rack_ids = data.rack_ids

                const lot_data = await this.fetchLots()

                if (lot_data.success) {
                    this.rack_lots = lot_data.rack_lots
                }

            } else {
                this.error = true
                this.message = data.message
            }
            this.lotLoading = false
            
        }
    }
}
</script>