<template>

    <Loading v-if="loading" :message="loadingMsg" />
    <div v-if="message" @click="message = ''" class="px-3 py-2 -ml-4 -mr-4 -mt-2 cursor-pointer bg-red-600 text-red-50 font-bold text-center">{{message}}</div>
    <div v-if="successMsg" @click="successMsg = ''" class="px-3 py-2 -ml-4 -mr-4 -mt-2 cursor-pointer bg-green-700 text-green-50 font-bold text-center">{{successMsg}}</div>

    <div v-if="!loading">

        <RetrieveRequisitions
            v-if="!requisition_requests.length"
            @loadingRR="(n) => loading = n.status"
            @successRR="handleSuccess"
            @errorRR="handleError"
        />

        <div v-if="requisition_requests.length" class="mt-2">
            
            <div>

                <div :class="setGridLentgh()" class="mb-1">

                    <div class="mb-1 px-3 py-2 bg-pink-700 text-pink-50 text-center">
                        ID
                    </div>

                    <div class="mb-1 px-3 py-2 bg-pink-700 text-pink-50 text-center">
                        Count
                    </div>

                    <div @click="downloadRequisitions()" v-if="requisitionListToDownload.length > 0" class="mb-1 px-3 py-2 bg-pink-500 text-pink-50 text-center font-bold cursor-pointer">
                        Download
                    </div>

                    <div v-if="requisitionListToDownload.length == 0" class="mb-1 px-3 py-2 bg-pink-700 text-pink-50 text-center">
                        Download
                    </div>

                    <div @click="sendReceivedRequisitions()" v-if="currentState == 'received' && receivedListToSend.length > 0" class="mb-1 px-3 py-2 bg-pink-500 text-pink-50 text-center font-bold cursor-pointer">
                        Send
                    </div>

                    <div v-if="currentState == 'received' && receivedListToSend.length == 0" class="mb-1 px-3 py-2 bg-pink-700 text-pink-50 text-center">
                        Send
                    </div>

                    <div @click="completeSentRequisitions()" v-if="currentState == 'sent' && sentListToComplete.length > 0" class="mb-1 px-3 py-2 bg-pink-500 text-pink-50 text-center font-bold cursor-pointer">
                        Complete
                    </div>

                    <div v-if="currentState == 'sent' && sentListToComplete.length == 0" class="mb-1 px-3 py-2 bg-pink-700 text-pink-50 text-center">
                        Complete
                    </div>

                </div>

                <div v-for="rr in requisition_requests" :key="rr.id" :class="setGridLentgh()">

                    <div class="mb-1 px-3 py-2 bg-pink-200 text-pink-900 text-center">
                        {{rr.id}}
                    </div>

                    <div class="mb-1 px-3 py-2 bg-pink-200 text-pink-900 text-center">
                        {{rr.count}}
                    </div>

                    <div v-if="requisitionListToDownload.includes(rr.id)" @click="selectRequisition(rr.id)" class="mb-1 px-3 py-2 bg-pink-500 text-pink-50 text-center font-bold cursor-pointer">
                        Select
                    </div>

                    <div v-if="!requisitionListToDownload.includes(rr.id)" @click="selectRequisition(rr.id)" class="mb-1 px-3 py-2 bg-pink-200 text-pink-900 text-center cursor-pointer">
                        Select
                    </div>

                    <div v-if="currentState == 'received' && receivedListToSend.includes(rr.id)" @click="selectReceived(rr.id)" class="mb-1 px-3 py-2 bg-pink-500 text-pink-50 text-center font-bold cursor-pointer">
                        Select
                    </div>

                    <div v-if="currentState == 'received' && !receivedListToSend.includes(rr.id)" @click="selectReceived(rr.id)" class="mb-1 px-3 py-2 bg-pink-200 text-pink-900 text-center cursor-pointer">
                        Select
                    </div>

                    <div v-if="currentState == 'sent' && sentListToComplete.includes(rr.id)" @click="selectSent(rr.id)" class="mb-1 px-3 py-2 bg-pink-500 text-pink-50 text-center font-bold cursor-pointer">
                        Select
                    </div>

                    <div v-if="currentState == 'sent' && !sentListToComplete.includes(rr.id)" @click="selectSent(rr.id)" class="mb-1 px-3 py-2 bg-pink-200 text-pink-900 text-center cursor-pointer">
                        Select
                    </div>

                </div>

            </div>

        </div>
        
    </div>
    
</template>

<script>
import { ref, watch } from 'vue'
import Loading from '../../components/Loading.vue'
import RetrieveRequisitions from './RetrieveRequisitions.vue'
import getRequisitionPicking from '../../composables/inventorycount/getRequisitionPicking'
import completeSentRequisition from '../../composables/inventorycount/completeSentRequisition'
import sendReceivedRequisition from '../../composables/inventorycount/sendReceivedRequisition'

export default {
    name: 'Requisitions',
    components: {
        Loading,
        RetrieveRequisitions
    },
    setup() {

        const requisition_requests = ref([])
        const message = ref('')
        const successMsg = ref('')
        const loading = ref(false)
        const loadingMsg = ref('Loading ...')
        const requisitionListToDownload = ref([])
        const move_line_list = ref([])
        const currentState = ref('')
        const receivedListToSend = ref([])
        const sentListToComplete = ref([])

        watch([message, successMsg], (n, o) => {
            setTimeout(() => {
                message.value = ''
                successMsg.value = ''
            }, 5000)
        })

        const setGridLentgh = () => {
            if (currentState.value == 'pending') {
                return "grid grid-cols-3 gap-1"
            } else {
                return "grid grid-cols-4 gap-1"
            }
        }

        const handleSuccess = (n) => {
            successMsg.value = n.msg
            requisition_requests.value = n.requisition_requests
            currentState.value = n.state

            if (requisition_requests.value.length == 0) {
                alert("Sorry, you do not have any requisition in this state")
            }
        }

        const handleError = (n) => {
            message.value = n.msg
        }

        const selectRequisition = (n) => {
            if (!requisitionListToDownload.value.includes(n)) {
                requisitionListToDownload.value.push(n)
            } else {
                requisitionListToDownload.value = requisitionListToDownload.value.filter((x) => x != n)
            }
        }

        const selectReceived = (n) => {
            if (!receivedListToSend.value.includes(n)) {
                receivedListToSend.value.push(n)
            } else {
                receivedListToSend.value = receivedListToSend.value.filter((x) => x != n)
            }
        }

        const selectSent = (n) => {
            if (!sentListToComplete.value.includes(n)) {
                sentListToComplete.value.push(n)
            } else {
                sentListToComplete.value = sentListToComplete.value.filter((x) => x != n)
            }
        }

        const downloadRequisitions = async () => {

            loading.value = true

            let move_line_data = []
            
            try {
                for (const x of requisitionListToDownload.value) {
                    const result = await getRequisitionPicking(x)
                    move_line_data = [...result, ...move_line_data]
                }
            } catch(err) {
                console.log(err)
            }
        
            loading.value = false
            move_line_list.value = move_line_data

            console.log(move_line_list.value)

            let csvContent = "";
                
            let row = "Product, From, To, Lot, Qty"
            csvContent += row + "\r\n"

            let title = ''

            move_line_list.value.forEach((move_line) => {

                title = String(move_line.id) + '.csv'

                let product_name = move_line.product.name
                // replace " to '
                while(1) {
                    if (product_name.includes('"')) {
                        product_name = product_name.replace('"', "'")
                    } else {
                        break
                    }
                }

                // replace all new line to space
                while(1) {
                    if (product_name.includes('\n')) {
                        product_name = product_name.replace('\n', " ")
                    } else {
                        break
                    }
                }

                let row = '"' + product_name + '",' + move_line.location.from + ',' + move_line.location.to + ',"=""' + move_line.lot.name + '""",' + move_line.qty
                csvContent += row + "\r\n"
            })

            var contentType = 'text/csv';
            var csvFile = new Blob([csvContent], {type: contentType});

            // download
            var link = document.createElement("a");
            link.setAttribute("href", window.URL.createObjectURL(csvFile));
            link.setAttribute("download", title);
            link.click()
        }

        const sendReceivedRequisitions = async () => {

            loading.value = true

            try {
                for (const x of receivedListToSend.value) {
                    await sendReceivedRequisition(x)
                }
                alert("Request sent")
            } catch(err) {
                console.log(err)
            }

            loading.value = false

        }

        const completeSentRequisitions = async () => {

            loading.value = true

            try {
                for (const x of sentListToComplete.value) {
                    await completeSentRequisition(x)
                }
                alert("Request completed")
            } catch(err) {
                console.log(err)
            }

            loading.value = false

        }
        

        return {
            message,
            loading,
            loadingMsg,
            successMsg,
            requisition_requests,
            handleSuccess,
            handleError,
            requisitionListToDownload,
            selectRequisition,
            downloadRequisitions,
            setGridLentgh,
            currentState,
            selectReceived,
            receivedListToSend,
            sendReceivedRequisitions,
            sentListToComplete,
            selectSent,
            completeSentRequisitions
        }

    }
}
</script>