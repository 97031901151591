<template>

<div class="relative min-h-screen md:flex">
      <!-- nav bar -->
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />
      <!-- content -->
      <div class="flex-1 px-4 py-10">
        <div v-if="!loading">
            <div class="text-center text-pink-700 font-bold">You have recently counted total {{total_queue}} inventory.</div>

            <div class="grid grid-cols-5 gap-1 mt-4 text-center">
                <div class="bg-pink-200 text-pink-800 rounded-md p-1 font-bold col-span-2">
                    Lot
                </div>
                <div class="bg-pink-200 text-pink-800 rounded-md p-1 font-bold">
                    Rack
                </div>
                <div class="bg-pink-200 text-pink-800 rounded-md p-1 font-bold">
                    Qty
                </div>
                <div class="bg-pink-200 text-pink-800 rounded-md p-1 font-bold">
                    Action
                </div>
            </div>
            <div v-for="queue in queueList" :key="queue.lot_id" class="grid grid-cols-5 gap-1 mt-1 text-center">
                <div class="bg-pink-200 text-pink-800 rounded-md p-2 col-span-2">
                    {{queue.lot_id}}
                </div>

                <div class="bg-pink-200 text-pink-800 rounded-md p-2">
                    {{queue.rack_id}}
                </div>

                <div class="bg-pink-200 text-pink-800 rounded-md p-2">
                    {{queue.qty}}
                </div>

                <div class="bg-pink-200 text-pink-800 rounded-md p-2">

                    <router-link :to="{ name: 'LotDetails', query: { lot_name: queue.lot_id, rackid: queue.rack_id, product_code: queue.product_id, location_id: queue.location_id } }" class="hover:text-pink-600 rounded transition duration-200 font-bold">
                        Edit
                    </router-link>

                </div>

            </div>


        </div>
        <div v-else-if="loading">
            <div class="text-center text-pink-700 font-bold animate-bounce">
                ... getting recent inventory data, please wait ...
            </div>
        </div>
      </div>
    </div>
    
</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
    name: 'RecentInventory',
    components: {
        Navbar,
    },
    props: [],
    data() {
        return {
            goBack: false,
            loading: false,
            queueList: [],
            total_queue: 0,
        }
    },

    methods: {
        goBackButton() {
            this.$router.go(-1)
        },
        async fetchRecent() {
            let url = ''
            if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                    url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
                } else {
                    url = process.env.VUE_APP_BACKEND_URL
                }
            const res = await fetch(url + 'api/v2/user/queues', {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
            const data = await res.json()
            return data
        },
    },

    async created() {
        if (!localStorage.hasOwnProperty('api_token')) {
            this.$router.push({ name: 'Login' })
        }

        if ((window.history.length - this.$root.historyCount) > 0) {
          this.goBack = true
        }

        window.scrollTo(0, 0);
        this.loading = true
        const data = await this.fetchRecent()
        if (data.success) {
            this.queueList = data.queue_list
            this.loading = false
            this.total_queue = data.total_queue
            console.log(this.queueList)
        } else {
            this.loading = false
            localStorage.removeItem("api_token");
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>