<template>

    <div v-if="message" @click="resetMessage()" class="px-3 py-2 -ml-4 -mr-4 -mt-2 cursor-pointer bg-red-600 text-red-50 font-bold text-center">
        {{message}}
    </div>

    <div v-if="successMsg" @click="resestSuccessMsg()" class="px-3 py-2 -ml-4 -mr-4 -mt-2 cursor-pointer bg-green-700 text-green-50 font-bold text-center">
        {{successMsg}}
    </div>

    <div v-if="loading && !message">
        <Loading :message="loadingMsg" />
    </div>

    <div v-if="!fromLoc && !loading">
        <ChooseFromLocation 
            @fromlocationselected="setFromLocSelect"
            @transferqty="(n) => transfer_qty = n.amount"
        />
    </div>

    <div v-if="fromLoc && !loading">
        
        <ChooseToLocation />

        <div v-if="location_list.length == 0" class="">

            <CompleteTransfer :fromloc="from_loc_name" :destloc="dest_loc_name" :transferqty="transfer_qty"/>
            
        </div>
    </div>
    
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import ChooseFromLocation from './ChooseFromLocation.vue'
import ChooseToLocation from './ChooseToLocation.vue'
import CompleteTransfer from './CompleteTransfer.vue'
import Loading from '../Loading.vue'
import inventorycount from '../../store/inventorycount'
import status from '../../store/status'
import getLocations from '../../composables/inventorycount/getLocations'
import getLocName from '../../composables/inventorycount/getLocName'

export default {
    name: 'InventoryTransfer',
    components: {
        ChooseFromLocation,
        ChooseToLocation,
        CompleteTransfer,
        Loading
    },  
    setup() {        
        const location_list = computed(() => inventorycount.state.location_list)
        const breadcrumb = computed(() => inventorycount.state.breadcrumb)
        const lot_details = computed(() => inventorycount.state.lot_details)
        const fromLoc = computed(() => status.state.fromloc)
        const transfer_qty = ref(null)
        const dest_loc_name = ref(null)
        const from_loc_name = ref(null)

        const loading = computed(() => status.state.transferloading)
        const message = computed(() => status.state.message)
        const successMsg = computed(() => status.state.successmsg)

        const loadingMsg = ref('Loading ...')

        const resetMessage = () => {
            status.state.message = ''
        }

        const resestSuccessMsg = () => {
            status.state.successmsg = ''
        }

        watch(message, () => {
            setTimeout(() => {
                resetMessage()
            }, 5000)
        })

        watch(successMsg, () => {
            setTimeout(() => {
                resestSuccessMsg()
            }, 3000)
            
        })

        watch(location_list, async (n, o) => {
            if (location_list.value.length == 0) {
                // add try catch
                status.state.transferloading = true
                dest_loc_name.value = await getLocName(breadcrumb.value[breadcrumb.value.length - 1].id)
                from_loc_name.value = await getLocName(inventorycount.state.from_location_id)
                status.state.transferloading = false
            }
        })

        onMounted( async () => {
            inventorycount.state.filteredLots = []
            inventorycount.state.breadcrumb = [{
                'id': 13,
                'name': 'WH/Stock'
            }];
            await getLocations('13')
            console.log("test")
            status.state.fromloc = false
        });

        const setFromLocSelect = (n) => {
            status.state.fromloc = n.status
        }

        return {
            fromLoc,
            location_list,
            transfer_qty,
            lot_details,
            dest_loc_name,
            from_loc_name,
            setFromLocSelect,
            loading,
            loadingMsg,
            message,
            successMsg,
            resetMessage,
            resestSuccessMsg
        }

    }
}
</script>