<template>
    <div>
        <div class="grid grid-cols-6 md:grid-cols-4 mt-4 text-gray-400 rounded-lg border border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
            <div class="col-span-2 md:col-span-1 px-4 flex items-center justify-start border-0 focus:outline-none">
                <span class="">Lot</span>
            </div>

            <div class="col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none">
                <input class="w-full h-full focus:outline-none text-center text-pink-700" name="lot_id" type="text" autocomplete="off" v-model="lot_id" @keyup.enter="setLotId(lot_id)" />
            </div>

            <div class="flex items-center justify-end px-4 col-span-2 md:col-span-1 border-0 focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RackLotSearch',
    data() {
        return {
            lot_id: '',
        }
    },
    watch: {
        lot_id(newVal, oldVal) {
            // when a change happens, we send this to SetRackLot component
            this.$emit("lotid:changed", newVal)
        }
    },
    methods: {

        setLotId(lot_id) {
            // console.log(lot_id)
            // if you press enter, lot_id is set to SetRackLot component
            this.$emit("lotid:selected", lot_id)
        }
    }
}
</script>