<template>
    <div class="text-pink-800 text-center pb-4">
        Enter your Lot
    </div>
    <div class="grid grid-cols-6 md:grid-cols-4 text-gray-400 rounded-lg border border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
        <div class="col-span-2 md:col-span-1 px-4 flex items-center justify-start border-0 focus:outline-none">
            <span class="">Lot</span>
        </div>

        <div class="col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none">
            <input class="w-full h-full focus:outline-none text-center text-pink-700" name="lot_id" type="text" autocomplete="off" v-model="lot_key" @keyup.enter="getProductDetails(lot_key)" />
        </div>

        <div class="flex items-center justify-end px-4 col-span-2 md:col-span-1 border-0 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
        </div>
    </div>

    <div>
        <div v-if="filteredLots">
            <ul class="text-center text-pink-600 font-bold mt-2">
                <li v-for="filteredLot in filteredLots" :key="filteredLot._source.lot_id" @click="getProductDetails(filteredLot._source.lot_name)" class="border py-2 bg-pink-200 rounded shadow-md mt-1">
                    <div>{{filteredLot._source.lot_name}}</div>
                    <div class="text-xs">SKU {{filteredLot._source.product_code}}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { computed, watch, ref } from 'vue'
import inventorycount from '../../store/inventorycount'
import getLotSearchResult from '../../composables/inventorycount/getLotSearchResult'
import getLotInfo from '../../composables/inventorycount/getLotInfo'

export default {
    name: 'LotSearch',
    emits: ["loading:status", "showproduct", "showsearch", "lot:name", "getlotinfo"],
    props: ["defaultlot"],
    setup(props, context) {

        const filteredLots = computed(() => inventorycount.state.filteredLots)

        const defaultLot = computed(() => props.defaullot)

        const lot_key = ref('')

        watch(lot_key, async (newVal, prevVal) => {

            await getLotSearchResult(lot_key.value)

        })

        watch(defaultLot, (newVal, prevVal) => {

            lot_key.value = defaultLot

        })

        const getProductDetails = async (lot_name) => {

            // this is being done for new migration, so clear all old migration_id in the storage
            inventorycount.state.migration_id = 0
            inventorycount.state.migration = {}
            inventorycount.state.inv_qty = 0

            context.emit("loading:status", {"status": true})
            await getLotInfo(lot_name)
            context.emit("loading:status", {"status": false})

            // for counting
            context.emit("showproduct", {"status": true})
            context.emit("showsearch", {"status": false})

            // for transfer
            context.emit("lot:name", lot_name)
            context.emit("getlotinfo", {"status": true})
        }

        return {
           filteredLots,
           lot_key,
           getProductDetails,
        }

    }
}
</script>