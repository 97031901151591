<template>

    <div>
        <div v-if="message" @click="message = ''" class="px-3 py-2 -ml-4 -mr-4 -mt-2 cursor-pointer bg-red-600 text-red-50 font-bold text-center">
            {{message}}
        </div>

        <div v-if="successMsg" @click="successMsg = ''" class="px-3 py-2 -ml-4 -mr-4 -mt-2 cursor-pointer bg-green-700 text-green-50 font-bold text-center">
            {{successMsg}}
        </div>

        <div v-if="loading && !message">
            <Loading :message="loadingMsg" />
        </div>

        
        <div v-if="!loading && !is_product_details_exist">
            <label for="product_code_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-2 rounded-sm shadow-sm mt-4 mb-1">Enter Product Code or Name</label>
            <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                <input type="text" name="product_code_name" id="product_code_name" autocomplete="off" @keyup.enter="fetchProducts" @input="fetchProducts" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" v-model="product_code" />
            </div>

            <div v-if="filteredProducts">
                <ul class="text-center text-pink-600 font-bold mt-2">
                    <li v-for="filteredProduct in filteredProducts" :key="filteredProduct._source.product_code" @click="setProduct(filteredProduct._source.product_code)" class="border px-2 py-2 bg-pink-200 rounded shadow-md mt-1">
                        <div>{{filteredProduct._source.product_name}}</div>
                        <div class="text-xs">SKU {{filteredProduct._source.product_code}}</div>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="is_product_details_exist && !loading">

            <div class="px-3 py-2 -ml-4 -mr-4 mb-2 bg-pink-500 text-pink-50 font-bold text-center">
                Product Stock
            </div>

            <!-- show form with product basic info component -->
            <InventoryCountFormProduct :product_details_data="product_details"/>

            <!-- show stock by lot by location component -->
            <ProductStockDetailsProductData :product_details_data="product_details"/>

        </div>

    </div>

    
    
</template>

<script>
import { computed, watch, ref } from 'vue'
import inventorycount from '../../store/inventorycount'
import getProducts  from '../../composables/inventorycount/getProducts'
import getProductInfo  from '../../composables/inventorycount/getProductInfo'
import Loading from '../../components/Loading.vue'
import ProductStockDetailsProductData from './ProductStockDetailsProductData.vue'
import InventoryCountFormProduct from './InventoryCountFormProduct.vue'

export default {
    name: 'ShowStocks',
    components: {
        Loading,
        ProductStockDetailsProductData,
        InventoryCountFormProduct,
    },
    setup(props, context) {

        const product_code = ref('')

        const product_code_value = ref('')

        const filteredProducts = ref([])

        const product_details = ref({})

        const is_product_details_exist = computed(() => {
            if (Object.keys(product_details.value).length > 0) {
                return true
            } else {
                return false
            }
        })

        const message = ref('')

        const successMsg = ref('')

        const loading = ref(false)

        const loadingMsg = ref('Loading ...')

        watch(product_code, (n, o) => {
            fetchProducts()
        })
        
        const fetchProducts = async () => {
            if (product_code.value.length > 0) {

                try {
                    filteredProducts.value = await getProducts(product_code.value)
                } catch(e) {
                    message.value = e.message
                }
            }
        }

        const setProduct = async (code) => {
            loading.value = true
            if (code.length > 0) {
                try {
                    
                    product_details.value = await getProductInfo(code)
                    
                    
                } catch (e) {
                    message.value = e.message
                }
                
            }
            loading.value = false
        }

        

        return {
            filteredProducts,
            product_code,
            setProduct,
            fetchProducts,
            loading,
            loadingMsg,
            message,
            successMsg,
            product_details,
            is_product_details_exist
        }

    }
}
</script>