import { createRouter, createWebHistory } from 'vue-router'
import SelectLocation from '../views/SelectLocation.vue'
import SelectLotProduct from '../views/SelectLotProduct.vue'
import Lot from '../views/Lot.vue'
import Product from '../views/Product.vue'
import LotRack from '../views/LotRack.vue'
import LotDetails from '../views/LotDetails.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import ProductStock from '../views/ProductStock.vue'
import ChangePassword from '../views/ChangePassword.vue'
import RecentInventory from '../views/RecentInventory.vue'
import SelectProject from '../views/SelectProject.vue'
import ReturnInventory from '../views/ReturnInventory.vue'
import SetRackLot from '../views/SetRackLot.vue'
import SetExpiryLot from '../views/SetExpiryLot.vue'
import NoExpiryLot from '../views/NoExpiryLot.vue'
import NoRackLot from '../views/NoRackLot.vue'
import ExpiryManagement from '../views/ExpiryManagement.vue'
import BarcodeManagement from '../views/BarcodeManagement.vue'
import NegativeLot from '../views/NegativeLot.vue'
import ProductInventory from '../views/ProductInventory.vue'
import ProductMovement from '../views/ProductMovement.vue'
import TailwindTest from '../views/TailwindTest.vue'
import IfrbProduct from '../views/IfrbProduct.vue'
import CarrierScan from '../views/CarrierScan.vue'
import InventoryCount from '../views/inventorycount/InventoryCount.vue'
import WhCodeScan from '../views/WhCodeScan.vue'

const routes = [
  {
    path: '/',
    name: 'SelectProject',
    component: SelectProject
  },
  {
    path: '/count',
    name: 'InventoryCount',
    component: InventoryCount
  },
  {
    path: '/location',
    name: 'SelectLocation',
    component: SelectLocation
  },
  {
    path: '/return',
    name: 'ReturnInventory',
    component: ReturnInventory
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/password',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/recent',
    name: 'RecentInventory',
    component: RecentInventory,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/second',
    name: 'SelectLotProduct',
    component: SelectLotProduct,
    props: route => ({
      location_id: route.query.location_id
    })
  },
  {
    path: '/lot',
    name: 'Lot',
    component: Lot,
    props: route => ({
      location_id: route.query.location_id
    })
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    props: route => ({
      location_id: route.query.location_id
    })
  },
  {
    path: '/lot/:rackid',
    name: 'LotRack',
    component: LotRack,
    props: route => ({
      location_id: route.query.location_id
    })
  },
  {
    path: '/details',
    name: 'LotDetails',
    component: LotDetails,
    props: route => ({ 
      lot_name: route.query.lot_name,
      rackid: route.query.rackid,
      product_code: route.query.product_code,
      location_id: route.query.location_id
    })
  },
  {
    path: '/product/stock',
    name: 'ProductStock',
    component: ProductStock
  },
  {
    path: '/rack/lot',
    name: 'SetRackLot',
    component: SetRackLot,
    props: route => ({
      passed_location_id: route.query.location,
      passed_selected_lot_id: route.query.lot,
      passed_location_name: route.query.location_name,
    })
  },
  {
    path: '/expiry/lot',
    name: 'SetExpiryLot',
    component: SetExpiryLot,
    props: route => ({
      passed_location_id: route.query.location,
      passed_selected_lot_id: route.query.lot,
      passed_location_name: route.query.location_name,
    })
  },
  {
    path: '/lot/no/rack',
    name: 'NoRackLot',
    component: NoRackLot,
  },
  {
    path: '/lot/no/expiry',
    name: 'NoExpiryLot',
    component: NoExpiryLot
  },
  {
    path: '/manage/expiry',
    name: 'ExpiryManagement',
    component: ExpiryManagement,
  },
  {
    path: '/stock/expiry',
    name: 'ExpiryStock',
    component: ProductStock,
    props: route => ({
      passed_location_id: route.query.location,
      passed_product_code: route.query.code
    })
  },
  {
    path: '/barcode',
    name: 'BarcodeManagement',
    component: BarcodeManagement,
  },
  {
    path: '/negative/lot',
    name: 'NegativeLot',
    component: NegativeLot,
  },
  {
    path: '/product/inventory/:locationid/:productcode',
    name: 'ProductInventory',
    component: ProductInventory,
  },
  {
    path: '/product/movement',
    name: 'ProductMovement',
    component: ProductMovement
  },
  {
    path: '/tailwind',
    name: 'Tailwind',
    component: TailwindTest
  },
  {
    path: '/ifrb/product',
    name: 'IfrbProduct',
    component: IfrbProduct
  },
  {
    path: '/courier',
    name: 'CarrierScan',
    component: CarrierScan
  },
  {
    path: '/scan',
    name: 'WhCodeScan',
    component: WhCodeScan
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
