<template>
    <div>
        <div v-if="error" class="w-full text-pink-800 bg-pink-200 text-center mt-4 rounded-md shadow-md py-3 px-6">
            {{message}}
        </div>

        <div v-if="loading" class="flex items-center justify-center py-3 px-6 mt-4 font-bold text-pink-700">
            <div>
                <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            <div class="px-4">
                Loading lots ...
            </div>
        </div>

        <div v-if="!loading && !error" class="mt-4 w-full">

            <div class="grid grid-cols-3 gap-1 text-center w-full">
                <div class="col-span-2 bg-pink-200 rounded-sm mt-1 px-2 py-2 text-pink-800 font-bold">
                    Lot
                </div>
                <div class="bg-pink-200 rounded-sm mt-1 px-2 py-2 text-pink-800">
                    
                </div>
            </div>

            <div v-for="lot_id in lot_ids" :key="lot_id.id" class="grid grid-cols-3 gap-1 text-center w-full">
                <div class="col-span-2 bg-pink-200 rounded-sm mt-1 px-2 py-1 text-pink-800">
                    {{lot_id.name}}
                </div>
                <div class="bg-pink-200 rounded-sm mt-1 px-2 py-1 text-pink-800 font-bold">
                    <router-link :to="{ name: 'SetRackLot', query: {location: location_id, lot:lot_id.name, location_name: location_name}}">Edit</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetNoRackList',
    props: ['location_id', 'location_name'],
    data() {
        return {
            lot_ids: [],
            loading: false,
            error: false,
            message: '',
        }
    },
    methods: {
        async fetchNoRackLots() {


            let url = ''
            if (this.location_id == 13) {
                url = process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_STORE_URL
            }

            const res = await fetch(url + 'api/v2/lot/'+this.location_id+'/no/rack', {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
                        
            const data = await res.json()
            return data
          
        },
    },
    async created() {
        if (!localStorage.hasOwnProperty('api_token')) {
            this.$router.push({ name: 'Login' })
        }
        window.scrollTo(0, 0);
        this.loading = true
        const data = await this.fetchNoRackLots()
        console.log(data)
        if (data.success) {
            this.lot_ids = data.lot_ids
            console.log(this.lot_ids)
        } else {
            localStorage.removeItem("api_token");
            this.$router.push({ name: 'Login' })

            this.error = true
            this.message = data.message
        }
        this.loading = false
    }
}
</script>