<template>
    <div>
        <div v-if="filteredLots">
            <ul class="text-center text-pink-600 font-bold mt-2">
                <li v-for="filteredLot in filteredLots" :key="filteredLot._source.lot_id" @click="setLot(filteredLot._source.lot_name)" class="border py-2 bg-pink-200 rounded shadow-md mt-1">
                    <div>{{filteredLot._source.lot_name}}</div>
                    <div class="text-xs">SKU {{filteredLot._source.product_code}}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShowLotSearchResult',
    props: ['lot_id', 'location_id'],
    data() {
        return {
            filteredLots: [],
        }
    },
    watch: {
        async lot_id(newVal, oldVal) {
            if (newVal == 0) {
                this.filteredLots = []
            }
            if (newVal) {
                let url = ''

                if (this.location_id == '13') {
                    url = process.env.VUE_APP_BACKEND_URL
                } else {
                    url = process.env.VUE_APP_STORE_URL
                }

                const res = await fetch(url + 'api/v2/lot/'+newVal+'/search', {
                                            method: "GET",
                                            headers: {
                                                'api_token': localStorage.getItem('api_token'),
                                            }
                                        })
                const data = await res.json()
                if (data.success) {
                    if (this.lot_id.length > 0) {
                        this.filteredLots = data.lot_list
                    } else {
                        this.filteredLots = []
                    }
                    
                }
            }
        }
    },
    methods: {
        setLot(lot_id) {
            this.$emit('lotid:selected', lot_id)
        }
    }
}
</script>