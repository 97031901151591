import { reactive } from "vue"

const state = reactive({
    transferloading: false,
    fromloc: false,
    message: '',
    successmsg: '',
});

export default {
    state
};