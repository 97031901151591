<template>

    
    
    <div class="relative min-h-screen md:flex">

    <div v-if="isOffline" class="fixed w-screen bg-red-700 text-red-50 opacity-80 text-2xl px-5 py-4 text-center">
        Sorry, you are offline! Please check your internet!
    </div>
      
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />

      
      <div class="flex-1 px-4 py-10">

        <div v-if="message" @click="message = ''" class="px-3 py-2 cursor-pointer bg-red-600 text-red-50 font-bold text-center rounded-md shadow-md">
            {{message}}
        </div>

        <div v-if="loadingCarriers && !message">
            <Loading :message="loadingMsg" />
        </div>

        <div v-if="!loadingCarriers && !message">

            <div class="text-center px-4 py-5 mb-4 -ml-4 -mr-4 -mt-2 text-pink-800 font-bold bg-pink-100 shadow-sm">
                কুরিয়ার স্ক্যান
            </div>

            <!-- Top Level Selection Menu -->
            <div v-if="!sessionId && !summarySession" class="flex items-center justify-center">
                
                <div class="w-64">
                    
                    <div @click="startSession()" class="flex items-center justify-center cursor-pointer bg-pink-200 px-8 py-4 rounded-lg shadow-md border-2 border-pink-400 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                            
                        Start Session
            
                    </div>
                </div>
                
            </div>

            <div v-if="!sessionId && !summarySession" class="flex items-center justify-center">
                
                <div class="w-64">
                    
                    <div @click="startSummary()" class="flex items-center justify-center cursor-pointer bg-pink-200 px-8 py-4 mt-2 rounded-lg shadow-md border-2 border-pink-400 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                            
                        Order Summary
            
                    </div>
                </div>
                
            </div>

            <!-- Summary Section -->
            <div v-if="!sessionId && summarySession && !loadingCarriers">

                <div @click="summarySession = !summarySession" class="text-center px-4 py-5 mb-4 -ml-4 -mr-4 -mt-2 text-pink-800 font-semibold shadow-sm">
                    কুরিয়ার সামারি
                </div>

                <div v-if="carrierOrderList">

                    <div class="grid grid-cols-6 gap-1 mb-2">
                        <div class="px-3 py-4 bg-pink-800 text-pink-50 text-center font-bold rounded-sm shadow-sm">
                            Picking Date
                        </div>

                        <div class="px-3 py-4 bg-pink-800 text-pink-50 text-center font-bold rounded-sm shadow-sm">
                            Courier
                        </div>

                        <div class="px-3 py-4 bg-pink-800 text-pink-50 text-center font-bold rounded-sm shadow-sm">
                            Session Id
                        </div>

                        <div class="px-3 py-4 bg-pink-800 text-pink-50 text-center font-bold rounded-sm shadow-sm">
                            User
                        </div>

                        <div class="px-3 py-4 bg-pink-800 text-pink-50 text-center font-bold rounded-sm shadow-sm">
                            Total
                        </div>

                        <!-- if donwloadList contains, it changes to select button color -->
                        <div v-if="downloadList.length < 1" class="px-3 py-4 bg-pink-800 text-pink-50 text-center font-bold rounded-sm shadow-sm">
                            Download
                        </div>

                        <div @click="downloadAllSummary()" v-if="downloadList.length >= 1" class="px-3 py-4 cursor-pointer bg-pink-600 text-pink-50 text-center font-bold rounded-sm shadow-sm">
                            Download
                        </div>
                    </div>
                    

                    <div v-for="carrierOrder in carrierOrderList" :key="carrierOrder.session_id" class="grid grid-cols-6 gap-1 mb-1">

                        <div class="px-3 py-4 bg-pink-200 text-pink-800 text-center font-bold rounded-sm shadow-sm">
                            {{carrierOrder.picking_date}}
                        </div>

                        <div class="px-3 py-4 bg-pink-200 text-pink-800 text-center font-bold rounded-sm shadow-sm">
                            {{carrierOrder.delivery_channel}}
                        </div>

                        <div class="px-3 py-4 bg-pink-200 text-pink-800 text-center font-bold rounded-sm shadow-sm">
                            {{carrierOrder.session_id}}
                        </div>

                        <div class="px-3 py-4 bg-pink-200 text-pink-800 text-center font-bold rounded-sm shadow-sm">
                            {{carrierOrder.user_id}}
                        </div>

                        <div class="px-3 py-4 bg-pink-200 text-pink-800 text-center font-bold rounded-sm shadow-sm">
                            {{carrierOrder.total}}
                        </div>

                        <!-- Add to Download List -->
                        <div v-if="!downloadList.includes(carrierOrder.session_id+'_'+ carrierOrder.delivery_channel)" @click="addDownloadList(carrierOrder.session_id, carrierOrder.delivery_channel)" class="px-3 py-4 cursor-pointer bg-pink-200 text-pink-800 text-center font-bold rounded-sm shadow-sm">
                            Select
                        </div>

                        <!-- Remove from Download List -->
                        <div v-if="downloadList.includes(carrierOrder.session_id+'_'+ carrierOrder.delivery_channel)" @click="removeDownloadList(carrierOrder.session_id, carrierOrder.delivery_channel)" class="px-3 py-4 cursor-pointer bg-pink-600 text-pink-50 text-center font-bold rounded-sm shadow-sm">
                            Select
                        </div>

                    </div>

                </div>

                <div v-if="!carrierOrderList">
                    There is no courier data
                </div>

            </div>



            <!-- Courier Selection Menu -->
            <div v-if="!current_carrier && sessionId">
                <div @click="sessionId = !sessionId" class="text-center text-pink-800">
                    আপনার কুরিয়ার সিলেক্ট করুন
                </div>
                <div class="flex items-center justify-center mt-4">
                    <div class="grid grid-cols-1 gap-2">
                        <div v-for="carrier in carriers" :key="carrier.id">
                            <div @click="setCurrentCarrier(carrier.name)" class="flex items-center justify-center cursor-pointer bg-pink-200 px-8 py-4 rounded-lg shadow-md border-2 border-pink-400 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                
                                {{carrier.name}}
                            
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <!-- Counter and 3rd Level Menu with Close Session -->
            <div v-if="current_carrier && sessionId">

                <div class="flex justify-center items-center px-2">
                    <div>
                        <div @click="resetCarrier" class="w-64 px-5 py-3 rounded-md shadow-md cursor-pointer font-bold bg-pink-700 text-pink-50 text-center">
                            {{current_carrier}}
                        </div>
                    </div>
                </div>

                <div class="py-2 font-medium text-pink-700">
                    <div :class="setMessageClasses(setLevel)">
                        {{infomessage}}
                    </div>     

                    <div class="flex items-center justify-center m-2 text-9xl font-extrabold text-pink-800">
                        {{delivered_orders[current_carrier].length}}
                    </div>

                    <div class="flex items-center justify-center py-2">
                        <div class="w-64 px-4 py-3 rounded-md shadow-md font-bold bg-pink-200 text-center">
                            {{scanned_word}} - {{derived_carrier}}
                        </div>
                    </div>

                    <div class="flex items-center justify-center py-2">
                        <button @click="downloadCourier(current_carrier)" class="w-64 ml-2 px-4 py-3 rounded-md shadow-md font-bold bg-pink-600 text-pink-100 text-center">
                            {{current_carrier}} Orders
                        </button>
                    </div>

                    <div class="flex items-center justify-center py-2">
                        <button @click="downloadAll()" class="w-64 px-4 py-3 rounded-md shadow-md font-bold bg-pink-600 text-pink-100 text-center">
                            All Orders
                        </button>
                    </div>

                    <div class="flex items-center justify-center py-2">
                        <button @click="resetCounter(current_carrier)" class="w-64 px-4 py-3 rounded-md shadow-md font-bold bg-pink-600 text-pink-100 text-center">
                            Reset {{current_carrier}}
                        </button>
                    </div>

                    <div class="flex items-center justify-center py-2">
                        <button @click="resetCounterAll()" class="w-64 px-4 py-3 rounded-md shadow-md font-bold bg-pink-600 text-pink-100 text-center">
                            Reset All
                        </button>
                    </div>

                    <div class="flex items-center justify-center py-2">
                        <button @click="closeSession()" class="w-64 px-4 py-3 rounded-md shadow-md font-bold bg-pink-200 text-pink-800 text-center">
                            Close Session
                        </button>
                    </div>

                    <div class="flex items-center justify-center py-2">
                        <div class="w-64 ml-2 px-4 py-3 rounded-md shadow-md font-bold bg-pink-600 text-pink-100 text-center">
                            {{errorOrders.length}} Errors
                        </div>
                    </div>

                    <!-- Show Error Orders Section -->
                    <div class="flex items-center justify-center py-2">
                        <div v-if="errorOrders" class="grid grid-cols-4 gap-4 py-2">

                            <div v-for="errorOrder in errorOrders" :key="errorOrder" class="w-64 ml-2 px-4 py-3 rounded-md shadow-md font-bold bg-pink-200 text-pink-800 text-center">
                                <div>{{errorOrder}}</div>
                            </div>

                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
        
      </div>

    </div>

</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import Navbar from '../components/Navbar.vue'
import GetOrderCarrier from '../components/GetOrderCarrier.vue'
import Loading from '../components/Loading.vue'

export default {
    name: 'CourierScan',
    components: {
        Navbar,
        GetOrderCarrier,
        Loading,
    },
    setup() {

        const sessionId = ref('')
        const creatingSession = ref(false)
        let scanned_code = ''
        const scanned_word = ref('')
        // we should be storing the delivered couriers based on the courier value?
        const delivered_orders = ref({})
        const goBack = ref(false)
        const current_carrier = ref('')
        const derived_carrier = ref('')
        const carriers = ref([])
        const woocommerce_id = ref('')
        const match = ref(false)
        const message = ref('')
        const loadingCarriers = ref(true)
        const loadingMsg = ref('একটু অপেক্ষা করুন ...')
        const infomessage = ref('অর্ডার বারকোড স্ক্যান করুন')
        const setLevel = ref('info')
        const picking_done = ref(false)
        const router = useRouter()
        const errorOrders = ref([])
        const isOffline = ref(navigator.isOffline)
        const summarySession = ref(false)
        const carrierOrderList = ref([])
        const downloadList = ref([])

        // checking variables
        let order_summary_list = []
        let order_added = []

        const addDownloadList = (session_id, del_ch) => {

            let session_key = session_id + '_' + del_ch

            if (!downloadList.value.includes(session_key)) {
                downloadList.value.push(session_key)
            }

        }

        const removeDownloadList = (session_id, del_ch) => {

            let session_key = session_id + '_' + del_ch

            downloadList.value = downloadList.value.filter((x) => x != session_key)

        }

        const getOrderBySessionId = (session_id, courier_method) => {

            return new Promise(async (resolve, reject) => {

                message.value = ''
                let url = process.env.VUE_APP_BACKEND_URL
                const res = await fetch(url + 'api/v2/carrier/' + session_id + '/' + courier_method + '/orders', {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
                const data = await res.json()
                if (data.success) {

                    data.order_list.forEach((x) => {
                        if (!order_added.includes(x.woocommerce_id)) {
                            order_added.push(x.woocommerce_id)
                            order_summary_list.push(x)
                        }
                    })

                    resolve('done')

                } else {
                    if (data.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        router.push({ name: 'Login' })
                    }
                    message.value = data.message
                    reject(message.value)
                }

            })

        }


        const downloadAllSummary = async () => {

            // use all the sessions available in downloadList and merge the result in 

            loadingCarriers.value = true
            // reset containers
            order_summary_list = []
            order_added = []
            
            let bar = new Promise((resolve, reject) => {
                downloadList.value.forEach( async (session_key, index, array) => {
                    const session_items = session_key.split("_")
                    const session_id = session_items[0]
                    const courier_method = session_items[1]
                    let return_data = await getOrderBySessionId(session_id, courier_method)
                    if (return_data != 'done') {
                        console.log(return_data)
                    }
                    if (index === array.length -1) resolve();
                });
            });

            bar.then(() => {
                // define file
                let csvContent = "";
                
                // add header
                
                let row = "Address, Customer Name, Delivery Channel, Notes, Amount, Payment Term, Phone Number, Woo Order ID"
                csvContent += row + "\r\n"

                // add contents

                // "=""Data Here""" = as text for phone number

                order_summary_list.forEach((order) => {

                    let final_address = order.address.replace('"', "'")

                    // replace " to '
                    while(1) {
                        if (final_address.includes('"')) {
                            final_address = final_address.replace('"', "'")
                        } else {
                            break
                        }
                    }

                    // replace all new line to space
                    while(1) {
                        if (final_address.includes('\n')) {
                            final_address = final_address.replace('\n', " ")
                        } else {
                            break
                        }
                    }

                    // do same for order notes
                    let notes = order.notes.replace("\n", " ")

                    while(1) {
                        if (notes.includes("\n")) {
                            notes = notes.replace("\n", " ")
                        } else {
                            break
                        }
                    }

                    while(1) {
                        if (notes.includes('"')) {
                            notes = notes.replace('"', "'")
                        } else {
                            break
                        }
                    }

                    let row = '"' + final_address + '",' + order.customer_name + ',' + order.delivery_channel + ',"' + notes + '",' + order.amount + ',' + order.payment + ',"=""' + order.phone + '""",' + order.woocommerce_id
                    csvContent += row + "\r\n"
                })

                var contentType = 'text/csv';

                var csvFile = new Blob([csvContent], {type: contentType});

                // download
                var link = document.createElement("a");
                link.setAttribute("href", window.URL.createObjectURL(csvFile));
                link.setAttribute("download", "delivered_orders.csv");
                link.click()

            });

            loadingCarriers.value = false
            
        }

        // scan the barcode and add it to scanned_word
        window.addEventListener("keypress", e => {
            if (e.keyCode != 13) {
                scanned_code = scanned_code + String.fromCharCode(e.keyCode)
            } else {
                // scanning the same code again, does not change the scanned_word value, it remains same. 
                if (scanned_word.value === scanned_code) {

                    if (setLevel.value != 'danger') {
                        infomessage.value = 'একি অর্ডার আবার স্ক্যান করেছেন'
                        setLevel.value = 'danger'
                    }

                }
                scanned_word.value = scanned_code
                scanned_code = ''
            }
        });

        //Testing

        // setTimeout(() => {

        //     scanned_word.value = '2274714589'

        //     setTimeout( () => {
        //         scanned_word.value = '2269169'

        //         setTimeout( () => {
                    
        //             scanned_word.value = '2276769'

        //             setTimeout( () => {
                    
        //                 scanned_word.value = '2273244'

        //                 setTimeout( () => {
                    
        //                     scanned_word.value = '2273813'

        //                     setTimeout( () => {
                    
        //                         scanned_word.value = '2273805'

        //                         setTimeout( () => {
                    
        //                             scanned_word.value = '2276171'

        //                             setTimeout( () => {
                    
        //                                 scanned_word.value = '2273851'

        //                                 setTimeout( () => {
                    
        //                                     scanned_word.value = '2273854'

        //                                     setTimeout( () => {
                    
        //                                         scanned_word.value = '2273851'

        //                                         setTimeout( () => {
                    
        //                                             scanned_word.value = '2273972'

        //                                             setTimeout( () => {
                    
        //                                                 scanned_word.value = '2273820'

        //                                             }, 5000)

        //                                         }, 5000)

        //                                     }, 5000)

        //                                 }, 5000)

        //                             }, 5000)

        //                         }, 5000)

        //                     }, 5000)

        //                 }, 5000)

        //             }, 5000)

        //         }, 5000)

        //     }, 5000)

        // }, 10000)

        window.addEventListener('offline', e => {
            isOffline.value = true
        })

        window.addEventListener('online', e => {
            isOffline.value = false
        })

        if (localStorage.hasOwnProperty('delivered_orders')) {
            delivered_orders.value = JSON.parse(localStorage.getItem('delivered_orders'))
        }

        if (localStorage.hasOwnProperty('courier_session')) {

            let text = "আপনার আগে থেকে একটা সেশন আছে, কন্টিনিউ করলে ওকে দিন, অথবা নতুন সেশন তৈরী করতে কেন্সেল দিন";
            if (confirm(text) == true) {
                sessionId.value = localStorage.getItem('courier_session')    
            } else {
                localStorage.removeItem('courier_session')
                delivered_orders.value = {}
                errorOrders.value = []
                localStorage.setItem('delivered_orders', JSON.stringify(delivered_orders.value))
                scanned_word.value = ''; 
                derived_carrier.value = '';
                current_carrier.value = '';
            }
        }


        watch(scanned_word, (newVal, prevVal) => {
            if (errorOrders.value.length > 0) {
                let t = 'আমরা এরর পেয়েছি। স্ক্যান বন্ধ করুন, সাপোর্ট এ যোগাযোগ করুন।'
                if (confirm(t) == true) {
                    console.log('error accepted')
                }
            } else {
                getOrderCarrier(scanned_word.value);
            }
            
        });

        const startSummary = async () => {

            message.value = ''
            loadingCarriers.value = true
            let url = process.env.VUE_APP_BACKEND_URL
            const res = await fetch(url + 'api/v2/carrier/orders', {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
            const data = await res.json()
            if (data.success) {
                carrierOrderList.value = data.carrier_order_list
                summarySession.value = true
            } else {
                if (data.message == 'API Token Invalid') {
                    localStorage.removeItem("api_token");
                    router.push({ name: 'Login' })
                }
                message.value = data.message
            }
            loadingCarriers.value = false

        }

        

        const startSession = async () => {

            message.value = ''
            loadingCarriers.value = true
            let url = process.env.VUE_APP_BACKEND_URL
            const res = await fetch(url + 'api/v2/carrier/session', {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
            const data = await res.json()
            if (data.success) {
                sessionId.value = data.session_id
                localStorage.setItem('courier_session', sessionId.value)
            } else {
                if (data.message == 'API Token Invalid') {
                    localStorage.removeItem("api_token");
                    router.push({ name: 'Login' })
                }
                message.value = data.message
            }
            loadingCarriers.value = false

        }

        const closeSession = () => {

            let text = "সেশন ক্লোস করলে, আপনার ব্রাউসার থেকে এই সেশনের তথ্য সব রিসেট হবে, আপনি নিশ্চিত হলে, কন্ফার্ম দিন। অন্যথা কেন্সেল করুন।";

            if (confirm(text) == true) {
                sessionId.value = ''
                localStorage.removeItem('courier_session')
                delivered_orders.value = {}
                errorOrders.value = []
                localStorage.setItem('delivered_orders', JSON.stringify(delivered_orders.value))
                scanned_word.value = ''; 
                derived_carrier.value = '';
                current_carrier.value = '';
            }

            window.scrollTo(0, 0);

        }

        const resetCarrier = () => {
            current_carrier.value = ''; 
            scanned_word.value = ''; 
            derived_carrier.value = '';
            infomessage.value = 'অর্ডার বারকোড স্ক্যান করুন'
            setLevel.value = 'info'
        }

        const downloadCourier = (c) => {

            
            // define file
            let csvContent = "";
            
            // add header

            //Address, Customer Name, Delivery Channel, Notes, Final Amount, Payment Term, Phone Number, Woo Order ID
            
            let row = "Address, Customer Name, Delivery Channel, Notes, Amount, Payment Term, Phone Number, Woo Order ID"
            csvContent += row + "\r\n"

            // add contents
            delivered_orders.value[c].forEach( (order) => {                
                let row = '"' + order.address + '",' + order.customer_name + ',' + order.delivery_channel + ',' + order.notes + ',' + order.amount + ',' + order.payment + ',"=""' + order.phone + '""",' + order.woocommerce_id
                csvContent += row + "\r\n"
            })

            var contentType = 'text/csv';

            var csvFile = new Blob([csvContent], {type: contentType});

            // download
            var link = document.createElement("a");
            link.setAttribute("href", window.URL.createObjectURL(csvFile));
            link.setAttribute("download", "delivered_orders.csv");
            link.click()
            
        }

        const downloadAll = () => {

            // define file
            let csvContent = "";
            
            // add header
            
            let row = "Address, Customer Name, Delivery Channel, Notes, Amount, Payment Term, Phone Number, Woo Order ID"
            csvContent += row + "\r\n"

            // add contents

            // "=""Data Here""" = as text for phone number

            Object.keys(delivered_orders.value).forEach((k) => {
                delivered_orders.value[k].forEach((order) => {                    
                    let row = '"' + order.address + '",' + order.customer_name + ',' + order.delivery_channel + ',' + order.notes + ',' + order.amount + ',' + order.payment + ',"=""' + order.phone + '""",' + order.woocommerce_id
                    csvContent += row + "\r\n"
                })
            })

            var contentType = 'text/csv';

            var csvFile = new Blob([csvContent], {type: contentType});

            // download
            var link = document.createElement("a");
            link.setAttribute("href", window.URL.createObjectURL(csvFile));
            link.setAttribute("download", "delivered_orders.csv");
            link.click()

        }

        const resetCounter = (c) => {
            let text = "You are trying to clear the counter. Once cleared, it can not be retrieved, you have to scan them again\nPress Ok to confirm.";
            if (confirm(text) == true) {
                delivered_orders.value[c] = []
                errorOrders.value = []
                localStorage.setItem('delivered_orders', JSON.stringify(delivered_orders.value))
                scanned_word.value = ''; 
                derived_carrier.value = '';
            }
        }

        const resetCounterAll = () => {
            let text = "You are trying to clear all data. Once cleared, it can not be retrieved, you have to scan them again\nPress Ok to confirm.";
            if (confirm(text) == true) {
                delivered_orders.value = {}
                errorOrders.value = []
                localStorage.setItem('delivered_orders', JSON.stringify(delivered_orders.value))
                scanned_word.value = ''; 
                derived_carrier.value = '';
                current_carrier.value = '';
            }
        }

        const setCurrentCarrier = (c_c) => {
            window.scrollTo(0, 0);
            current_carrier.value = c_c
            if (typeof(delivered_orders.value[current_carrier.value]) == 'undefined') {
                delivered_orders.value[current_carrier.value] = []
            }
        }

        const setMessageClasses = (level) => {
            if (level === 'danger') {
                return 'm-2 px-3 py-4 flex items-center justify-center rounded-md shadow-md bg-red-600 text-red-50 font-semibold'
            }

            if (level === 'success') {
                return 'm-2 px-3 py-4 flex items-center justify-center rounded-md shadow-md bg-green-600 text-green-50 font-semibold'
            }

            // level info or else
            return 'm-2 px-3 py-4 flex items-center justify-center rounded-md shadow-md bg-blue-100 text-blue-800 font-semibold'
        }

        const getOrderCarrier = async (order_id) => {

            if (order_id !== '') {
                infomessage.value = 'আপনার অর্ডারটি খুঁজছি ...'
                setLevel.value = 'info'
                let url = process.env.VUE_APP_BACKEND_URL
                const res = await fetch(url + 'api/v2/order/' + order_id + '/carrier', {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
                const data = await res.json()
                if (data.success) {
                    let match_value = false
                    if(data.carrier_id === current_carrier.value) {
                        match_value = true
                    }
                    window.scrollTo(0, 0);
                    derived_carrier.value = data.carrier_id
                    picking_done.value = data.picking_done

                    if (!picking_done.value) {

                        infomessage.value = 'আপনার অর্ডারটি ভ্যালিডেট হয় নি।'
                        setLevel.value = 'danger'
                        let audio = new Audio('order_validate_hoy_ni.mp3');
                        audio.play();

                        let t = 'অর্ডারটি ভ্যালিডেট হয় নি, ওকে দিয়ে কনফার্ম করুন।'
                        if (confirm(t) == true) {
                            console.log(order_id + ' confirmed after validation miss')
                        }

                    } else {

                        if (!match_value) {
                            infomessage.value = 'আপনার অর্ডারটি '+ order_id +' ঠিক কুরিয়ারের সাথে মিলেনি '
                            setLevel.value = 'danger'
                            let audio = new Audio('courier_mile_nai.mp3');
                            audio.play();

                            let t = 'অর্ডারটি কুরিয়ারের সাথে মিলে নাই, ওকে দিয়ে কনফার্ম করুন।'
                            if (confirm(t) == true) {
                                console.log(order_id + ' confirmed after courier miss')
                            }

                        } else {
                            // increase counter
                            const match_arr = delivered_orders.value[current_carrier.value].filter((d) => {
                                return d.woocommerce_id == order_id;
                            });

                            if (match_arr.length > 0) {
                                // should be shown in red
                                infomessage.value = 'আপনার অর্ডার '+ order_id +' আগেই স্ক্যান করা হয়েছে'
                                setLevel.value = 'danger'
                                let audio = new Audio('order_agei_scan_kora_hoyeche.mp3');
                                audio.play();
                                // setTimeout(() => {
                                //     // should be shown in blue
                                //     infomessage.value = 'অর্ডার বারকোড স্ক্যান করুন'
                                //     setLevel.value = 'info'
                                // }, 30000)
                            } else {
                                // should be shown in green
                                // Store the delivery_details here
                                if (typeof(delivered_orders.value[current_carrier.value]) === 'undefined') {
                                    delivered_orders.value[current_carrier.value] = [
                                        data.delivery_details
                                    ]
                                    postCarrierPick(order_id, data.delivery_details)
                                    setTimeout(() => {
                                        let msg = new SpeechSynthesisUtterance();
                                        msg.text = delivered_orders.value[current_carrier.value].length;
                                        window.speechSynthesis.speak(msg);
                                    }, 1500)
                                    
                                } else {
                                    delivered_orders.value[current_carrier.value].push(data.delivery_details);

                                    setTimeout(() => {
                                        let msg = new SpeechSynthesisUtterance();
                                        msg.text = delivered_orders.value[current_carrier.value].length;
                                        window.speechSynthesis.speak(msg);
                                    }, 1500)

                                    localStorage.setItem('delivered_orders', JSON.stringify(delivered_orders.value))
                                    postCarrierPick(order_id, data.delivery_details)
                                }                                
                                
                                infomessage.value = 'আপনার অর্ডারটি পেয়েছি'
                                setLevel.value = 'success'
                                let audio = new Audio('paoa_geche.mp3');
                                audio.play();
                                // setTimeout(() => {
                                //     // should be shown in blue
                                //     infomessage.value = 'অর্ডার বারকোড স্ক্যান করুন'
                                //     setLevel.value = 'info'
                                // }, 30000)
                            }
                        }

                    }

                    
                    
                } else {
                    if (data.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        router.push({ name: 'Login' })
                    }
                    // should be shown in red
                    infomessage.value = data.message
                    setLevel.value = 'danger'
                }
            }
        }
        

        const getCouriers = async () => {
            message.value = ''
            loadingCarriers.value = true
            let url = process.env.VUE_APP_BACKEND_URL
            const res = await fetch(url + 'api/v2/carriers', {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
            const data = await res.json()
            if (data.success) {
                carriers.value = data.carrier_list
            } else {
                if (data.message == 'API Token Invalid') {
                    localStorage.removeItem("api_token");
                    router.push({ name: 'Login' })
                }
                message.value = data.message
            }
            loadingCarriers.value = false
        }

        const setBarcodeId = (barcode) => {
            woocommerce_id.value = barcode
        }

        const postCarrierPick = async (order_id, delivery_details) => {

            try {
                let url = process.env.VUE_APP_BACKEND_URL

                let car_list = carriers.value.filter((car) => {
                        if (car.name == current_carrier.value) {
                            return car.id
                        }
                    })

                //and kw.get('customer_name') and kw.get('delivery_channel') and kw.get('address') and kw.get('amount')and kw.get('payment_term') and kw.get('notes')
                const data = {
                        "params": {
                            'carrier_id': car_list[0].id,
                            'customer_name': delivery_details.customer_name,
                            'delivery_channel': delivery_details.delivery_channel,
                            'address': delivery_details.address,
                            'amount': delivery_details.amount,
                            'payment_term': delivery_details.payment,
                            'notes': delivery_details.notes,
                            'phone': delivery_details.phone,
                            'session_id': sessionId.value,
                        }
                    }

                console.log(data);

                const res = await fetch(url + "api/v2/order/" + order_id + "/carrier/picked", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': '*',
                        'api_token': localStorage.getItem('api_token'),
                    },
                    body: JSON.stringify(data),
                    redirect: 'follow',
                });
                const result = await res.json()
                const r = JSON.parse(result.result)

                if (r.success) {
                    console.log(order_id + " posted");
                } else {
                    if (r.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        router.push({ name: 'Login' })
                    }

                    if (!errorOrders.value.includes(order_id)) {
                        errorOrders.value.push(order_id)
                    }

                    console.log(order_id + " has error: " + r.message)
                }

            } catch(error) {
                if (!errorOrders.value.includes(order_id)) {
                    errorOrders.value.push(order_id)
                }
                console.log(order_id + " has error: " + error)
            }

        }

        const setMatchStatus = (payload) => {
            match.value = payload.$event.match
            message.value = payload.$event.message
            if (match.value) {
                // POST to ERP, show it has matched, and reset values
                postCarrierPick(woocommerce_id.value)
            } else {
                alert("Wrong Courier")

                // resetting values
                woocommerce_id.value = ''
                current_carrier.value = ''
                match.value = false

            }
        }

        getCouriers()
        

        return {
            goBack,
            sessionId,
            creatingSession,
            loadingCarriers,
            loadingMsg,
            carriers,
            current_carrier,
            woocommerce_id,
            setBarcodeId,
            setMatchStatus,
            resetCarrier,
            startSession,
            message,
            scanned_word,
            derived_carrier,
            delivered_orders,
            infomessage,
            setMessageClasses,
            setCurrentCarrier,
            resetCounter,
            downloadCourier,
            downloadAll,
            resetCounterAll,
            closeSession,
            setLevel,
            errorOrders,
            isOffline,
            summarySession,
            startSummary,
            carrierOrderList,
            downloadList,
            addDownloadList,
            removeDownloadList,
            downloadAllSummary
        }

    },
}
</script>