<template>
    <div class="relative min-h-screen md:flex">
      <!-- nav bar -->
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />
      <!-- content -->
      <div class="flex-1 px-4 py-10">
        <div v-if="!error">
            <form v-if="!loading" @submit.prevent="checkLot">
                <label class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700">
                    Rack {{this.rackid}}
                </label>

                <!-- search bar goes here --->

                <div class="grid grid-cols-6 md:grid-cols-4 mt-4 text-gray-400 rounded-lg border border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="col-span-2 md:col-span-1 px-4 flex items-center justify-start border-0 focus:outline-none">
                        <span class="">Lot</span>
                    </div>

                    <div class="col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none">
                        <input class="w-full h-full focus:outline-none text-center text-pink-700" name="lot_id" type="text" autocomplete="off" v-model="lot_id" @input="filterLot" @focusin="showSearch = !showSearch" @focusout="showsearch = !showSearch" />
                    </div>

                    
                    <div class="flex items-center justify-end px-4 col-span-2 md:col-span-1 border-0 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                </div>

                <!-- <div class="relative mt-4 text-gray-400 focus-within:text-pink-800">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-4">
                        <span class="">Enter Lot</span>
                    </div>
                    <input name="lot_id" type="text" autocomplete="off" v-model="lot_id" @input="filterLot" @focusin="showSearch = !showSearch" @focusout="showsearch = !showSearch" tabindex="1" class="w-full border border-pink-600 px-8 py-4 rounded-lg shadow-sm text-center text-pink-700 font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500" />
                    <div class="absolute inset-y-0 right-0 flex items-center pr-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                </div> -->
                
                <!-- search result goes here -->

                <div v-if="filteredLots">
                    <ul class="text-center text-pink-600 font-bold mt-2">
                        <li v-for="filteredLot in filteredLots" :key="filteredLot._source.lot_id" @click="setLot(filteredLot._source.lot_name)" class="border py-2 bg-pink-200 rounded shadow-md mt-1">
                            <div>{{filteredLot._source.lot_name}}</div>
                            <div class="text-xs">SKU {{filteredLot._source.product_code}}</div>
                        </li>
                    </ul>
                </div>

                <!-- <button type="submit" class="w-full bg-pink-600 text-pink-100 px-8 py-4 rounded-lg shadow-sm mt-4 font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">Get Details</button> -->
            </form>
            <div v-else>
                <div class="text-center text-pink-700 font-bold animate-bounce">
                ... loading lot details, please wait ...
                </div>
            </div>
        </div>

        <div v-else>

            <label class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-red-100 font-bold text-red-700">
                {{ this.message }}
            </label>

        </div>
      </div>
    </div>
    
</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
    name: 'LotRack',
    components: {
        Navbar,
    },
    props: ['location_id'],
    data() {
        return {
            rackid: '',
            lot_id: '',
            loading: false,
            error: false,
            message: '',
            filteredLots: [],
            model: false,
            goBack: false,
            showSearch: false,
        }
    },
    watch: {
      showSearch: function (val) {
        console.log(this.showSearch);
      },
    },
    methods: {
        setLot(lot_name) {
            this.lot_id = lot_name
            this.filteredLots = []
            this.checkLot()
        },

        async filterLot() {
            if (this.lot_id.length == 0) {
                this.filteredLots = []
            }
            if (this.lot_id) {
                let url = ''

                if (this.location_id == '13') {
                    url = process.env.VUE_APP_BACKEND_URL
                } else {
                    url = process.env.VUE_APP_STORE_URL
                }

                const res = await fetch(url + 'api/v2/lot/'+this.lot_id+'/search', {
                                            method: "GET",
                                            headers: {
                                                'api_token': localStorage.getItem('api_token'),
                                            }
                                        })
                const data = await res.json()
                if (data.success) {
                    if (this.lot_id.length > 0) {
                        this.filteredLots = data.lot_list
                        console.log(this.filteredLots)
                    } else {
                        this.filteredLots = []
                    }
                    
                }
            }
        },

        async checkLot() {
            this.loading = true
            if (this.lot_id === null || this.lot_id.length == 0) {
                this.error = true
                this.message = 'No lot given'
                this.loading = false
            }

            if (this.rackid === null || this.rackid.length == 0) {
                this.error = true
                this.message = 'No rackid selected'
                this.loading = false
            }

            let url = ''

            if (this.location_id == '13') {
                url = process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_STORE_URL
            }

            const res = await fetch(url + 'api/v2/racklots/'+this.location_id+'/'+this.rackid+'/'+this.lot_id, {
                                        method: "GET",
                                        headers: {
                                            'api_token': localStorage.getItem('api_token'),
                                        }
                                    })

            const data = await res.json()
            if (data.success) {
                this.$router.push({ name: 'LotDetails', query: { lot_name: data.lot_name, rackid: data.rackid, product_code: data.product_code, location_id: this.location_id } })
            } else {
                this.error = true
                this.loading = false

                if (data.message == 'API Token Invalid') {
                    localStorage.removeItem("api_token");
                    this.$router.push({ name: 'Login' })
                }
                this.message = data.message
            }
        }
    },
    created() {
        if (!localStorage.hasOwnProperty('api_token')) {
            this.$router.push({ name: 'Login' })
        }
        if (typeof this.$route.params.rackid !== 'undefined') {
            this.rackid = this.$route.params.rackid
        }

        if ((window.history.length - this.$root.historyCount) > 0) {
          this.goBack = true
        }
    },
    mounted() {
        document.title = 'Inventory Count App - Lot Rack ' + this.rackid
    }

}
</script>