<template>
  <div class="font-body">
    <router-view/>
  </div>
</template>

<script>
export default {
  components: {
  },
  mounted() {
    this.$root.historyCount = window.history.length
  }
}
</script>