<template>
    <div class="px-3 py-2 -ml-4 -mr-4 text-center cursor-pointer font-semibold text-pink-800 bg-pink-100 shadow-sm">
        Select Requisition Type
    </div>
    <div class="flex items-center justify-center mt-2">
        <div class="grid grid-cols-1 gap-2">
            <div @click="setState('pending')" class="px-5 py-4 cursor-pointer bg-pink-200 text-center text-pink-800 rounded-md shadow-md font-bold hover:bg-pink-800 hover:text-pink-50">
                Pending
            </div>
            <div @click="setState('received')" class="px-5 py-4 cursor-pointer bg-pink-200 text-center text-pink-800 rounded-md shadow-md font-bold hover:bg-pink-800 hover:text-pink-50">
                Received
            </div>
            <div @click="setState('sent')" class="px-5 py-4 cursor-pointer bg-pink-200 text-center text-pink-800 rounded-md shadow-md font-bold hover:bg-pink-800 hover:text-pink-50">
                Sent
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import getRequisitions from '../../composables/inventorycount/getRequisitions'
export default {
    name: 'RetrieveRequisitions',
    emits: ['loadingRR', 'successRR', 'errorRR' ],
    setup(props, context) {

        const setState = async (val) => {

            context.emit('loadingRR', {'status': true})

            try {

                let rr = await getRequisitions(val)
                context.emit('successRR', {
                            'msg': 'Requisitions retrieved', 
                            'requisition_requests': rr,
                            'state': val,
                        })
            } catch (err) {
                context.emit('errorRR', {'msg': err})
            }

            context.emit('loadingRR', {'status': false})

        }

        return {
            setState
        }

    }
}
</script>