<template>
    
</template>

<script>
export default {
    name: 'Logout',
    created() {
        localStorage.removeItem("api_token");
        this.$router.push({ name: 'Login' })
    }
}
</script>