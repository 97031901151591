import { reactive } from "vue"

const state = reactive({
    breadcrumb: [],
    location_list: [],
    filteredLots: [],
    lot_details: {},
    error: '',
    migration_list: [],
    current_nav: 'recent',
    showsearch: false,
    showproduct: false,
    showloading: false,
    inv_qty: 0,
    migration_id: 0, // this is only updated when a migration is updated, else, this shall be left 0

    // transfer
    from_location_id: 0,
    transferloading: false,

    migration: {}
});

export default {
    state
};