<template>
    <!-- mobile menu bar -->

    <div class="bg-pink-700 text-pink-50 flex justify-between md:hidden">
        <span class="block p-4 text-pink-50 font-bold">SHAJGOJ</span>

        <button class="p-4 focus:outline-none focus:bg-pink-600" @click="openSidebar">
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
        </button>
    </div>

    <!-- sidebar -->
    <div 
        class="sidebar bg-pink-700 h-fit text-pink-200 w-64 space-y-6 py-5 absolute z-0 inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out"
    >    
        <div class="flex justify-between px-2 py-2">
            <span class="text-2xl font-extrabold px-3">
                SHAJGOJ
            </span>
            <button class="py-1 md:hidden" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            
        </div>
        
        <nav class="bg-pink-700 w-64 px-2">
            <button v-if="showBack" class="flex items-center w-full text-left font-bold py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="goBack">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z" />
                </svg>    
                <span class="px-2">Back</span>
            </button>

            <router-link to="/" class="px-4 py-2.5 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-500 font-bold flex items-center" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>
                <span class="px-2">Home</span>
            </router-link>
            
            
            <router-link to="/count" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200 font-bold" v-if="isLogged" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                </svg>
                <span class="px-2">Inventory Ops</span>
            </router-link>

            <router-link to="/courier" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200 font-bold" v-if="isLogged" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                </svg>
                <span class="px-2">Courier Scan</span>
            </router-link>

            <router-link to="/scan" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200 font-bold" v-if="isLogged" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                </svg>
                <span class="px-2">Picking Scan</span>
            </router-link>

            <router-link to="/recent" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200 font-bold" v-if="isLogged" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
                <span class="px-2">Recent Inventory</span>
            </router-link>

            <router-link to="/barcode" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200 font-bold" v-if="isLogged" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
                </svg>
                <span class="px-2">Barcode</span>
            </router-link>

            <router-link to="/manage/expiry" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200 font-bold" v-if="isLogged" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
                <span class="px-2">Expiry Management</span>
            </router-link>

            <router-link to="/negative/lot" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200 font-bold" v-if="isLogged" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                </svg>
                <span class="px-2">Negative Lot</span>
            </router-link>

            <router-link to="/product/movement" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200 font-bold" v-if="isLogged" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                </svg>
                <span class="px-2">Product Movement</span>
            </router-link>

            <router-link to="/return" v-if="isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                </svg>
                <span class="px-2 ">Return Inventory</span>
            </router-link>

            <router-link to="/login" v-if="!isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200 font-bold" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                </svg>
                <span class="px-2">Login</span>
            </router-link>

            <router-link to="/lot/no/rack" v-if="isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="px-2 ">No Rack Lots</span>
            </router-link>

            <router-link to="/lot/no/expiry" v-if="isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="px-2 ">No Expiry Lots</span>
            </router-link>

            <router-link to="/ifrb/product" v-if="isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                </svg>
                <span class="px-2 ">Product Rack</span>
            </router-link>

            <router-link to="/rack/lot" v-if="isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                </svg>
                
                <span class="px-2">Lot Rack</span>
            </router-link>

            <router-link to="/expiry/lot" v-if="isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                
                <span class="px-2">Lot Expiry</span>
            </router-link>

            <router-link to="/product/stock" v-if="isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                </svg>
                <span class="px-2">Product Stocks</span>
            </router-link>
            <router-link to="/password" v-if="isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                </svg>
                <span class="px-2">Change Password</span>
            </router-link>
            <router-link to="/logout" v-if="isLogged" class="flex items-center py-2.5 px-4 hover:bg-pink-500 hover:text-pink-50 rounded transition duration-200" @click="openSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
                <span class="px-2">Logout</span>
            </router-link>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data() {
        return {
            isLogged: false
        }
    },
    props: [
        'showBack'
    ],
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        openSidebar() {
            const sidebar = document.querySelector('.sidebar')
            sidebar.classList.toggle("-translate-x-full")
        }
    },
    created() {
        if (localStorage.hasOwnProperty('api_token')) {
            this.isLogged = true
        }
    }
}
</script>