<template>
    <div class="relative min-h-screen md:flex overflow-hidden">
      <!-- nav bar -->
      <Navbar />
      <!-- content -->
      <div class="flex-1 px-4 py-10">
          <div v-if="!loading">
        <div class="mb-6 pb-4 text-center text-pink-900 text-2xl shadow-md rounded-sm">
            Welcome Back!
            <div class="text-sm">
                Please login first
            </div>
            </div>
            
            <div class="border-b-2 border-pink-700 bg-pink-50 rounded-sm px-3 py-3 shadow-xl focus-within:bg-pink-200">
                <!-- <label for="name" class="block text-xs font-bold text-pink-900 text-center peer-placeholder-shown:uppercase">Username</label> -->
                <input type="text" name="username" id="username" autocomplete="off" @keyup.enter="nextPlease" @keypress="errorUsername=false" v-model="username" class="text-center block w-full border-0 pt-1 text-pink-900 bg-pink-50 focus:bg-pink-200 placeholder-pink-500 focus:outline-none focus:ring-0 sm:text-sm" placeholder="Username" />
            </div>
            <div v-if="errorUsername" class="text-sm text-red-500 text-center font-bold">
                {{messageUsername}}
            </div>
            <div class="border-b-2 border-pink-700 bg-pink-50 rounded-sm px-3 py-3 shadow-xl focus-within:bg-pink-200 mt-4">
                <!-- <label for="name" class="block text-xs font-bold text-pink-900 text-center uppercase">Password</label> -->
                <input type="password" name="password" id="password" autocomplete="off" @keyup.enter="login" @keypress="errorPassword=false" v-model="password" class="password text-center block w-full border-0 pt-1 text-pink-900 bg-pink-50 focus:bg-pink-200 placeholder-pink-500 focus:outline-none focus:ring-0 sm:text-sm" placeholder="Password" />
            </div>
            <div v-if="errorPassword" class="text-sm text-red-500 text-center font-bold">
                {{messagePassword}}
            </div>

            <button @click="login" class="w-full text-center">
                <div class="border-2 border-pink-700 bg-pink-700 rounded-sm text-pink-100 font-bold px-3 py-3 shadow-xl focus-within:bg-pink-200 mt-4">
                    Login
                </div>
            </button>
        </div>

        <div v-else-if="loading">
            <div class="text-center text-pink-700 font-bold animate-bounce">
                ... you are logging in, please wait ...
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
    name: 'Login',
    components: {
        Navbar
    },
    data() {
        return {
            loading: false,
            username: '',
            password: '',
            errorUsername: false,
            messageUsername: '',
            errorPassword: false,
            errorPassword: '',
        }
    },
    methods: {
        nextPlease() {
            document.querySelector('.password').focus()
        },
        async login() {
            if (this.username.length == -1) {
                this.errorUsername = true
                this.messageUsername = 'Username can not be left blank'
            } else if (this.password.length == -1) {
                this.errorPassword = true
                this.messagePassword = 'Password can not be left blank'
            } else {
                this.loading = true
                const data = {
                            "params": {
                                "username": this.username,
                                "password": this.password
                            }
                        }
                try {
                    let url = ''
                    if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                        url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
                    } else {
                        url = process.env.VUE_APP_BACKEND_URL
                    }
                    const res = await fetch(url + "api/v2/user/login", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Origin': '*'
                        },
                        body: JSON.stringify(data),
                        redirect: 'follow',
                    });
                    const result = await res.json()
                    const r = JSON.parse(result.result)

                    if (!r.success) {
                        this.loading = false
                        if (r.username_error) {
                            this.errorUsername = true
                            this.messageUsername = r.message
                        } else if(r.password_error) {
                            this.errorPassword = true
                            this.messagePassword = r.message
                        }
                    } else if (r.success) {
                        localStorage.setItem("api_token", r.api_token);
                        this.$router.push({ name: 'SelectProject' })
                    }
                } catch(error) {
                    console.log(error)
                }
            }
            
        }
    },
    // plan on how to handle the expiry time for the token and in the app
    created() {
        this.loading = false
        this.username = ''
        this.password = ''
        this.errorUsername = false
        this.messageUsername = ''
        this.errorPassword = false
        this.errorPassword = ''

        if (localStorage.getItem("api_token")) {
            this.$router.push({ name: 'SelectProject' })
        }
    }
}
</script>