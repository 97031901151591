<template>
  <main v-if="!loading">
    <div class="text-center text-pink-800">
        You have total {{ total_racks }} racks for products.
    </div>
    <div class="flex items-center justify-center mt-4">
        <div class="grid grid-cols-3 gap-4">
            <div v-for="product_rack in product_racks" :key="product_rack.id" class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">{{product_rack.name}}</div>
            
        </div>
    </div>
  </main>

<main v-else>
    <div class="text-center text-pink-700 font-bold animate-bounce">
            ... loading product racks, please wait ...
    </div>
</main>
</template>

<script>

export default {
  name: 'Product',
  components: {
    
  },
  props: ['location_id'],
  data() {
      return {
          loading: true,
          product_racks: [],
          total_racks: 0,
      }
  },
  methods: {
      async fetchProductData() {
          let url = ''
          if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_BACKEND_URL
            }
          const res = await fetch(url + 'api/v2/rackproducts/'+this.location_id)
          const data = await res.json()
          return data
      }
  },
  async created() {
      if (!localStorage.hasOwnProperty('api_token')) {
        this.$router.push({ name: 'Login' })
      }
      const data = await this.fetchProductData()
      if(data.success) {
          this.product_racks = data.rack_products
          this.total_racks = this.product_racks.length
          this.loading = false
      } else {
          this.total_racks = 0
          this.loading = false
      }
  },
  mounted() {
      document.title = "Inventory Count App - Product"
  }
}
</script>