<template>

    <div>
        <!-- show errors here -->
        <div v-if="error && !lotLoading" class="my-4 bg-pink-600 text-white px-3 py-3 text-center shadow-sm rounded-md flex justify-center items-center">
            <div v-if="message">
                {{message}}
            </div>
            <div v-if="!message">
                There was an error loading the lot stocks, please try reloading the page
            </div>
        </div>

        <!-- actual content/lot details here -->
        <div v-if="!error && !lotLoading">
            <div class="text-center text-pink-700 font-bold mt-4 underline">
                Product Information
            </div>

            <div class="text-center text-pink-700 mt-1">
                Product Code : {{product}}
            </div>
            <div class="text-center text-pink-700 mt-1">
                Product Name : {{product_name}}
            </div>
            <div class="text-center text-pink-700 font-bold mt-1">
                Total Product Stock : {{total_product_quantity}}
            </div>

            <div v-if="total_lots">
                <div class="grid grid-cols-4 gap-2 text-center text-pink-50 mt-4">
                    <!-- table header goes here -->
                    <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
                        Lot
                    </div>
                    <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
                        Stock
                    </div>
                    <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
                        Reserve
                    </div>
                    <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
                        Rack
                    </div>
                </div>

                <!-- stock content goes here -->
                <div v-for="product_lot in product_lot_list" :key="product_lot.lot_name" class="grid grid-cols-4 gap-2 text-center text-pink-50 mt-1">
                    <div class="bg-pink-200 text-pink-800 py-0.5 px-1 rounded-md">
                        <span v-if="product_lot.lot_name == lot" class="font-bold">
                            {{product_lot.lot_name}}
                        </span>
                        <span v-else>
                            {{product_lot.lot_name}}
                        </span>
                    </div>
                    <div class="bg-pink-200 text-pink-800 py-0.5 px-1 rounded-md">
                        <span>
                            {{product_lot.quantity}}
                        </span>
                    </div>
                    <div class="bg-pink-200 text-pink-800 py-0.5 px-1 rounded-md">
                        <span>
                            {{product_lot.reserved_quantity}}
                        </span>
                    </div>
                    <div class="bg-pink-200 text-pink-800 py-0.5 px-1 rounded-md">
                        <span>{{product_lot.rack_id}}</span>
                    </div>
                </div>
            </div>
            <div v-if="!total_lots">
                <div class="my-4 bg-pink-600 text-white px-3 py-3 text-center shadow-sm rounded-md flex justify-center items-center">
                    There is no lot with stock for this product in the warehouse.
                </div>
            </div>
            

        </div>

        <!-- loading part here -->
        <div class="my-4 bg-pink-600 text-white px-3 py-3 text-center shadow-sm rounded-md flex justify-center items-center" v-if="lotLoading">
            <svg class="animate-spin h-5 w-5 text-white mx-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading all the lots and their stock from this product
        </div>

        
    </div>
    
</template>

<script>
export default {
    name: 'GetProductLotDetails',
    props: ['product', 'lot', 'lotLoading', 'error', 'message', 'product_lot_list', 'total_lots', 'total_product_quantity', 'product_name'],
    data() {
        return {

        }
    }
}
</script>