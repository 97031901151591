import inventorycount from '../../store/inventorycount'

const getProducts = (product_code) => {

    return new Promise(async (resolve, reject) => {
        try {
            let res = await fetch('http://192.144.82.169/api/v2/product/'+product_code+'/search', {
                                    method: "GET",
                                    headers: {
                                        'api_token': localStorage.getItem('api_token'),
                                    }
                                })

            let data = await res.json()

            if (data.success) {
    
                resolve(data.product_list)
                
            } else {

                reject(data.message)

            }
        }
        catch (err) {

            reject(err.message)
        }
    })

}

export default getProducts