<template>
    <BreadCrumb :title="loc_title"/>
    <div class="flex items-center justify-center">
        <div :class="setGridLentgh()">
            <div @click="changeLoc(location)" v-for="location in location_list" :key="location.id" class="px-5 py-4 cursor-pointer bg-pink-200 text-center text-pink-800 rounded-md shadow-md font-bold hover:bg-pink-800 hover:text-pink-50">
                {{location.name}}
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import BreadCrumb from './BreadCrumb.vue'
import inventorycount from '../../store/inventorycount'
import status from '../../store/status'
import getLocations from '../../composables/inventorycount/getLocations'

export default {
    name: 'ChooseToLocation',
    components: {
        BreadCrumb,
    },
    setup() {
        const loc_title = ref("Destination Location")

        const location_list = computed(() => inventorycount.state.location_list)

        const setGridLentgh = () => {
            if (location_list.value.length < 7) {
                return "grid grid-cols-2 gap-3"
            } else {
                return "grid grid-cols-3 gap-2"
            }
        }

        const changeLoc = async (loc_obj) => {

            // loading has some bug with lotsearch, need to track it later
            status.state.transferloading = true

            inventorycount.state.breadcrumb.push(loc_obj)
            inventorycount.state.filteredLots = []

            await getLocations(String(loc_obj.id))

            status.state.transferloading = false

        }

        return {
            loc_title,
            location_list,
            setGridLentgh,
            changeLoc
        }
    }
}
</script>