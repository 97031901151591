import inventorycount from '../../store/inventorycount'

const sendReceivedRequisition = (picking_id) => {

    return new Promise(async (resolve, reject) => {
        try {

            let res = await fetch('http://192.144.82.169/api/v3/requisition/' + picking_id + '/sent', {
                method: "GET",
                headers: {
                    'api_token': localStorage.getItem('api_token'),
                }
            })

            let data = await res.json()

            if (data.success) {

                resolve()

            } else {

                reject(data.message)

            }

        }
        catch (err) {
            reject(err.message)
        }
    })

}

export default sendReceivedRequisition