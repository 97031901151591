<template>
    
    <div class="flex items-center justify-center py-3 px-2 text-pink-700">

        <div v-if="loading">
            <Loading :message="loadingMsg" />
        </div>

        <div v-if="!loading">
            <div v-if="match">
            
                <strong>{{carrier_name}}</strong> is matched.

            </div>

            <div v-if="!match">
            
                <strong>{{carrier_name}}</strong> is not matched.

            </div>
        </div>

        
    </div>
    

</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import Loading from '../components/Loading.vue'

export default {
    name: 'GetOrderCarrier',
    components: {
        Loading,
    },
    props: {
        carrier_name: String,
        woocommerce_id: String,
    },
    setup(props, context) {

        const carrier = ref('')
        const match = ref(false)
        const router = useRouter()
        const loading = ref(true)
        const loadingMsg = ref('একটু অপেক্ষা করুন ...')

        const getOrderCarrier = async () => {
            loading.value = true
            carrier.value = ''
            match.value = false

            let url = process.env.VUE_APP_BACKEND_URL
            const res = await fetch(url + 'api/v2/order/' + props.woocommerce_id + '/carrier', {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
            const data = await res.json()
            if (data.success) {
                carrier.value = data.carrier_id
                if(data.carrier_id === props.carrier_name) {
                    match.value = true
                }
                window.scrollTo(0, 0);
                const emit_obj = {
                    'match': match.value,
                    'carrier_found': carrier.value,
                    'message': '',
                }
                context.emit('carrier:match', emit_obj)
            } else {
                if (data.message == 'API Token Invalid') {
                    localStorage.removeItem("api_token");
                    router.push({ name: 'Login' })
                }
                const emit_obj = {
                    'match': false,
                    'carrier_found': '',
                    'message': data.message,
                }
                context.emit('carrier:match', emit_obj)
            }

            setTimeout(() => {
                loading.value = false
            }, 1000)
            

        }

        if (props.woocommerce_id.length > 0) {
            getOrderCarrier()
        } else {
            console.log('no carrier found')
        }
       
        return {
           carrier,
           match,
           loadingMsg,
           loading,
        }

    },
}
</script>