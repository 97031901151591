<template>
    <div class="relative min-h-screen md:flex">
      <!-- nav bar -->
      
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />

      <!-- content -->
      <div class="flex-1 px-4 py-10">
        <div v-if="!loading && project_list">
            <div class="text-center text-pink-800">
                Please select your project
            </div>
            <div class="flex items-center justify-center mt-4">
                <div class="grid grid-cols-1 gap-2">
                    <div v-for="project in project_list" :key="project.id">

                        <div v-if="project.state == 'active'" class="mb-5">
                            <div class="text-center text-pink-700 px-8 py-1 bg-pink-100 mb-2 rounded-md shadow-md font-bold">
                                Running Projects
                            </div>
                            <button @click="selectProject(project.id)" class="flex items-center w-full justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                
                                {{project.name}}
                            
                            </button>
                        </div>

                        <div v-if="project.state == 'planned'" class="mb-5">
                            <div class="text-center text-pink-700 px-8 py-1 bg-pink-100 mb-2 rounded-md shadow-md font-bold">
                                Future Projects
                            </div>
                            <div  class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                
                                {{project.name}}
                            
                            </div>
                        </div>
                        
                    </div>

                    <div v-if="!has_project" class="text-center text-pink-700 font-bold px-8 py-1">
                        There is no project planned or running at this moment.
                    </div>
                    
                </div>
            </div>
        </div>

        <div v-else>
            <div class="text-center text-pink-700 animate-bounce font-bold">
                    ... loading projects, please wait ...
            </div>
        </div>
      </div>
    </div>

  
</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
  name: 'SelectProject',
  components: {
    Navbar
  },
  data() {
      return {
          loading: false,
          project_list: [],
          has_project: false,
          goBack: false
      }
  },
  methods: {
      selectProject(project_id) {
          if (typeof project_id !== undefined) {
              localStorage.setItem('project_id', project_id)
              this.$router.push({ name: 'SelectLocation' })
          }
      },
      async fetchProjects() {
          let url = ''
          if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_BACKEND_URL
            }
          const res = await fetch(url + 'api/v2/projects', {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
          const data = await res.json()
          return data
      }
  },
  async created() {
      if (!localStorage.hasOwnProperty('api_token')) {
        this.$router.push({ name: 'Login' })
      }
      window.scrollTo(0, 0);
      this.loading = true
      const data = await this.fetchProjects()
      if (data.success) {
          this.project_list = data.project_list
          if (this.project_list.length > 0) {
              this.has_project = true
          }
          this.loading = false
      } else {
          this.loading = false
          localStorage.removeItem("api_token");
          this.$router.push({ name: 'Login' })
      }
      
      if ((window.history.length - this.$root.historyCount) > 0) {
          this.goBack = true
      }

  },
  mounted() {
    document.title = 'Inventory Count App - Project'
  }
}
</script>