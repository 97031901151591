import inventorycount from '../../store/inventorycount'

const getLotInfo = (lot_name) => {

    return new Promise(async (resolve, reject) => {
        try {

            let res = await fetch('http://192.144.82.169/api/v3/inventory/lot/'+lot_name+'/details', {
                                    method: "GET",
                                    headers: {
                                        'api_token': localStorage.getItem('api_token'),
                                    }
                                })

            let data = await res.json()

            console.log(lot_name)
            console.log(data)

            if (data.success) {
    
                inventorycount.state.lot_details = data.lot_details
                resolve(data.lot_details)

            } else {

                inventorycount.state.error = data.message
                reject(data.message)

            }
        }
        catch (err) {
            inventorycount.state.error = err.message
            reject(err.message)
        }
    })

}

export default getLotInfo