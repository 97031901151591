<template>
    
    <div class="relative min-h-screen md:flex">

        <div v-if="isOffline" class="fixed w-screen bg-red-700 text-red-50 opacity-80 text-2xl px-5 py-4 text-center">
            দুঃখিত, আপনি অফলাইন। ইন্টারনেট চেক করুন
        </div>

        <div v-if="status == 'error'" class="fixed z-50 h-min inset-0 bg-red-700 text-red-50 opacity-100 text-4xl px-5 py-4">
            <div class="flex items-center justify-center h-screen">
                {{ scanned_word }}<br/><br/>
                {{ message }}
            </div>
        </div>

        <div v-if="status == 'success'" class="fixed z-50 h-min inset-0 bg-green-700 text-green-50 opacity-100 text-4xl px-5 py-4">
            <div class="flex items-center justify-center h-screen">
                {{ scanned_word }}<br/><br/>
                {{ message }}
            </div>
        </div>

        <div v-if="status == 'loading'" class="fixed z-50 h-min inset-0 bg-gray-700 text-gray-50 opacity-100 text-4xl px-5 py-4">
            <div class="flex items-center justify-center h-screen">
                LOADING ...
            </div>
        </div>
        
        <Navbar showBack="true" v-if="goBack" />
        <Navbar showBack="false" v-else />

        
        <div class="flex-1 px-4 py-10">

            <div class="flex items-center justify-center h-screen text-4xl">

                <div>
                    {{init}}
                </div>
                

            </div>
            
        </div>

    </div>

</template>

<script setup>

import { ref, watch } from 'vue'
import Navbar from '../components/Navbar.vue'

const isOffline = ref(navigator.isOffline)
let scanned_code = ref('')
const init = ref('WH কোডটি স্ক্যান করুন')
const scanned_word = ref('')
const status = ref('')
const done = ref(false)
const message = ref('')
const goBack = ref(false)
const loading = ref(false)

watch(scanned_word, (newVal, prevVal) => {
    status.value = 'loading'
    usePickingOrderStatus(scanned_word.value);
    
});

watch(status, (newVal, prevVal) => {

    setTimeout(() => {
        status.value = ''
        message.value = ''
    }, 10000)

})

window.addEventListener("keydown", e => {
    if (e.keyCode == 27) {
        status.value = ''
        message.value = ''
        scanned_code.value = ''
        init.value = 'WH কোডটি স্ক্যান করুন'
    }

})

window.addEventListener("keypress", e => {

    if (e.keyCode != 13) {

        if (init.value == 'WH কোডটি স্ক্যান করুন') {
            init.value = ''
        }

        scanned_code.value = scanned_code.value + String.fromCharCode(e.keyCode)
        init.value = scanned_code.value

    } else {
        // scanning the same code again, does not change the scanned_word value, it remains same. 
        // if (scanned_word.value === scanned_code) {

        //     if (setLevel.value != 'danger') {
        //         infomessage.value = 'একি অর্ডার আবার স্ক্যান করেছেন'
        //         setLevel.value = 'danger'
        //     }

        // }
        scanned_word.value = scanned_code.value
        scanned_code.value = ''
    }
});

const usePickingOrderStatus = async (p) => {

    try {

        const body_data = {
                            "params": {
                                "picking_id": p
                            }
                        }

        let res = await fetch('https://erp.shajgoj.com/api/v3/inventory/status', {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'api_token': localStorage.getItem('api_token'),
                                },
                                body: JSON.stringify(body_data),
                            })

        const result = await res.json()
        const data = result.result
        // const data = JSON.parse(result.result)

        console.log(data)

        if (data.success) {

            status.value = 'success'
            message.value = 'অর্ডারটি নিতে পারেন, অর্ডারটি কনফার্ম আছে'

        } else {

            status.value = 'error'
            message.value = 'দুঃখিত, অর্ডারটি কনফার্ম নেই, অর্ডারটি নিবেন না'

        }
    }
    catch (err) {
        status.value = 'error'
        message.value = err.message
    }

}

window.addEventListener('offline', e => {
    isOffline.value = true
})

window.addEventListener('online', e => {
    isOffline.value = false
})

</script>