<template>
    <div v-if="is_lot_details_exist">
        <label for="lot_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mb-1">Lot Name</label>
        <div class=" border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="lot_name" id="lot_name" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.name" disabled="1" />
        </div>

        <label for="product_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mb-1">Product Name</label>
        <div class=" border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_name" id="product_name" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.product_details.name" disabled="1" />
        </div>

        <label for="product_code" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Product Code</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_code" id="product_code" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.product_details.product_code" disabled="1" />
        </div>

        <label for="product_code" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Product Stock</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_code" id="product_code" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.product_details.stock" disabled="1" />
        </div>

        <label v-if="migration_type == 'transfer'" for="source_location" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Source Location</label>
        <div v-if="migration_type == 'transfer'" class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="source_location" id="source_location" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="migration.from_location.name" disabled="1" />
        </div>

        <label v-if="migration_type == 'transfer'" for="destination_location" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Destination Location</label>
        <div v-if="migration_type == 'transfer'" class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="destination_location" id="destination_location" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="migration.to_location.name" disabled="1" />
        </div>

        <label v-if="current_nav == 'count' && migration_type != 'transfer'" for="qty" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Inventoried Quantity</label>
        <div v-if="current_nav == 'count' && migration_type != 'transfer'" class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="inventoried_quantity" id="inventoried_quantity" autocomplete="off" class="block w-full border-0 p-0 bg-white text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" v-model="inv_qty" @focus="error=false"/>
        </div>

        <label v-if="migration_type == 'transfer'" for="qty" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Transfer Quantity</label>
        <div v-if="migration_type == 'transfer'" class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="transfer_qty" id="transfer_qty" autocomplete="off" class="block w-full border-0 p-0 bg-white text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" v-model="transfer_qty" @focus="error=false"/>
        </div>

        <button @click="postInventoryCount()" class="w-full mt-4 px-4 py-3 border border-pink-800 rounded-md shadow-sm bg-pink-600 text-pink-50 focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            Submit
        </button>
    </div>
    
</template>

<script>
import { computed, ref, watch } from 'vue'
import inventorycount from '../../store/inventorycount'

export default {
    name: 'InventoryCountForm',
    emits: ['loading:status', 'message:value', 'success:message'],
    setup(props, context) {

        const is_lot_details_exist = computed(() => {
            if (inventorycount.state.hasOwnProperty("lot_details")) {
                if (inventorycount.state.lot_details.hasOwnProperty('stock')) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        })

        const lot_details = computed(() => inventorycount.state.lot_details)

        const breadcrumb = computed(() => inventorycount.state.breadcrumb)

        const migration_id = computed(() => inventorycount.state.migration_id)

        const inv_qty = ref(inventorycount.state.inv_qty)

        const migration_type = computed(() => inventorycount.state.migration.type)

        const migration = computed(() => inventorycount.state.migration)

        const transfer_qty_comp = computed(() => inventorycount.state.migration.inventory_amount)

        const transfer_qty = ref(transfer_qty_comp.value)

        const current_nav = computed(() => inventorycount.state.current_nav)

        watch(inv_qty, (n, o) => {
            // check if the current value is number

            if (inv_qty.value.length != 0) {
                if (isNaN(parseInt(inv_qty.value))) {
                    inv_qty.value = 0.0
                }
            }

            // make sure to make this parseInt before sending
        })

        const postInventoryCount = async () => {

            context.emit("loading:status", {"status": true})

            let passed = true

            let data = {
                        "params": {
                            "lot_id": lot_details.value.name,
                            "location_id": breadcrumb.value[breadcrumb.value.length - 1].id,
                            "stock": inv_qty.value,
                            "breadcrumb": JSON.stringify(breadcrumb.value),
                            "migration_id": migration_id.value,
                            "lot_details": JSON.stringify(lot_details.value)
                        }
                    }

            if (migration_type.value == 'transfer') {
                // transfer_qty has to have some condition

                let stock_in_loc = 0.0
                lot_details.value.stock.forEach((n) => {
                    if (n.location_id == migration.value.from_location.id) {
                        stock_in_loc = n.stock
                    }
                })

                if (stock_in_loc < transfer_qty.value || transfer_qty.value == null || transfer_qty.value == '0') {
                    passed = false
                }

                data = {
                        "params": {
                            "lot_id": lot_details.value.name,
                            "stock": transfer_qty.value,
                            "breadcrumb": JSON.stringify(breadcrumb.value),
                            "migration_id": migration_id.value,
                            "lot_details": JSON.stringify(lot_details.value)
                        }
                    }
            }

            if (passed) {
                try {
                
                    let url = 'http://192.144.82.169/api/v3/inventory/migration/init'

                    if (migration_type.value == 'transfer') {
                        url = 'http://192.144.82.169/api/v3/inventory/' + migration.value.from_location.id +'/migrate/' +  migration.value.to_location.id
                    }

                    const res = await fetch(url, {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Origin': '*',
                            'api_token': localStorage.getItem('api_token'),
                        },
                        body: JSON.stringify(data),
                        redirect: 'follow',
                    });
                    const result = await res.json()

                    const r = JSON.parse(result.result)

                    if (r.success) {
                        
                        let successmsg = "Inventory Count is now posted"
                        if (migration_id.value != 0) {
                            successmsg = "Inventory Count for Migration " + String(migration_id.value) + " is now updated"
                        }

                        context.emit("success:message", {"successmessage": successmsg})

                        // we should get the migration in return and store it, instead of just ID

                        if (migration_type.value == 'init') {
                            inventorycount.state.migration_id = r.migration_id
                            inventorycount.state.inv_qty = inv_qty.value
                        }

                        if (migration_type.value == 'transfer') {
                            inventorycount.state.migration = r.migration
                        }
                        
                    } else {
                        if (r.message == 'API Token Invalid') {
                            localStorage.removeItem("api_token");
                        }
                        context.emit("message:value", {"message": r.message})
                    }
                } catch(error) {
                    context.emit("message:value", {"message": error})
                }
            } else {
                alert("You can not transfer more quantity than you have in that location")
            }
            
            context.emit("loading:status", {"status": false})
        }

        return {
           lot_details,
           breadcrumb,
           inv_qty,
           postInventoryCount,
           migration_type,
           migration,
           transfer_qty,
           current_nav,
           is_lot_details_exist
        }

    }
}
</script>