<template>
    <div class="vendorparent">
        <button @click="isOpen = !isOpen" class="vendordropdown pl-2 py-2 flex items-center justify-between text-sm bg-pink-200 text-pink-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2">
            <div class="pl-2 pr-1 text-sm">
                Select {{title}}
            </div>
            <div v-if="vendor_selected.length" class="font-bold">({{vendor_selected.length}})</div>
            <div class="px-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
        </button>
        <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed inset-0 h-full w-full bg-black bg-opacity-50 cursor-default"></button>
        <div v-if="isOpen" class="absolute mt-2 py-2 w-48 text-sm bg-pink-200 text-pink-800 rounded-md shadow-2xl">
            <ul>
                <li v-for="dropdown_list in dropdown_lists" :key="dropdown_list.key" @click="setVendor(dropdown_list.key)" class="flex items-center justify-between px-4 py-2 text-sm cursor-pointer hover:bg-pink-700 hover:text-pink-100">
                    <div>
                        {{dropdown_list.key}} ({{dropdown_list.doc_count}})
                    </div>
                    <div v-if="vendor_selected.includes(dropdown_list.key)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "VendorDropDown",
    props: ['title', 'dropdown_lists', 'removed_vendor'],
    data() {
        return {
            isOpen: false,
            vendor_selected: [],
        }
    },
    watch: {
        isOpen(newVal, oldVal) {
            if (newVal) {
                document.querySelector('.vendorparent').classList.add("relative")
                document.querySelector('.vendordropdown').classList.add("relative", "z-10")
            } else {
                document.querySelector('.vendorparent').classList.remove("relative")
                document.querySelector('.vendordropdown').classList.remove("relative", "z-10")
            }
        },
        removed_vendor() {
            this.vendor_selected = this.vendor_selected.filter(item => item !== this.removed_vendor)
        }
    },
    methods: {
        setVendor(vendor) {
            if (!this.vendor_selected.includes(vendor)) {
                this.vendor_selected.push(vendor)
                this.$emit('vendor:select', vendor)
            } else {
                this.vendor_selected = this.vendor_selected.filter(item => item !== vendor)
                this.$emit('vendor:remove', vendor)
            }
            
            // this.isOpen = false
        }
    },
    created() {
        const handleEscape = (e) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                this.isOpen = false
            }
        }

        document.addEventListener('keydown', handleEscape)
    }
}
</script>