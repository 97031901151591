<template>
    
    <div class="relative min-h-screen md:flex">
      
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />

      
      <div class="flex-1 px-4 py-10">
        <SetLocationId v-if="!location_id" @locationid:selected="selectLocationId" @locationname:selected="selectLocationName" />
        
        <div v-if="location_id" class="mb-4 ml-1">
            <span class="bg-pink-200 text-pink-800 rounded-md shadow-md text-center px-4 py-2 cursor-pointer" @click="resetLocationId()">Change Location</span> <span class="text-pink-700 font-bold px-2">{{location_name}}</span>
        </div>

        <GetNegativeLots v-if="location_id" :location_id="location_id" />
        
      </div>

    </div>

</template>

<script>
import { ref } from 'vue'
import Navbar from '../components/Navbar.vue'
import SetLocationId from '../components/SetLocationId.vue'
import GetNegativeLots from '../components/GetNegativeLots.vue'

export default {
    name: 'NegativeLot',
    components: {
        Navbar,
        SetLocationId,
        GetNegativeLots,
    },
    setup() {

        const goBack = ref(false)
        const location_id = ref('')
        const location_name = ref('')

        const selectLocationId = (loc_id) => {
            location_id.value = loc_id
        }

        const selectLocationName = (loc_name) => {
            location_name.value = loc_name
        }

        const resetLocationId = () => {
            location_id.value = ''
            location_name.value = ''
        }

        return {
            goBack,
            location_id,
            location_name,
            selectLocationId,
            selectLocationName,
            resetLocationId,
        }

    },
    // data() {
    //     return {
    //         goBack: false,
    //         location_id: '',
    //         location_name: '',
    //     }
    // },
    // methods: {
    //     selectLocationId(location_id) {
    //         this.location_id = location_id
    //     },
    //     selectLocationName(location_name) {
    //         this.location_name = location_name
    //     },
    //     resetLocationId() {
    //         this.location_id = ''
    //         this.location_name = ''
    //     },
    //     resetAll() {
    //         this.location_id = ''
    //         this.location_name = ''
    //     }
    // },
    // created() {
    //     if (!localStorage.hasOwnProperty('api_token')) {
    //         this.$router.push({ name: 'Login' })
    //     }

    //     if ((window.history.length - this.$root.historyCount) > 0) {
    //       this.goBack = true
    //     }
    // },
    // mounted() {
    //     document.title = 'Inventory Count App - Negative Lot Inventory'
    // }
}
</script>