const getLocName = (loc_id) => {

    return new Promise(async (resolve, reject) => {
        try {

            let res = await fetch('http://192.144.82.169/api/v3/inventory/location/' + loc_id + '/short', {
                                    method: "GET",
                                    headers: {
                                        'api_token': localStorage.getItem('api_token'),
                                    }
                                })

            let data = await res.json()

            if (data.success) {
    
                resolve(data.location_short_name)

            } else {

                reject(data.message)

            }
        }
        catch (err) {
            reject(err.message)
        }
    })

}

export default getLocName