<template>
    <div class="relative min-h-screen md:flex overflow-hidden">
      <!-- nav bar -->
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />
      <!-- content -->
        <div class="flex-1 py-10 px-5">
            <div class="mb-6 pb-4 text-center text-pink-900 text-2xl shadow-md rounded-sm">
                Change Your Password
                <div class="text-sm">
                    Enter your current password first, and then the new password
                </div>
            </div>

            <div class="mb-6 bg-pink-600 text-white px-3 py-3 text-center shadow-sm rounded-sm flex justify-center items-center" v-if="loading">
                <svg class="animate-spin h-5 w-5 text-white mx-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                We are changing your password
            </div>

            <div class="mb-6 bg-pink-600 text-white px-3 py-3 text-center shadow-sm rounded-sm" v-if="error">
                {{message}}
            </div>

            <div class="mb-6 bg-green-400 text-green-50 px-3 py-3 text-center shadow-sm rounded-sm" v-if="success">
                {{message}}
            </div>
                
            <div class="border-b-2 border-pink-700 bg-pink-50 rounded-sm px-3 py-3 shadow-xl focus-within:bg-pink-200">
                <!-- <label for="name" class="block text-xs font-bold text-pink-900 text-center peer-placeholder-shown:uppercase">Username</label> -->
                <input type="password" name="currentpassword" id="currentpassword" autocomplete="off" @keypress="error=false; message=''" v-model="currentPassword" class="text-center block w-full border-0 pt-1 text-pink-900 bg-pink-50 focus:bg-pink-200 placeholder-pink-500 focus:outline-none focus:ring-0 sm:text-sm" placeholder="Current Password" :disabled="loading || success" />
            </div>

            <div class="border-b-2 border-pink-700 bg-pink-50 rounded-sm px-3 py-3 shadow-xl focus-within:bg-pink-200 mt-4">
                <!-- <label for="name" class="block text-xs font-bold text-pink-900 text-center uppercase">Password</label> -->
                <input type="password" name="newpassword" id="newpassword" autocomplete="off" @keyup.enter="changePassword" @keypress="error=false; message=''" v-model="newPassword" class="password text-center block w-full border-0 pt-1 text-pink-900 bg-pink-50 focus:bg-pink-200 placeholder-pink-500 focus:outline-none focus:ring-0 sm:text-sm" placeholder="New Password" :disabled="loading || success" />
            </div>

            <button class="w-full border-2 border-pink-700 bg-pink-700 rounded-sm text-pink-100 font-bold px-3 py-3 shadow-xl focus:bg-pink-600 mt-4 text-center" @click="changePassword">
                Change Password
            </button>
            
            
            
        </div>

    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
    name: 'ChangePassword',
    components: {
        Navbar
    },
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            errorNew: false,
            errorCurrent: false,
            messageNew: '',
            messageCurrent: '',
            goBack: false,
            error: false,
            message: '',
            success: false,
            loading: false,
        }
    },
    methods: {
        async changePassword() {
            this.loading = true
            this.error = false
            this.success = false
            try {
                    let url = ''
                    if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                        url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
                    } else {
                        url = process.env.VUE_APP_BACKEND_URL
                    }

                    const body_data = {
                            "params": {
                                "current_password": this.currentPassword,
                                "password": this.newPassword
                            }
                        }
                    const res = await fetch(url + 'api/v2/user/password', {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Origin': '*',
                                        'api_token': localStorage.getItem('api_token'),
                                    },
                                    body: JSON.stringify(body_data),
                                })
                                
                    const result = await res.json()
                    const r = JSON.parse(result.result)

                    if (!r.success) {
                        this.loading = false
                        this.error = true
                        this.message = r.message
                    }  else {
                        this.loading = false
                        this.success = true
                        this.message = 'Your password is now changed'
                        setTimeout(() => {
                            this.success = false
                        }, 6000)
                    }
                } catch(error) {
                    this.error = true
                    this.message = error

                    console.log(error)
                }
        }
    },
    created() {
        if (!localStorage.hasOwnProperty('api_token')) {
            this.$router.push({ name: 'Login' })
        }

        if ((window.history.length - this.$root.historyCount) > 0) {
            this.goBack = true
        }
    }
}
</script>