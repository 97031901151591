<template>
    <div class="relative min-h-screen md:flex">
        
        <Navbar showBack="true" v-if="goBack" />
        <Navbar showBack="false" v-else />

        <div class="flex-1 px-4 py-3">

            <div class="text-3xl text-center px-4 py-3 text-pink-800 font-bold">
                <span>Congregated Product Stock</span>
                <span class="block text-sm text-pink-600">Warehouse (WH) | Jamuna Future Park (JFP) | Shimanto Square (SSQ)</span>
                <span class="block text-xs text-pink-600 font-semibold">Movement Date From : {{from_date}} To : {{to_date}}</span>
            </div>

            <div class="flex justify-end items-center mb-2">

                <!-- <button class="bg-pink-700 text-pink-50 font-bold px-4 py-2 rounded-md shadow-md">Download</button> -->

                <button class="flex items-center justify-center bg-pink-700 text-pink-50 font-bold px-4 py-2 rounded-md shadow-md" @click="downloadReport">
                    <div class="px-2" v-if="downloadLoading">
                        <svg class="animate-spin h-5 w-5 text-pink-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    <div>
                        <span v-if="downloadLoading">Downloading</span>
                        <span v-if="!downloadLoading">Download</span>
                    </div>
                </button>

            </div>

            <div v-if="loading" class="bg-pink-800 opacity-80 absolute inset-0"></div>

            <div v-if="loading && loadingMessage" class="fixed z-10 top-1/3 left-1/2 text-white text-2xl font-bold">
                <div v-for="msg in loadingMessage" :key="msg">
                    {{msg}}<br />
                </div>
            </div>

            <div class="grid grid-cols-6 md:grid-cols-4 mt-1 mb-2 text-gray-400 rounded-sm shadow-sm border border-pink-600 focus-within:shadow-md focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="col-span-2 md:col-span-1 px-4 flex items-center justify-start border-0 focus:outline-none">
                    <span class="">Product</span>
                </div>

                <div class="col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none">
                    <input class="w-full h-full focus:outline-none text-center text-pink-700" name="lot_id" type="text" autocomplete="off" v-model="productTerm" />
                </div>

                
                <div class="flex items-center justify-end px-4 col-span-2 md:col-span-1 border-0 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </div>
            </div>

            <div class="flex items-center justify-center py-1 px-6 mt-1 mb-2 text-pink-50 bg-pink-700 rounded-sm shadow-sm">
                <div class="px-4">
                    Total <span class="font-bold">{{movements.length}}</span> number of products found
                </div>
            </div>

            <div class="grid grid-cols-9 gap-1 text-sm text-pink-900 font-bold">
                <div class="text-center  px-2 py-2 mb-1 bg-pink-300 shadow-sm rounded-sm">
                    Product Code
                </div>

                <div class="text-center px-2 py-2 mb-1 col-span-2 truncate bg-pink-300 shadow-sm rounded-sm">
                    Product Name
                </div>

                <div class="text-center  px-2 py-2 mb-1 bg-pink-300 shadow-sm rounded-sm">
                    WH Stock
                </div>

                <div class="text-center  px-2 py-2 mb-1 bg-pink-300 shadow-sm rounded-sm">
                    Movement
                </div>

                <div class="text-center  px-2 py-2 mb-1 bg-pink-300 shadow-sm rounded-sm">
                    JFP Stock
                </div>

                <div class="text-center  px-2 py-2 mb-1 bg-pink-300 shadow-sm rounded-sm">
                    Movement
                </div>

                <div class="text-center  px-2 py-2 mb-1 bg-pink-300 shadow-sm rounded-sm">
                    SSQ Stock
                </div>

                <div class="text-center  px-2 py-2 mb-1 bg-pink-300 shadow-sm rounded-sm">
                    Movement
                </div>
            </div>
            


            <div v-for="movement in movements.slice(0, startingSlice)" :key="movement.product_code" class="grid grid-cols-9 gap-1 text-sm text-pink-900">
                <div class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    {{movement.product_code}}
                </div>

                <div class="text-center px-2 py-2 mb-1 col-span-2 bg-pink-200 font-bold shadow-sm rounded-sm">
                    <span class="block truncate" @mouseover="enableTooltip(movement.product_code)" @mouseout="disableTooltip(movement.product_code)">{{movement.product_name}}</span>
                    <span :class="{'absolute':true, 'border-2': true, 'border-pink-700': true, 'rounded-sm': true, 'shadow-sm': true, 'bg-pink-100': true, 'px-4': true, 'py-2': true, ['product-tooltip-' + movement.product_code]:true, 'hidden': true}">{{movement.product_name}}</span>
                </div>

                <div v-if="movement.stock" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    {{movement.stock}}
                </div>

                <div v-if="!movement.stock" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    0
                </div>

                <div v-if="movement.movement" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    {{movement.movement.toFixed(2)}}
                </div>

                <div v-if="!movement.movement" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    0.00
                </div>

                <div v-if="movement.jfp_stock" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    {{movement.jfp_stock.toFixed(2)}}
                </div>

                <div v-if="!movement.jfp_stock" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    0
                </div>

                <div v-if="movement.jfp_movement" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    {{movement.jfp_movement.toFixed(2)}}
                </div>

                <div v-if="!movement.jfp_movement" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    0.00
                </div>

                <div v-if="movement.ssq_stock" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    {{movement.ssq_stock.toFixed(2)}}
                </div>

                <div v-if="!movement.ssq_stock" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    0
                </div>

                <div v-if="movement.ssq_movement" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    {{movement.ssq_movement.toFixed(2)}}
                </div>

                <div v-if="!movement.ssq_movement" class="text-center px-2 py-2 mb-1 bg-pink-200 shadow-sm rounded-sm">
                    0.00
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
import { ref, watch, onBeforeUnmount } from 'vue'
import getProductMovements from '../composables/getProductMovements'
import getProductMovementJfp from '../composables/getProductMovementJfp'
import getProductMovementSsq from '../composables/getProductMovementSsq'
import Navbar from '../components/Navbar.vue'

export default {
    name: 'ProductMovement',
    components: {
        Navbar,
    },
    setup() {
        const goBack = ref(false)
        const movements = ref([])
        const original_movements = ref([])
        const loading = ref(true)
        const loadingMessage = ref([])
        let doneLocation = ''
        const loadCount = ref('0')
        const productTerm = ref('')
        const backup_movements = ref([])
        const startingSlice = ref(150)
        const to_date = ref('')
        const from_date = ref('')
        const downloadLoading = ref(false)

        const { result, error, load } = getProductMovements("13", 'week', "28")
        const { jfp_result, jfp_error, jfp_load } = getProductMovementJfp("20", 'week', "28")
        const { ssq_result, ssq_error, ssq_load } = getProductMovementSsq("26", 'week', "28")
        loadingMessage.value.push('Loading ...')

        // init load
        load().then( () => {

            original_movements.value = result.value.movements
            movements.value = original_movements.value
            to_date.value = result.value.to_date
            from_date.value = result.value.from_date
            loadCount.value = parseInt(loadCount.value) + 1
            loadingMessage.value.push('Warehouse Loaded')
            loadingMessage.value.push('Loading ...')

        })


        // watchers
        watch(original_movements, () => {

            if (original_movements.value.length > 0) {
                
                // what about the products that are in jfp and ssq, but not in wh

                jfp_load().then( () => {

                    movements.value.forEach( (wh_item) => {
                        
                        let check = 0
                        jfp_result.value.movements.forEach( (jfp_item) => {
                            if (wh_item.product_code == jfp_item.product_code) {

                                wh_item['jfp_stock'] = jfp_item.stock
                                wh_item['jfp_movement'] = jfp_item.movement
                                check = 1

                            }
                        })

                        if (check == 0) {
                            wh_item['jfp_stock'] = 0.0
                            wh_item['jfp_movement'] = 0.0
                        }

                    })

                    jfp_result.value.movements.forEach( (jfp_item) => {

                        let check = 0
                        movements.value.forEach( (wh_item) => {

                            if (jfp_item.product_code == wh_item.product_code) {

                                check = 1

                            }

                        })

                        if (check == 0) {

                            let newEntry = {
                                product_id: jfp_item.product_id,
                                product_code: jfp_item.product_code,
                                product_name: jfp_item.product_name,
                                stock: 0.0,
                                movement: 0.0,
                                jfp_stock: jfp_item.stock,
                                jfp_movement: jfp_item.movement,
                            }
                            movements.value.push(newEntry)

                        }

                    })

                    loadCount.value = parseInt(loadCount.value) + 1
                    loadingMessage.value.push('JFP Loaded')

                    if (loadCount.value == 3) {
                        loadingMessage.value.push('Done')
                    } else {
                        loadingMessage.value.push('Loading ...')
                    }

                    // doneLocation = doneLocation + 'JFP Loaded, '
                    // if (loadCount.value == 3) {
                    //     loadingMessage.value = doneLocation + 'Done.'
                    // } else {
                    //     loadingMessage.value = doneLocation + 'Loading ...'
                    // }
                    
                })

                

                ssq_load().then( () => {

                    movements.value.forEach( (wh_item) => {
                        
                        let check = 0
                        ssq_result.value.movements.forEach( (ssq_item) => {
                            if (wh_item.product_code == ssq_item.product_code) {

                                wh_item['ssq_stock'] = ssq_item.stock
                                wh_item['ssq_movement'] = ssq_item.movement
                                check = 1

                            }
                        })

                        if (check == 0) {
                            wh_item['ssq_stock'] = 0.0
                            wh_item['ssq_movement'] = 0.0
                        }

                    })

                    ssq_result.value.movements.forEach( (ssq_item) => {

                        let check = 0
                        movements.value.forEach( (wh_item) => {

                            if (ssq_item.product_code == wh_item.product_code) {

                                check = 1

                            }

                        })

                        if (check == 0) {

                            let newEntry = {
                                product_id: ssq_item.product_id,
                                product_code: ssq_item.product_code,
                                product_name: ssq_item.product_name,
                                stock: 0.0,
                                movement: 0.0,
                                ssq_stock: ssq_item.stock,
                                ssq_movement: ssq_item.movement,
                            }
                            movements.value.push(newEntry)

                        }

                    })
                    
                    loadCount.value = parseInt(loadCount.value) + 1
                    loadingMessage.value.push('SSQ Loaded')

                    if (loadCount.value == 3) {
                        loadingMessage.value.push('Done')
                    } else {
                        loadingMessage.value.push('Loading ...')
                    }

                    // doneLocation = doneLocation + 'SSQ Loaded, '
                    // if (loadCount.value == 3) {
                    //     loadingMessage.value = doneLocation + 'Done.'
                    // } else {
                    //     loadingMessage.value = doneLocation + 'Loading ...'
                    // }
                    
                })
            }

        })

        watch(loadCount, () => {
            if (loadCount.value == 3) {
                backup_movements.value = movements.value
                setTimeout( () => {
                    
                    loading.value = false
                    
                }, 1000)
                
            }
        })

        watch(productTerm, () => {

            startingSlice.value = 75
            
            if (productTerm.value.length === 0) {
                movements.value = backup_movements.value
            } else {

                movements.value = backup_movements.value
                movements.value = movements.value.filter( movement => {
                    return movement.product_code.toLowerCase().startsWith(productTerm.value) || movement.product_name.toLowerCase().startsWith(productTerm.value) || movement.product_name.toLowerCase().includes(productTerm.value)
                })

            }

        })
        
        // Methods
        const enableTooltip = (product_code) => {
            document.querySelector('.product-tooltip-' + product_code).classList.remove('hidden')
        }

        const disableTooltip = (product_code) => {
            document.querySelector('.product-tooltip-' + product_code).classList.add('hidden')
        }

        const extendSlice = () => {

            if (startingSlice.value < movements.value.length) {

                if ( (movements.value.length - startingSlice.value) >= 150 ) {
                    startingSlice.value = startingSlice.value + 150
                } else {
                    startingSlice.value = movements.value.length - startingSlice.value
                }
                    
            }

        }

        const downloadReport = () => {

            downloadLoading.value = true
            setTimeout(() => {
                // define file
                let csvContent = "data:text/csv;charset=utf-8,";
                
                // add header
                let row = "Product Code, Product Name, WH Stock, Movement, JFP Stock, Movement, SSQ Stock, Movement"
                csvContent += row + "\r\n"

                // add contents
                movements.value.forEach( (movement) => {

                    let wh_stock = movement.stock
                    let wh_movement = movement.movement
                    let jfp_stock = movement.jfp_stock
                    let jfp_movement = movement.jfp_movement
                    let ssq_stock = movement.ssq_stock
                    let ssq_movement = movement.ssq_movement

                    if (wh_stock) {
                        wh_stock = wh_stock.toFixed(2)
                    } else {
                        wh_stock = 0
                    }

                    if (wh_movement) {
                        wh_movement = wh_movement.toFixed(2)
                    } else {
                        wh_movement = 0
                    }

                    if (jfp_stock) {
                        jfp_stock = jfp_stock.toFixed(2)
                    } else {
                        jfp_stock = 0
                    }

                    if (jfp_movement) {
                        jfp_movement = jfp_movement.toFixed(2)
                    } else {
                        jfp_movement = 0
                    }

                    if (ssq_stock) {
                        ssq_stock = ssq_stock.toFixed(2)
                    } else {
                        ssq_stock = 0
                    }

                    if (ssq_movement) {
                        ssq_movement = ssq_movement.toFixed(2)
                    } else {
                        ssq_movement = 0
                    }

                    let row = movement.product_code + "," + '"' + movement.product_name + '"' + "," + wh_stock + "," + wh_movement + "," + jfp_stock + "," + jfp_movement + "," + ssq_stock + "," + ssq_movement
                    csvContent += row + "\r\n"
                })

                // download
                var encodedUri = encodeURI(csvContent)
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "product_stock.csv");
                link.click()
                downloadLoading.value = false
            }, 2000)
        }

        // lifecycle hooks
        onBeforeUnmount( () => {
            console.log("worked")
            window.removeEventListener('scroll', extendSlice);
        })

        // window listeners
        window.addEventListener('scroll', extendSlice);

        return {
            startingSlice,
            goBack,
            movements,
            loading,
            loadingMessage,
            productTerm,
            to_date,
            from_date,
            downloadLoading,
            enableTooltip,
            disableTooltip,
            extendSlice,
            downloadReport,
        }

    }
}
</script>

<style>

</style>