<template>
  
    <div class="mt-4 ml-1">
        <div v-if="error" class="px-4 py-3 bg-pink-200 text-pink-800 text-center font-bold rounded-sm shadow-sm">{{error}}</div>
        <div v-if="!loading">
    
            <div class="mb-3 flex items-center justify-start">
                <!-- <NegativeLotDropDown :racks="racks" @rack:selected="selectRack" /> -->
            </div>

            <div class="px-4 py-3 bg-pink-200 text-pink-800 text-center font-bold mb-3">You have total {{total}} lots in negative</div>
            
            <div v-if="lots" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <router-link :to="{ name: 'ProductInventory', params: { locationid: location_id, productcode: lot.product_code }}" v-for="lot in lots" :key="lot._id" class="h-32">
                    <div class="flex items-center justify-between px-3 py-1 bg-pink-700 text-pink-100 text-center rounded-t-sm">
                        <div class="font-bold">
                            {{lot.lot_name}}
                        </div>
                        <div>
                            {{lot.product_code}}
                        </div>
                    </div>

                    <div class="px-3 py-3 bg-pink-200 text-pink-900 text-center truncate">
                        {{lot.product_name}}
                    </div>

                    <div class="flex items-center justify-between px-3 py-1 bg-pink-600 text-pink-50 text-center rounded-b-sm">
                        <div class="font-bold">
                            Stock : {{lot.stock}}
                        </div>
                        <div v-if="lot.lot_rack.toString() != ''">
                            Rack : {{lot.lot_rack.toString()}}
                        </div>
                        <div v-else>
                            Rack : N/A
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <div v-if="loading">
            <div v-if="loading" class="text-pink-700 text-center py-4 flex justify-center items-center">
                <div>
                    <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                <div class="px-4">
                    Searching negative lots ...
                </div>
            </div>
        </div>
        
    </div>



</template>

<script>
import { ref } from 'vue'
import getLots from '../composables/getLots'
import NegativeLotDropDown from './NegativeLotDropdown.vue'


export default {
    name: "GetNegativeLots",
    props: ['location_id'],
    components: {
        NegativeLotDropDown,
    },
    setup(props) {
        
        const { lots, error, load } = getLots(props.location_id)
        const total = ref(null)
        const loading = ref(true)
        const racks = ref([])
        const lots_show = ref([])

        total.value = 0

        load().then( () => {
            total.value = lots.value.length
            lots_show.value = lots.value
            loading.value = false
            // racks.value = aggs.value.rack_ids.buckets
        })

        const selectRack = (rack_selected) => {

            if (rack_selected.value.length) {
                let new_lots = []
                lots.value.forEach( (item) => {
                    if (item._source.wh_lot_rack) {
                        rack_selected.value.forEach( (rack_id) => {
                            item._source.wh_lot_rack.forEach( (wh_lot_rack) => {
                                if (rack_id == wh_lot_rack) {
                                    new_lots.push(item)
                                }
                            })
                        })
                    } else {
                        rack_selected.value.forEach( (rack_id) => {
                            if (rack_id == 'NA') {
                                new_lots.push(item)
                            }
                        })
                    }
                })

                lots_show.value = new_lots
                total.value = lots_show.value.length
            } else {
                lots_show.value = lots.value
                total.value = lots.value.length
            }

            
        }

        return {
            lots : lots_show, 
            racks, error, total, loading, selectRack
        }

    }
}
</script>

<style>

</style>