<template>
    <LotSearch v-if="!getlotinfo" @getlotinfo="afterGetLotInfo"/>

    <div v-if="getlotinfo">

        <div @click="getlotinfo = !getlotinfo" class="text-center text-pink-700 font-bold cursor-pointer">Select Location & Stock</div>

        <label for="lot_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mb-1">Lot Name</label>
        <div class=" border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="lot_name" id="lot_name" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.name" disabled="1" />
        </div>

        <label for="product_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mb-1">Product Name</label>
        <div class=" border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_name" id="product_name" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.product_details.name" disabled="1" />
        </div>

        <label for="product_code" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Product Code</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_code" id="product_code" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.product_details.product_code" disabled="1" />
        </div>

        <div class="hidden sm:grid grid-cols-2 gap-2 text-center text-pink-50 mt-4">
            <!-- table header goes here -->
            <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
                Location
            </div>
            <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
                Stock
            </div>
        </div>

        <!-- stock content goes here -->
        <div v-for="lot_detail in lot_details.stock" :key="lot_detail.location_name" class="grid grid-cols-1 sm:grid-cols-2 gap-1 sm:gap-2 text-center text-pink-50 mt-3 sm:mt-1">
            <label for="location_name" class="inline-block px-10 bg-pink-500 text-sm font-medium text-pink-50 w-full text-center pb-2 pt-1 -mb-2 sm:hidden">Location</label>
            <button v-if="lot_detail.location_id != from_location_id" @click="selectFromLoc(lot_detail.location_id)" class="w-full bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md hover:bg-pink-700 hover:text-pink-50">
                <span>
                    {{lot_detail.location_name}}
                </span>
            </button>

            <button v-if="lot_detail.location_id == from_location_id" @click="deselectFromLoc()" class="w-full bg-pink-800 text-pink-50 py-2 sm:py-0.5 px-1 rounded-md hover:bg-pink-700 hover:text-pink-50">
                <span>
                    {{lot_detail.location_name}}
                </span>
            </button>
            

            <label for="product_lot_stock" class="inline-block px-10 bg-pink-500 text-sm font-medium text-pink-50 w-full text-center pb-2 pt-1 -mb-2 sm:hidden">Stock</label>
            <div class="bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md">
                <span>{{lot_detail.stock}}</span>
            </div>

            <label v-if="lot_detail.location_id == from_location_id" for="transfer_stock" class="inline-block md:hidden px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Transfer Quantity</label>
            <div v-if="lot_detail.location_id == from_location_id" class="block md:hidden border border-pink-800 rounded-md px-2 py-1 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                <input ref="infocus" type="text" name="transfer_stock" id="transfer_stock" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" autofocus v-model="transfer_qty" />
            </div>
            
        </div>

        <div v-if="from_location_id != 0" class="hidden md:block">
            <label for="transfer_stock" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Transfer Quantity</label>
            <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                <input ref="infocus" type="text" name="transfer_stock" id="transfer_stock" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" autofocus v-model="transfer_qty" />
            </div>
        </div>

        <button @click="confirmLoc()" :class="setConfirmClass()">
            Confirm Source Location
        </button>
    </div>
</template>

<script>
import { ref, computed, nextTick, watch } from 'vue'
import LotSearch from './LotSearch.vue'
import inventorycount from '../../store/inventorycount'

export default {
    name: 'ChooseFromLocation',
    emits: ["fromlocationselected", "transferqty", "lotdetails"],
    components: {
        LotSearch,
    },
    setup(props, context) {
        const getlotinfo = ref(false)
        const lot_details = computed(() => inventorycount.state.lot_details)
        const from_location_id = computed(() => inventorycount.state.from_location_id)
        const infocus = ref(null)
        const transfer_qty = ref(null)

        const selectFromLoc = (loc_id) => {
            inventorycount.state.from_location_id = loc_id
            
            nextTick(() => {
                infocus.value.focus()
            })
        }

        const deselectFromLoc = () => {
            inventorycount.state.from_location_id = 0
        }

        watch(getlotinfo, (n, o) => {
            console.log("trig")
        })

        const setConfirmClass = () => {

            if (from_location_id.value == 0 || transfer_qty.value == null) {
                return "w-full mt-2 py-2 bg-pink-500 text-pink-50 rounded-sm"
            }

            return "w-full mt-2 py-2 bg-pink-800 text-pink-50 font-bold rounded-sm"
        }

        const confirmLoc = () => {

            let stock_in_loc = 0.0
            lot_details.value.stock.forEach((n) => {
                if (n.location_id == from_location_id.value) {
                    stock_in_loc = n.stock
                }
            })

            if (from_location_id.value == 0 || transfer_qty.value == null) {
                alert("Please select the source location or enter transfer qty")
            } else if (stock_in_loc < transfer_qty.value) {

                alert("You have entered more quantity than the available stock")

            } else {
                context.emit("fromlocationselected", {"status": true})
                context.emit("transferqty", {"amount": transfer_qty.value})
            }
            
        }

        const afterGetLotInfo = (n) => {
            getlotinfo.value = n.status
            inventorycount.state.from_location_id = 0
        }

        return {
            getlotinfo,
            lot_details,
            selectFromLoc,
            setConfirmClass,
            deselectFromLoc,
            from_location_id,
            confirmLoc,
            afterGetLotInfo,
            transfer_qty,
            infocus
        }
    }
}
</script>