<template>
    <div class="relative min-h-screen md:flex">
      
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />

      
      <div class="flex-1 px-4 py-10">

        <button v-if="isOpen" @click="isOpen = false; lineadded = false; submitFormState = false;" tabindex="-1" class="fixed inset-0 h-full w-full bg-black bg-opacity-80 cursor-default"></button>

        <div v-if="isOpen && lineadded" class="relative">
            <div class="absolute left-0 right-0 ml-auto mr-auto">
                <input type="text" placeholder="Enter Lot" class="w-full mb-3 px-8 py-3 rounded-md shadow-md bg-pink-100 text-pink-900 placeholder-pink-700 placeholder-opacity-50 text-center font-bold border-2 border-pink-300 focus:outline-none focus:placeholder-opacity-80 focus:ring-2 focus:ring-offset-2 focus:ring-pink-500" v-model="searchLot">
                <ul class="w-full rounded-md shadow-md bg-pink-50 text-pink-900 text-center font-bold border">
                    <li v-for="lot_id in lot_ids.slice(0, 15)" :key="lot_id.lot_id" class="py-2 m-1 rounded-md bg-pink-600 text-pink-50 cursor-pointer hover:bg-pink-700" @click="addLot(lot_id.lot_name)">
                        {{lot_id.lot_name}}
                    </li>
                </ul>
                
            </div>
            
        </div>

        <div v-if="isOpen && submitFormState" class="relative">
            <div class="absolute w-full">
                <div class="w-full h-full p-4 bg-pink-100 text-center rounded-md shadow-md">
                    <div class="px-2 text-3xl font-extrabold text-pink-900">Confirm?</div>

                    <div class="mt-2 mb-5 text-pink-700 text-sm">
                        Current Total : {{current_data.current_total}}<br />
                        Inventoried Total : {{current_data.inventoried_total}}<br />
                        Difference : <strong>{{current_data.difference}}</strong><br />

                        <span v-if="current_data.difference < 0" class="block font-bold text-md text-red-600">Quantity Increased</span>
                        <span v-if="current_data.difference == 0" class="block font-bold text-green-600">Quantity Remained Same</span>
                        <span v-if="current_data.difference > 0" class="block font-bold text-red-600">Quantity Decreased</span>
                    </div>

                    <div class="m-2">
                        <button class="bg-pink-700 text-pink-100 px-4 py-2 rounded-md" @click="confirmForm('ok')">Ok</button>
                        <button class="ml-2 bg-pink-700 text-pink-100 px-4 py-2 rounded-md" @click="confirmForm('modify')">Modify</button>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="text-center text-pink-800 text-lg">
            <strong>{{product_code}}</strong> - {{product_name}}
        </div>

        <div class="text-center text-pink-700 text-sm">
            Quantity : <strong>{{quantity}}</strong>
        </div>

        <div class="text-center mt-4">

            <div class="flex items-center justify-between mb-2">
                <button class="px-8 py-2 rounded-md shadow-md font-bold text-pink-800 bg-pink-300 hover:bg-pink-500 hover:text-pink-50 focus:outline-none focus:bg-pink-500 focus:text-pink-50 focus:ring-2 focus:ring-pink-400 focus:ring-offset-2" @click="addLine">
                    <div v-if="!isOpen">Add Line</div>
                    <div v-if="isOpen">Close Input</div>
                </button>
                <div>
                    <button class="px-8 py-2 rounded-md shadow-md font-bold text-pink-800 bg-pink-300 hover:bg-pink-500 hover:text-pink-50 focus:outline-none focus:bg-pink-500 focus:text-pink-50 focus:ring-2 focus:ring-pink-400 focus:ring-offset-2" @click="expandAll">
                        <div v-if="!expanded">Expand All</div>
                        <div v-if="expanded">Shrink All</div>
                    </button>
                    <button class="ml-2 px-8 py-2 rounded-md shadow-md font-bold text-pink-800 bg-pink-300 hover:bg-pink-500 hover:text-pink-50 focus:outline-none focus:bg-pink-500 focus:text-pink-50 focus:ring-2 focus:ring-pink-400 focus:ring-offset-2" @click="resetAll">
                        <div>Reset</div>
                    </button>
                </div>
                
            </div>
            
            <div v-for="lot in lots" :key="lot.lot_name" class="mb-2">
                <div class="flex items-center rounded-md focus-within:ring-2 focus-within:ring-pink-300 focus-within:ring-offset-2 focus-within:ring-offset-pink-100">
                    <button class="w-5/6 px-8 py-4 rounded-l-md shadow-sm font-bold text-pink-50 bg-pink-800 border-2 border-pink-200 hover:bg-pink-700 hover:text-pink-100 focus:outline-none" @click="expandLot(lot.lot_name)">
                        {{lot.lot_name}}
                    </button>
                    <button class="w-1/6 px-7 py-4 flex items-center justify-center rounded-r-md shadow-md bg-pink-600 hover:bg-pink-500" @click="closeLot(lot.lot_name)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-pink-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                

                <div class="mt-2 text-pink-800 hidden" :class="`expand-lot-${lot.lot_name}`">
                    <div class="px-8 py-2 mb-1 flex items-center justify-between bg-pink-200 rounded-md">
                        <div class="">Rack <strong>({{lot.rack_id}})</strong></div>
                        <select class="w-32 pl-3 pr-7 box-border bg-pink-200 focus:outline-none" @change="selectLot(lot.lot_name, $event.target.value)">
                            <option v-for="rack in racks" :key="rack.id" :value="rack.name">
                                {{rack.name}}
                            </option>
                        </select>
                        <!-- <button v-if="!rackOpened" @click="rackOpened = true" class="text-center">
                            Select Rack
                        </button>
                        <div v-if="rackOpened" class="relative">
                            <ul class="absolute -left-32 -top-5 w-32 px-4 py-2 bg-pink-200">                                
                                <li v-for="rack in racks" :key="rack.id" @click="rackOpened = false">
                                    {{rack.name}}
                                </li>
                            </ul>
                        </div> -->
                    </div>

                    <div class="px-8 py-2 flex items-center justify-between bg-pink-200 rounded-md">
                        <div class="">Qty (<strong>{{lot.quantity}}</strong>)</div>
                        <div><input type="number" class="w-32 pl-3 pr-7 bg-pink-200 focus:outline-none" v-model="lot.quantity"></div>
                    </div>
                </div>

            </div>
            <div v-if="lotExists">
                <button class="w-full px-8 py-3 rounded-md shadow-md text-pink-50 bg-pink-600 hover:bg-pink-500 hover:text-pink-50 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-offset-2" @click="submitForm">
                    Submit
                </button>
            </div>

            <div v-if="!lotExists">
                <button class="w-full px-8 py-3 rounded-md shadow-md text-pink-50 bg-pink-600 hover:bg-pink-500 hover:text-pink-50 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-offset-2" @click="addLine">
                    There is no lot added, please add a lot
                </button>
            </div>
        </div>
        
      </div>

    </div>
</template>

<script>
import { ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import Navbar from '../components/Navbar.vue'
import getProductLotByLocation from '../composables/getProductLotByLocation'
import getLotsForProduct from '../composables/getLotsForProduct'
import getRackIds from '../composables/getRackIds'

export default {
    name: 'ProductInventory',
    components: {
        Navbar,
    },
    setup() {
        window.scrollTo(0, 0);
        const route = useRoute()
        const product_code = ref(route.params.productcode)
        const location_id = ref(route.params.locationid)
        const goBack = ref(false)
        const product_name = ref(null)
        const quantity = ref(null)
        const lots = ref(null)
        let store_lots = []
        const expanded = ref(false)
        const lineadded = ref(false)
        const searchLot = ref(null)
        const isOpen = ref(false)
        const lot_ids = ref([])
        const backup_lot_ids = ref([])
        const racks = ref([])
        const rackOpened = ref(false)
        const selected = ref(null)
        const lotExists = ref(true)
        const submitFormState = ref(false)
        const current_data = ref({})

        const { data, error, load } = getProductLotByLocation('https://erp.shajgoj.com/', location_id.value, product_code.value)

        const { lotData, lotError, loadLots } = getLotsForProduct('https://erp.shajgoj.com/', product_code.value, 'all')

        const { rackData, rackError, loadRacks } = getRackIds('https://erp.shajgoj.com/', location_id.value)

        load().then(() => {
            loadRacks().then(() => {
                racks.value = rackData.value.rack_lots
                racks.value.unshift({
                    id: 'n/a',
                    name: 'N/A',
                })
            })
            product_name.value = data.value.product_name
            quantity.value = data.value.quantity
            lots.value = data.value.product_lot_list
        })

        watchEffect((searchLot, () => {
            if (searchLot.value) {
                lot_ids.value = backup_lot_ids.value.filter((lot_id) => {
                    return lot_id.lot_name.includes(searchLot.value)
                })

                if (lot_ids.value.length == 0) {
                    lot_ids.value.push({
                        lot_id: 991919191919,
                        lot_name: 'No Lot Available',
                    })
                }
            } else {
                lot_ids.value = backup_lot_ids.value
            }
        }))

        const submitForm = () => {

            console.log(lots.value)

            isOpen.value = !isOpen.value
            submitFormState.value = !submitFormState.value

            let total_quantity = 0.0
            lots.value.forEach(lot => {
                total_quantity = total_quantity + parseInt(lot.quantity)
            })

            load().then(() => {


                current_data.value = {
                    current_total: data.value.quantity,
                    inventoried_total: total_quantity,
                    difference: data.value.quantity - total_quantity,
                }

                
                // data.value.product_name
                // data.value.quantity
                // data.value.product_lot_list
            })

        }

        const expandLot = (lot_name) => {
            const expand_element = document.querySelector('.expand-lot-'+lot_name)
            expand_element.classList.toggle('hidden')
        }

        const addLine = () => {
            searchLot.value = ''
            isOpen.value = !isOpen.value
            lineadded.value = !lineadded.value
            lot_ids.value.push({
                        lot_id: 9919191919191920,
                        lot_name: 'Loading lots for product ' + product_code.value + '...',
                    })
            loadLots().then(() => {
                lot_ids.value = lotData.value.lot_ids
                if (lot_ids.value.length == 0) {
                    lot_ids.value.push({
                        lot_id: 99191919191919,
                        lot_name: 'No Lot Available',
                    })
                }
                backup_lot_ids.value = lotData.value.lot_ids
            })
        }

        const expandAll = () => {
            lots.value.forEach((lot) => {
                const expand_element = document.querySelector('.expand-lot-'+lot.lot_name)
                if (expanded.value) {
                    expand_element.classList.add('hidden')
                } else {
                    expand_element.classList.remove('hidden')
                }
                // expand_element.classList.toggle('hidden')
            })
            expanded.value = !expanded.value
        }

        const addLot = (lot_name) => {
            let check = 0
            lots.value.forEach((lot_in_array) => {
                if (lot_in_array.lot_name == lot_name) {
                    check = 1
                    alert(lot_name + ' already exist in the current list')
                    lineadded.value = false
                    isOpen.value = false
                }
            })

            if (check == 0) {
                lots.value.unshift({
                    lot_name: lot_name,
                    rack_id: 'N/A',
                    quantity: 0
                })

                isOpen.value = false

                setTimeout(() => {
                    expandLot(lot_name)
                }, 1000)
            }
            lineadded.value = false

            if (lots.value.length === 0) {
                lotExists.value = false
            } else {
                lotExists.value = true
            }
        }

        const selectLot = (lot_name, rack_name) => {
            lots.value.forEach((lot) => {
                if (lot.lot_name == lot_name) {

                    if (rack_name == 'N/A') {
                        lot.rack_id = 'N/A'
                    } else {
                        let rack_list = lot.rack_id.split(',')
                        if (!rack_list.includes(rack_name)) {
                            rack_list.push(rack_name)
                        }

                        if (rack_list.length > 1) {
                            rack_list = rack_list.filter(item => item !== 'N/A')
                        }

                        lot.rack_id = rack_list.join()
                    }

                    
                }
            })
        }

        const inputQty = (lot_name, lot_quantity) => {
            lots.value.forEach((lot) => {
                if (lot.lot_name == lot_name) {
                    lot.quantity = parseInt(lot_quantity)
                }
            })
        }

        const closeLot = (lot_name) => {

            lots.value = lots.value.filter(lot => lot.lot_name !== lot_name)

            if (lots.value.length === 0) {
                lotExists.value = false
            } else {
                lotExists.value = true
            }

        }

        const resetAll = () => {
            load().then(() => {
                loadRacks().then(() => {
                    racks.value = rackData.value.rack_lots
                    racks.value.unshift({
                        id: 'n/a',
                        name: 'N/A',
                    })
                })
                product_name.value = data.value.product_name
                quantity.value = data.value.quantity
                lots.value = data.value.product_lot_list
            })
        }

        const confirmForm = (status) => {
            if (status == 'ok') {

            }

            if (status == 'modify') {
                isOpen.value = false
                submitFormState.value = false
            }
        }


        return {
            goBack,
            product_code,
            location_id,
            product_name,
            quantity,
            lots,
            submitForm,
            expandLot,
            addLine,
            expandAll,
            expanded,
            lineadded,
            searchLot,
            isOpen,
            lot_ids,
            addLot,
            racks,
            rackOpened,
            selectLot,
            selected,
            inputQty,
            closeLot,
            lotExists,
            resetAll,
            submitFormState,
            current_data,
            confirmForm
        }
    }
}
</script>

<style>

</style>