import inventorycount from '../../store/inventorycount'

const getLotSearchResult = (name) => {

    return new Promise(async (resolve, reject) => {
        try {

            if (name.length == 0) {
                
                inventorycount.state.filteredLots = []
                resolve([])

            } else {

                let res = await fetch('http://192.144.82.169/api/v2/lot/'+name+'/search', {
                    method: "GET",
                    headers: {
                        'api_token': localStorage.getItem('api_token'),
                    }
                })

                let data = await res.json()

                if (data.success) {

                if (name.length > 0) {
                    inventorycount.state.filteredLots = data.lot_list
                    resolve(data.lot_list)
                } else {
                    inventorycount.state.filteredLots = []
                    resolve([])
                }

                } else {

                    inventorycount.state.error = data.message
                    reject(data.message)

                }
            }

        }
        catch (err) {
            inventorycount.state.error = err.message
            reject(err.message)
        }
    })

}

export default getLotSearchResult