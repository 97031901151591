<template>
    <div class="px-10 bg-pink-800 text-md font-bold text-pink-100 w-full text-center py-1 mt-4 mb-1">Product Stock</div>
    <div class="hidden sm:grid grid-cols-1 sm:grid-cols-3 gap-1 sm:gap-2 text-center text-pink-50 mt-1 sm:mt-2">
        <!-- table header goes here -->
        <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
            Lot
        </div>
        <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
            Location
        </div>
        <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
            Stock
        </div>
    </div>

    <!-- stock content goes here -->
    <div v-for="product_lot_detail in lot_details.product_details.stock_in_lot_by_location" :key="product_lot_detail.lot_name + product_lot_detail.location_name" class="grid grid-cols-1 sm:grid-cols-3 gap-1 sm:gap-2 text-center text-pink-50 mt-3 sm:mt-1">
        <label for="lot_name" class="inline-block px-10 bg-pink-500 text-sm font-medium text-pink-50 w-full text-center pb-2 pt-1 -mb-2 sm:hidden">Lot Name</label>
        <div v-if="product_lot_detail.location_id != breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md">
            <span>
                {{product_lot_detail.lot_name}}
            </span>
        </div>
        <div v-if="product_lot_detail.location_id == breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md font-extrabold">
            <span>
                {{product_lot_detail.lot_name}}
            </span>
        </div>
        <label for="location_name" class="inline-block px-10 bg-pink-500 text-sm font-medium text-pink-50 w-full text-center pb-2 pt-1 -mb-2 sm:hidden">Location</label>
        <div v-if="product_lot_detail.location_id != breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md">
            <span>
                {{product_lot_detail.location_name}}
            </span>
        </div>
        <div v-if="product_lot_detail.location_id == breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md font-extrabold">
            <span>
                {{product_lot_detail.location_name}}
            </span>
        </div>
        <label for="product_lot_stock" class="inline-block px-10 bg-pink-500 text-sm font-medium text-pink-50 w-full text-center pb-2 pt-1 -mb-2 sm:hidden">Stock Quantity</label>
        <div v-if="product_lot_detail.location_id != breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md">
            <span>{{product_lot_detail.stock}}</span>
        </div>
        <div v-if="product_lot_detail.location_id == breadcrumb[breadcrumb.length - 1].id" class="bg-pink-200 text-pink-800 py-2 sm:py-0.5 px-1 rounded-md font-extrabold">
            <span>{{product_lot_detail.stock}}</span>
        </div>
    </div>

    <!-- total -->
    <div class="grid grid-cols-3 gap-2 text-center text-pink-50 mt-2 font-bold">
        <div class="col-span-2 bg-pink-500 text-pink-50 py-0.5 px-1 rounded-md">
            <span>
                Total
            </span>
        </div>
        <div class="bg-pink-400 text-pink-50 py-0.5 px-1 rounded-md">
            <span>{{total_stock}}</span>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import inventorycount from '../../store/inventorycount'

export default {
    name: 'ProductStockDetails',
    setup() {

        const lot_details = computed(() => inventorycount.state.lot_details)

        const breadcrumb = computed(() => inventorycount.state.breadcrumb)

        const total_stock = computed(() => {
            let total = 0.0
            inventorycount.state.lot_details.product_details.stock_in_lot_by_location.forEach((item) => {
                total = total + item.stock
            })

            return total
        })

        return {
           lot_details,
           breadcrumb,
           total_stock
        }

    }
}
</script>