<template>
    <div class="relative min-h-screen md:flex">
      <!-- nav bar -->
      
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />

      <!-- content -->
      <div class="flex-1 px-4 py-10">
        <div v-if="!loading && location_ids">
            <div class="text-center text-pink-800">
                Please select your Inventory Location
            </div>
            <div class="flex items-center justify-center mt-4">
                <div class="grid grid-cols-1 gap-2">
                    <router-link v-for="location_id in location_ids" :key="location_id.id" :to="{ name: 'SelectLotProduct', query: { location_id: location_id.id } }">
                        <div  class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                            
                            {{location_id.name}}
                        
                        </div>
                    </router-link>
                    
                </div>
            </div>
        </div>

        <div v-else>
            <div class="text-center text-pink-700 animate-bounce font-bold">
                    ... loading locations, please wait ...
            </div>
        </div>
      </div>
    </div>

  
</template>

<script>
import Navbar from '../components/Navbar.vue'
// import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Location',
  components: {
    Navbar
  },
  data() {
      return {
          loading: false,
          location_ids: [],
          goBack: false
      }
  },
  computed: {
    //   ...mapState(["global_location_id"])
  },
  methods: {

    //   ...mapMutations(["setGlobalLocationId"]),

      async fetchLocations() {

          let url = process.env.VUE_APP_BACKEND_URL
            
          const res = await fetch(url + 'api/v2/locations/list', {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
                        
          const data = await res.json()
          return data
      }
  },
  async created() {
      if (!localStorage.hasOwnProperty('api_token')) {
        this.$router.push({ name: 'Login' })
      }
      window.scrollTo(0, 0);
      this.loading = true
      const data = await this.fetchLocations()
      if (data.success) {
          this.location_ids = data.location_list
          this.loading = false
      } else {
          this.loading = false
          localStorage.removeItem("api_token");
          this.$router.push({ name: 'Login' })
      }
      
      if ((window.history.length - this.$root.historyCount) > 0) {
          this.goBack = true
      }

  },
  mounted() {
    document.title = 'Inventory Count App - Location'
  }
}
</script>