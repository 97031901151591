<template>
  
    <div class="w-screen px-5 py-3">

        <button @click="happens()" class="px-3 py-2 bg-pink-300 mb-4 text-pink-800 rounded-md shadow-md font-bold">
            Press Here
        </button>

        <div class="grid grid-cols-4 gap-2">

            <div :class="[{ 'bg-pink-600': strength.length >= 1 }, {'animate-pulse' : sdup.length == 1}]" class="h-4 rounded-l-lg">
                
            </div>

            <div :class="[{ 'bg-pink-600': strength.length >= 2 }, {'animate-pulse' : sdup.length == 2}]" class="h-4">
                
            </div>

            <div :class="[{ 'bg-pink-600': strength.length >= 3 }, {'animate-pulse' : sdup.length == 3}]" class="h-4">
                
            </div>

            <div :class="[{ 'bg-pink-600': strength.length >= 4 }, {'animate-pulse' : sdup.length == 4}]" class="h-4 rounded-r-lg">
                
            </div>
            
        </div>
    </div>
    

</template>

<script>
import { ref, computed } from 'vue'
export default {
    name: 'TailwindTest',
    setup() {

        const strength = ref([])
        const sdup = computed(() => strength.value)

        const happens = () => {
            
            if (strength.value.length < 4) {
                strength.value.push(Math.random())
            }

        }

        return {
            happens,
            strength,
            sdup
        }

    }

}
</script>

<style>

</style>