import inventorycount from '../../store/inventorycount'

const getRecentInventories = () => {

    return new Promise(async (resolve, reject) => {
        try {

            let res = await fetch('http://192.144.82.169/api/v3/inventory/recent', {
                method: "GET",
                headers: {
                    'api_token': localStorage.getItem('api_token'),
                }
            })

            let data = await res.json()

            if (data.success) {

                inventorycount.state.migration_list = data.migration_list
                resolve(data.migration_list)

            } else {

                inventorycount.state.error = data.message
                reject(data.message)

            }

        }
        catch (err) {
            inventorycount.state.error = err.message
            reject(err.message)
        }
    })

}

export default getRecentInventories