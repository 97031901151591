import { ref } from 'vue'

const getLots = (loc_id) => {

    const lots = ref([])
    const error = ref(null)
    // const aggs = ref(null)

    const load = async () => {
        try {

            let res = await fetch('https://erp.shajgoj.com/api/v2/lot/negative/' + loc_id, {
                                    method: "GET",
                                    headers: {
                                        'api_token': localStorage.getItem('api_token'),
                                    }
                                })
            let data = await res.json()

            if (data.success) {

                lots.value = data.lot_ids
                // console.log(lots.value)
                // aggs.value = data.aggs

            } else {

                error.value = data.message

            }
        }
        catch (err) {
            error.value = err.message
        }
    }

    return {
        lots, error, load
    }

}

export default getLots