<template>
    <div>
        <div class="text-center text-pink-700 font-bold">Transfer Summary</div>
        <label for="lot_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Lot</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="lot_name" id="lot_name" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.name" disabled="1" />
        </div>

        <label for="product_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Product Name</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_name" id="product_name" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 truncate focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.product_details.name" disabled="1" />
        </div>

        <label for="product_code" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Product Code</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_code" id="product_code" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="lot_details.product_details.product_code" disabled="1" />
        </div>

        <label for="source_location" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Source Location</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="source_location" id="source_location" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="from_loc_name" disabled="1" />
        </div>

        <label for="destination_location" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Destination Location</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="destination_location" id="destination_location" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="dest_loc_name" disabled="1" />
        </div>

        <label for="transfer_qty" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Transfer Quantity</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="transfer_qty" id="transfer_qty" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="transfer_qty" disabled="1" />
        </div>
    </div>

    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-2">
        <button @click="completeTransfer()" class="w-full px-3 py-2 bg-green-700 text-green-50 rounded-sm shadow-sm hover:bg-green-600">
            Confirm
        </button>
        <button class="w-full px-3 py-2 bg-red-700 text-red-50 rounded-sm shadow-sm hover:bg-red-600">
            Cancel
        </button>
    </div>
</template>

<script>
import { computed } from 'vue'
import inventorycount from '../../store/inventorycount'
import status from '../../store/status'
export default {
    name: 'CompleteTransfer',
    props: ["fromloc", "destloc", "transferqty"],
    setup(props) {
        const from_loc_name = computed(() => props.fromloc)
        const dest_loc_name = computed(() => props.destloc)
        const transfer_qty = computed(() => props.transferqty)
        const lot_details = computed(() => inventorycount.state.lot_details)
        const breadcrumb = computed(() => inventorycount.state.breadcrumb)
        const migration_id = computed(() => inventorycount.state.migration_id)

        const completeTransfer = async () => {

            status.state.transferloading = true

            let from_id = String(inventorycount.state.from_location_id)
            let to_id = String(breadcrumb.value[breadcrumb.value.length - 1].id)

            const data = {
                        "params": {
                            "lot_id": lot_details.value.name,
                            "stock": transfer_qty.value,
                            "breadcrumb": JSON.stringify(breadcrumb.value),
                            "migration_id": migration_id.value,
                            "lot_details": JSON.stringify(lot_details.value)
                        }
                    }

            console.log(data)
            try {
                
                const res = await fetch('http://192.144.82.169/api/v3/inventory/' + from_id + '/migrate/' + to_id, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': '*',
                        'api_token': localStorage.getItem('api_token'),
                    },
                    body: JSON.stringify(data),
                    redirect: 'follow',
                });
                const result = await res.json()

                const r = JSON.parse(result.result)

                if (r.success) {
                    
                    status.state.successmsg = "Transfer Request is posted"
                    // if (migration_id.value != 0) {
                    //     successmsg = "Inventory Count for Migration " + String(migration_id.value) + " is now updated"
                        
                    // }

                    // inventorycount.state.migration_id = r.migration_id
                    // inventorycount.state.inv_qty = inv_qty.value

                } else {
                    if (r.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                    }
                    status.state.message = r.message
                
                }
            } catch(error) {
                status.state.message = error
                
            }
            status.state.transferloading = false
        }

        return {
            from_loc_name,
            dest_loc_name,
            transfer_qty,
            lot_details,
            completeTransfer
        }

    }
}
</script>