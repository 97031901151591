<template>
    <div @click="changePage" class="px-3 py-2 -ml-4 -mr-4 text-center cursor-pointer font-semibold text-pink-800 bg-pink-100 shadow-sm">
        {{title}}
    </div>

    <div class="flex items-center justify-center text-pink-800">
        <div v-if="breadcrumb.length > 0" class="px-4 py-3 flex items-center text-center uppercase">
            <div v-for="b in breadcrumb" :key="b.id">
                <div @click="moveLoc(b.id)" v-if="b == breadcrumb[0]" class="font-bold cursor-pointer hover:underline">{{b.name}}</div>
                <div v-else class="flex items-center">
                    <div class="text-xs px-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-pink-500 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                        </svg>
                    </div>
                    <div @click="moveLoc(b.id)" class="font-bold cursor-pointer hover:underline">{{b.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import inventorycount from '../../store/inventorycount'
import status from '../../store/status'
import getLocations from '../../composables/inventorycount/getLocations'

export default {
    name: 'BreadCrumb',
    emits: ["loading:status", "return:data"],
    props: ["title"],
    setup(props, context) {

        const breadcrumb = computed(() => inventorycount.state.breadcrumb)

        const title = computed(() => props.title)

        const moveLoc = async (loc_id) => {

            // 1. update breadcrumb by eliminating all items after loc_id
            // 2. fetch the childs of that location id

            context.emit("loading:status", {"status": true})
            // loading.value = true // needs to use event for this
            inventorycount.state.filteredLots = []

            let i = 0;
            let newCrumb = []
            for(;inventorycount.state.breadcrumb[i];i++) {

                if (inventorycount.state.breadcrumb[i].id == loc_id) {
                    newCrumb.push(inventorycount.state.breadcrumb[i])
                    break
                }

                newCrumb.push(inventorycount.state.breadcrumb[i])

            }

            inventorycount.state.breadcrumb = newCrumb
            let return_data = await getLocations(String(loc_id));

            // if we have reached the bin where we store the products, we get nothing else in return
            if (return_data.length == 0) {
                context.emit("return:data", {"length": 0})
            } else {
                context.emit("return:data", {"length": return_data.length})
            }

            context.emit("loading:status", {"status": false})
            // loading.value = false // needs to use event for this

        }

        const changePage = () => {
            status.state.fromloc = false
        }

        return {
            breadcrumb,
            moveLoc,
            title,
            changePage
        }

    }
}
</script>