import { ref } from 'vue'

const getRackIds = (url, loc_id) => {

    const rackData = ref([])
    const rackError = ref(null)

    const loadRacks = async () => {
        try {

            let res = await fetch(url + 'api/v2/racklots/' + loc_id, {
                                    method: "GET",
                                    headers: {
                                        'api_token': localStorage.getItem('api_token'),
                                    }
                                })
            let return_val = await res.json()

            if (return_val.success) {

                rackData.value = return_val

            } else {

                rackError.value = return_val.message

            }
        }
        catch (err) {
            rackError.value = err.message
        }
    }

    return {
        rackData, rackError, loadRacks
    }

}

export default getRackIds