<template>

    <div class="relative min-h-screen md:flex">

        <div v-if="isOffline" class="fixed w-screen bg-red-700 text-red-50 opacity-80 text-2xl px-5 py-4 text-center">
            Sorry, you are offline! Please check your internet!
        </div>
        
        <Navbar showBack="true" v-if="goBack" />
        <Navbar showBack="false" v-else />

        
        <div class="flex-1 px-4 py-10">

            <InventoryHeader 
                @showsearchIH="(n) => showSearch = n.status"
                @showproductIH="(n) => showProduct = n.status"
            />

            <div v-if="message" @click="message = ''" class="px-3 py-2 -ml-4 -mr-4 -mt-2 cursor-pointer bg-red-600 text-red-50 font-bold text-center">
                {{message}}
            </div>

            <div v-if="successMsg" @click="successMsg = ''" class="px-3 py-2 -ml-4 -mr-4 -mt-2 cursor-pointer bg-green-700 text-green-50 font-bold text-center">
                {{successMsg}}
            </div>

            <div v-if="loading && !message">
                <Loading :message="loadingMsg" />
            </div>

            <div v-if="!loading && !message">

                <div v-if="current_nav == 'count' || current_nav == ''">

                    <!-- show breadcrumb -->
                    <BreadCrumb @return:data="setReturn" @loading:status="(n) => loading = n.status" :title="loc_title"/>
                    <!-- breadcrumb ends -->

                    <!-- show rack & bins starts -->
                    <div v-if="!showSearch && !showProduct" class="flex items-center justify-center">
                        <div :class="setGridLentgh()">
                            <div @click="changeLoc(location)" v-for="location in location_list" :key="location.id" class="px-5 py-4 cursor-pointer bg-pink-200 text-center text-pink-800 rounded-md shadow-md font-bold hover:bg-pink-800 hover:text-pink-50">
                                {{location.name}}
                            </div>
                        </div>
                    </div>
                    <!-- show rack & bins ends -->

                    <!-- lot search starts -->
                    <LotSearch v-if="showSearch && !showProduct" 
                        :defaultlot="lot_key"
                        @loading:status="(n) => loading = n.status" 
                        @showproduct="(n) => showProduct = n.status"
                        @showsearch="(n) => showSearch = n.status"
                    />
                    <!-- lot search ends -->

                    <!-- inventory product starts -->
                    <div v-if="showProduct && !showSearch">

                        <!-- create the form to show product details and take inventory count -->
                        <InventoryCountForm 
                            @loading:status="(n) => loading = n.status"
                            @message:value="(n) => message = n.message"
                            @success:message="(n) => successMsg = n.successmessage"
                        />

                        <!-- section for lot stock by location -->
                        <div v-if="lot_details_stock_exist">
                            <LotStockDetails />
                        </div>

                        <!-- section for product stock by lot by location -->
                        <div v-if="lot_details_product_stock_exist">
                            <ProductStockDetails />
                        </div>
                    </div>
                    <!-- inventory product ends -->

                </div>

                <div v-if="current_nav == 'recent'">
                    <RecentInventories />
                </div>

                <div v-if="current_nav == 'transfer'">
                    <InventoryTransfer />
                </div>

                <div v-if="current_nav == 'requisition'">

                    <Requisitions />

                </div>

                <div v-if="current_nav == 'stock'">

                    <ShowStocks />

                </div>

                

            </div>
            
        </div>

    </div>

</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import Navbar from '../../components/Navbar.vue'
import Loading from '../../components/Loading.vue'
import BreadCrumb from '../../components/inventorycount/BreadCrumb.vue'
import LotSearch from '../../components/inventorycount/LotSearch.vue'
import LotStockDetails from '../../components/inventorycount/LotStockDetails.vue'
import ProductStockDetails from '../../components/inventorycount/ProductStockDetails.vue'
import InventoryCountForm from '../../components/inventorycount/InventoryCountForm.vue'
import InventoryHeader from '../../components/inventorycount/InventoryHeader.vue'
import RecentInventories from '../../components/inventorycount/RecentInventories.vue'
import InventoryTransfer from '../../components/inventorycount/InventoryTransfer.vue'
import Requisitions from '../../components/inventorycount/Requisitions.vue'
import ShowStocks from '../../components/inventorycount/ShowStocks.vue'
import getLocations from '../../composables/inventorycount/getLocations'
import inventorycount from '../../store/inventorycount'
import status from '../../store/status'

export default {
    name: 'InventoryCount',
    components: {
        Navbar,
        Loading,
        BreadCrumb,
        LotSearch,
        LotStockDetails,
        ProductStockDetails,
        InventoryCountForm,
        InventoryHeader,
        RecentInventories,
        InventoryTransfer,
        Requisitions,
        ShowStocks,
    },
    setup() {

        const goBack = ref(false)
        const current_nav = computed(() => inventorycount.state.current_nav)
        const message = ref('')
        const loading = ref(true)
        const loadingMsg = ref('একটু অপেক্ষা করুন ...')
        const infomessage = ref('অর্ডার বারকোড স্ক্যান করুন')
        const loc_title = ref("Location")
        const setLevel = ref('info')
        const isOffline = ref(navigator.isOffline)
        const lot_key = ref('')
        const successMsg = ref('')

        const location_list = computed(() => inventorycount.state.location_list)

        const breadcrumb = computed(() => inventorycount.state.breadcrumb)

        const filteredLots = computed(() => inventorycount.state.filteredLots)

        const lot_details = computed(() => inventorycount.state.lot_details)

        const showsearch = computed(() => inventorycount.state.showsearch)
        const showproduct = computed(() => inventorycount.state.showproduct)
        const showloading = computed(() => inventorycount.state.showloading)

        const lot_details_stock_exist = computed(() => {
            if (inventorycount.state.hasOwnProperty("lot_details")) {
                if (inventorycount.state.lot_details.hasOwnProperty('stock')) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        })
        const lot_details_product_stock_exist = computed(() => {
            if (inventorycount.state.hasOwnProperty("lot_details")) {
                if (inventorycount.state.lot_details.hasOwnProperty('product_details')) {
                    if (inventorycount.state.lot_details.product_details.hasOwnProperty('stock_in_lot_by_location')) {
                        return true
                    } else {
                        return false
                    }

                } else {
                    return false
                }
            } else {
                return false
            }
        })

        // loading has some bug with lotsearch in transfers, need to track it later
        const transloading = computed(() => status.state.transferloading)

        watch(successMsg, (n, o) => {
            setTimeout(() => {
                successMsg.value = ''
            }, 6000)
        })

        watch(message, (n, o) => {
            setTimeout(() => {
                message.value = ''
            }, 6000)
        })

        watch(showsearch, (n, o) => {
            showSearch.value = showsearch.value
        })

        watch(showproduct, (n, o) => {
            showProduct.value = showproduct.value
        })

        watch(showloading, (n, o) => {
            loading.value = showloading.value
            console.log(loading.value)
        })

        const showSearch = ref(false)
        const showProduct = ref(false)

        window.addEventListener('offline', e => {
            isOffline.value = true
        })

        window.addEventListener('online', e => {
            isOffline.value = false
        })

        watch(lot_key, async (newVal, prevVal) => {

            await getLotSearchResult(lot_key.value)

        })

        onMounted( async () => {
            inventorycount.state.filteredLots = []
            inventorycount.state.breadcrumb = [{
                'id': 13,
                'name': 'WH/Stock'
            }];
            await getLocations('13')
            loading.value = false
        });


        // set the return value from breadcrumb
        const setReturn = async (val) => {
            if (val.length == 0) {

                showSearch.value = true 
                showProduct.value = false 
                lot_key.value = '' 

            } else {
                showSearch.value = false 
                showProduct.value = false 
            }
        }

        const changeLoc = async (loc_obj) => {

            loading.value = true

            inventorycount.state.breadcrumb.push(loc_obj)
            inventorycount.state.filteredLots = []

            let return_data = await getLocations(String(loc_obj.id))

            if (return_data.length == 0) {
                showSearch.value = true
                showProduct.value = false
                lot_key.value = ''
            }

            loading.value = false

        }

        const setGridLentgh = () => {
            if (location_list.value.length < 7) {
                return "grid grid-cols-2 gap-3"
            } else {
                return "grid grid-cols-3 gap-2"
            }
        }
        
        const setMessageClasses = (level) => {
            if (level === 'danger') {
                return 'm-2 px-3 py-4 flex items-center justify-center rounded-md shadow-md bg-red-600 text-red-50 font-semibold'
            }

            if (level === 'success') {
                return 'm-2 px-3 py-4 flex items-center justify-center rounded-md shadow-md bg-green-600 text-green-50 font-semibold'
            }

            // level info or else
            return 'm-2 px-3 py-4 flex items-center justify-center rounded-md shadow-md bg-blue-100 text-blue-800 font-semibold'
        }


        return {
            goBack,
            loadingMsg,
            loading,
            message,
            infomessage,
            setMessageClasses,
            setGridLentgh,
            setLevel,
            isOffline,
            location_list,
            changeLoc,
            breadcrumb,            
            filteredLots,
            showSearch,
            showProduct,
            lot_key,
            lot_details,
            current_nav,
            setReturn,
            successMsg,
            loc_title,
            transloading,
            lot_details_stock_exist,
            lot_details_product_stock_exist
        }

    },
}
</script>