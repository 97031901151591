<template>
    
    <div class="relative min-h-screen md:flex">
      
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />

      
      <div class="flex-1 px-4 py-10">
        <div class="flex">
            <div v-if="check || save" class="mb-2">
                <span class="bg-pink-200 text-pink-800 rounded-md shadow-md text-center px-4 py-2 cursor-pointer" @click="reselectOptions()">Reselect Option</span>
            </div>
            <div v-if="barcode_id" class="mb-2 ml-2">
                <span class="bg-pink-200 text-pink-800 rounded-md shadow-md text-center px-4 py-2 cursor-pointer" @click="resetBarcode()">Reset Barcode</span>
            </div>
        </div>

        <!-- <div v-if="barcode_id" class="mt-6">
            <span class="w-full bg-pink-300 text-pink-800 rounded-md shadow-md text-center px-4 py-2">Selected Barcode : {{barcode_id}}</span>
        </div> -->

        <BarcodeScanSave @barcode:check="setCheck" @barcode:save="setSave" v-if="!check && !save"/>
        <BarcodeScan v-if="(check || save) && !barcode_id" @barcode:scanned="setBarcodeId"/>
        <BarcodeShow v-if="check && !save && barcode_id" :barcode_id="barcode_id"/>
        <BarcodeSave v-if="save && !check && barcode_id" :barcode_id="barcode_id"/>
      </div>

    </div>

</template>

<script>
import Navbar from '../components/Navbar.vue'
import BarcodeScanSave from '../components/BarcodeScanSave.vue'
import BarcodeScan from '../components/BarcodeScan.vue'
import BarcodeShow from '../components/BarcodeShow.vue'
import BarcodeSave from '../components/BarcodeSave.vue'

export default {
    name: 'BarcodeManagement',
    components: {
        Navbar,
        BarcodeScanSave,
        BarcodeScan,
        BarcodeShow,
        BarcodeSave,
    },
    data() {
        return {
            goBack: false,
            check: false,
            save: false,
            barcode_id: '',
        }
    },
    methods: {
        resetBarcode() {
            this.barcode_id = ''
        },
        setBarcodeId(barcode_id) {
            this.barcode_id = barcode_id
        },
        reselectOptions() {
            this.check = false
            this.save = false
            this.barcode_id = ''
        },
        setCheck(data) {
            this.check = true
            this.save = false
        },
        setSave(data) {
            this.check = false
            this.save = true
        },
        selectLocationId(location_id) {
            this.location_id = location_id
        },
        selectLocationName(location_name) {
            this.location_name = location_name
        },
        resetLocationId() {
            this.location_id = ''
            this.location_name = ''
        },
        resetAll() {
            this.location_id = ''
            this.location_name = ''
        }
    },
    created() {
        if (!localStorage.hasOwnProperty('api_token')) {
            this.$router.push({ name: 'Login' })
        }

        if ((window.history.length - this.$root.historyCount) > 0) {
          this.goBack = true
        }
    },
    mounted() {
        document.title = 'Inventory Count App - Barcode Management'
    }
}
</script>