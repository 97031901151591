<template>
    <div v-if="location_ids">
        <div class="text-center text-pink-800">
            Please select your Inventory Location
        </div>
        <div class="flex items-center justify-center mt-4">
            <div class="grid grid-cols-1 gap-2">
                <button v-for="location_id in location_ids" :key="location_id.id" @click="selectLocationId(location_id.id, location_id.name)">
                    <div  class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        
                        {{location_id.name}}
                    
                    </div>
                </button>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SetLocationId',
    data() {
        return {
            location_ids: [],
        }
    },
    methods: {
        async fetchLocations() {

          let url = process.env.VUE_APP_BACKEND_URL
            
          const res = await fetch(url + 'api/v2/locations/list', {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
                        
          const data = await res.json()
          return data
          
        },
        selectLocationId(location_id, location_name) {
            
            this.$emit('locationname:selected', location_name)
            this.$emit('locationid:selected', location_id)
            
        }
    },
    async created() {
        if (!localStorage.hasOwnProperty('api_token')) {
            this.$router.push({ name: 'Login' })
        }
        window.scrollTo(0, 0);
        const data = await this.fetchLocations()
        if (data.success) {
            this.location_ids = data.location_list
        } else {
            localStorage.removeItem("api_token");
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>