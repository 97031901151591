<template>
    <div>

        <div v-if="!code_selected">

            <label for="product_code_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-2 rounded-sm shadow-sm mt-4 mb-1">Enter Product Code or Name</label>
            <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                <input type="text" name="product_code_name" id="product_code_name" autocomplete="off" @keyup.enter="fetchProductStock" @input="fetchProducts" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" v-model="product_code" />
            </div>

            <div v-if="filteredProducts && !fetchProductLoading">
                <ul class="text-center text-pink-600 font-bold mt-2">
                    <li v-for="filteredProduct in filteredProducts" :key="filteredProduct._source.product_code" @click="setProduct(filteredProduct._source.product_code, filteredProduct._source.product_name)" class="border px-2 py-2 bg-pink-200 rounded shadow-md mt-1">
                        <div>{{filteredProduct._source.product_name}}</div>
                        <div class="text-xs">SKU {{filteredProduct._source.product_code}}</div>
                    </li>
                </ul>
            </div>

            <div v-if="fetchProductLoading" class="flex items-center justify-center py-3 px-6 mt-4 font-bold text-pink-700">
                <div>
                    <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                <div class="px-4">
                    Loading products ...
                </div>
            </div>

        </div>

        <div v-if="code_selected && product_code && !loading && !posted">
            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Barcode</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{barcode_id}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Product Name</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{product_name}}</span>
                </div>
            </div>

            <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                    <span class="">Product Code</span>
                </div>

                <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                    <span>{{product_code}}</span>
                </div>
            </div>

            <button class="w-full text-center bg-pink-600 mt-4 rounded-md shadow-md px-4 py-2 text-pink-100 font-bold" @click="submitBarcode()">
                Submit
            </button>
        </div>

        <div v-if="loading" class="text-pink-700 text-center py-6 flex justify-center items-center">
            <div>
                <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            <div class="px-4">
                Your request is submitting
            </div>
        </div>

        <div v-if="posted">
            <div class="text-pink-700 text-center py-6 px-4">
                Your request for barcode setting is now submitted.
            </div>
            <!-- <button class="w-full bg-pink-600 text-pink-100 font-bold px-4 py-2 mt-2 rounded-md shadow-md" @click="resetAll()">
                Go Back
            </button> -->
        </div>

    </div>
</template>

<script>
export default {
    name: 'BarcodeSave',
    props: ['barcode_id'],
    data() {
        return {
            product_code: '',
            product_name: '',
            fetchProductLoading: false,
            filteredProducts: [],
            code_selected: false,
            loading: false,
            posted: false,
            error: false,
            message: '',
        }
    },
    methods: {
        resetAll() {

        },
        async fetchProducts() {

            if (this.product_code.length != 0) {
                this.fetchProductLoading = true
                let url = process.env.VUE_APP_BACKEND_URL
                const res = await fetch(url + 'api/v2/product/'+this.product_code+'/search', {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
                const data = await res.json()
                if (data.success) {
                    this.filteredProducts = data.product_list
                } else {
                    if (data.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        this.$router.push({ name: 'Login' })
                    }
                }
                this.fetchProductLoading = false
            }
        },

        async submitBarcode() {
            this.loading = true
            const data = {
                        "params": {
                            "product_code": this.product_code,
                            "barcode_id": this.barcode_id
                        }
                    }
            try {
                let url = process.env.VUE_APP_BACKEND_URL

                const res = await fetch(url + "api/v2/product/barcode", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': '*',
                        'api_token': localStorage.getItem('api_token'),
                    },
                    body: JSON.stringify(data),
                    redirect: 'follow',
                });
                const result = await res.json()
                const r = JSON.parse(result.result)
                this.loading = false

                if (r.success) {
                    this.posted = true
                    window.scrollTo(0, 0);
                } else {
                    if (r.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        this.$router.push({ name: 'Login' })
                    }
                    this.error = true
                    this.message = r.message
                }

            } catch(error) {
                console.log(error)
            }
        },

        setProduct(code, name) {
            this.product_code = code
            this.product_name = name
            this.code_selected = true
        },
    }
}
</script>