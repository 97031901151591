import { ref } from 'vue'

const getProductMovementJfp = (loc_id, date_span, sale_days_diff) => {

    const jfp_result = ref([])
    const jfp_error = ref(null)

    let url = process.env.VUE_APP_STORE_URL

    const jfp_load = async () => {
        try {

            const data = {
                "params": {
                    "date_span": date_span,
                    "sale_days_diff": sale_days_diff
                }
            }

            let res = await fetch(url + 'api/v2/movements/' + loc_id, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'api_token': localStorage.getItem('api_token'),
                                        'Origin': '*'
                                    },
                                    body: JSON.stringify(data),
                                    redirect: 'follow'
                                })

            let return_result = await res.json()
            const r = return_result.result

            if (r.success) {

                jfp_result.value = r

            } else {

                jfp_error.value = r.message

            }
        }
        catch (err) {
            jfp_error.value = err.message
        }
    }

    return {
        jfp_result, jfp_error, jfp_load
    }

}

export default getProductMovementJfp