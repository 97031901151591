<template>
    <div>
        <!-- <div class="mt-4 mb-4 text-pink-800">
            Scanned Barcode : {{barcode_id || 'Not Yet'}}
        </div> -->
        <StreamBarcodeReader
            @decode="(a, b, c) => onDecode(a, b, c)"
            @loaded="() => onLoaded()" 
            v-if="!barcode_id"
            class="mt-4"
        />
    </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
    name: 'BarcodeScan',
    components: {
        StreamBarcodeReader,
    },
    data() {
        return {
            barcode_id: "",
            id: null,
        };
    },
    methods: {
        onDecode(a, b, c) {
            this.barcode_id = a
            this.$emit("barcode:scanned", this.barcode_id)
            alert("Scanned barcode " + this.barcode_id)
        },
        onLoaded() {
            console.log("load");
            this.barcode_id = ''
        },
        
    },
    created() {
        window.scrollTo(0, 0);
    }

}
</script>