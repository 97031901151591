<template>

    <div class="relative min-h-screen md:flex">
      <!-- nav bar -->
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />
      <!-- content -->
      <div class="flex-1 px-4 py-10">
        <main v-if="!loading">
            <div class="text-center text-pink-800">
                You have total {{ total_racks }} racks for lots.
            </div>
            <div class="flex items-center justify-center mt-4">
                <div class="grid grid-cols-3 gap-4">
                    <router-link v-for="lot_rack in lot_racks" :key="lot_rack.id" :to="{ name: 'LotRack', params: {rackid:lot_rack.name},  query: {location_id: location_id}}">
                        <div  class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                            
                            {{lot_rack.name}}
                        
                        </div>
                    </router-link>
                    
                </div>
            </div>
        </main>

            <main v-else>
                <div class="text-center text-pink-700 animate-bounce">
                    ... loading lot racks, please wait ...
                </div>
            </main>
      </div>
    </div>

</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
  name: 'Lot',
  components: {
    Navbar,
  },
  props: ['location_id'],
  data() {
      return {
          lot_racks: [],
          total_racks: 0,
          loading: true,
          goBack: true,
      }
  },
  methods: {
      async fetchLotData() {
          if(this.location_id.length > 0) {
            let url = ''

            if (this.location_id == '13') {
                url = process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_STORE_URL
            }
            
            const res = await fetch(url + 'api/v2/racklots/'+this.location_id, {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
            const data = await res.json()
            return data
          }
          
      }
  },
  async created() {
      if (!localStorage.hasOwnProperty('api_token')) {
        this.$router.push({ name: 'Login' })
      }
      const data = await this.fetchLotData()
      if(data.success) {
          this.lot_racks = data.rack_lots
          this.loading = false
          this.total_racks = data.rack_lots.length
      } else {
          this.total_racks = 0
          this.loading = false
          localStorage.removeItem("api_token");
          this.$router.push({ name: 'Login' })
      }

      if ((window.history.length - this.$root.historyCount) > 0) {
          this.goBack = true
      }
  },
  mounted() {
    document.title = 'Inventory Count App - Lot'
  }
}
</script>