<template>

    <div class="relative min-h-screen md:flex">
      <!-- nav bar -->
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />
      <!-- content -->
      <main v-if="error">
        <div class="text-center text-pink-700">
                {{message}}
        </div>

    </main>
    <main v-else class="flex-1 px-4 py-10">
        <div class="text-center text-pink-800">
            Please select Lot or Product
        </div>
        <div class="flex items-center justify-center mt-4">
            <div class="grid grid-cols-1">
                <div v-for="item in items" :key="item.id">
                    <router-link v-if="item.name == 'Lot'" :to="{ name: 'Lot', query: { location_id: location_id } }" >
                        <div  class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        
                            {{item.name}}
                    
                        </div>
                    </router-link>

                    <router-link v-if="item.name == 'Product'" :to="{ name: 'Product', query: { location_id: location_id } }" >
                        <div  class="mt-3 flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        
                            {{item.name}}
                    
                        </div>
                    </router-link>

                </div>
                
            </div>
        </div>
    </main>
    </div>

    
</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
  name: 'SelectLotProduct',
  components: {
    Navbar
  },
  props: ['location_id'],
  data() {
      return {
          goBack: false,
          error: false,
          message: '',
          items: [
              {
                'id': 1,
                'name': 'Lot'
              },
              {
                  'id': 2,
                  'name': 'Product'
              }
          ]
      }
  },
  methods: {

  },
  async created() {
      if (!localStorage.hasOwnProperty('api_token')) {
        this.$router.push({ name: 'Login' })
      }

    //   this.error = false

    //   if (this.location_id != 20) {
    //       this.error = true
    //       this.message = "Please select WH/Stock for today's Inventory"
    //   } else {
    //       this.error = false
    //   }

      if ((window.history.length - this.$root.historyCount) > 0) {
          this.goBack = true
      }
  },
  mounted() {
    document.title = 'Inventory Count App - Lot | Product'
  }
}
</script>