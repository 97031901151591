<template>
    <div v-if="is_product_details_exist">

        <label for="product_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mb-1">Product Name</label>
        <div class=" border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_name" id="product_name" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="product_details.name" disabled="1" />
        </div>

        <label for="product_code" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Product Code</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_code" id="product_code" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="product_details.product_code" disabled="1" />
        </div>

        <label for="product_code" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-1 mt-4 mb-1">Product Stock</label>
        <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
            <input type="text" name="product_code" id="product_code" class="block w-full border-0 bg-white p-0 text-center text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" :value="product_details.stock" disabled="1" />
        </div>

    </div>
    
</template>

<script>
import { computed, ref, watch } from 'vue'

export default {
    name: 'InventoryCountFormProduct',
    props: ["product_details_data"],
    setup(props, context) {

        const product_details = computed(() => props.product_details_data)

        const is_product_details_exist = computed(() => {
            if (Object.keys(product_details).length > 0) {
                return true
            } else {
                return false
            }
        })

        return {
            product_details,
            is_product_details_exist
        }

    }
}
</script>