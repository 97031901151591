<template>
    <div v-if="total_lots">

        <div class="flex items-center justify-between ml-1">
            <div class="flex items-center justify-start">
                <div class="bg-pink-200 px-6 py-2 rounded-md shadow-md">
                    <select id="month_id" name="month_id" class="focus:outline-none sm:text-sm bg-pink-200 w-full text-pink-700" v-model="selected_month_span" @change="setMonth(selected_month_span)">
                        <option disabled value="" class="bg-pink-200">Select Month</option>
                        <option v-for="(month_span, index) in month_list" :key="index">{{month_span}}</option>
                    </select>
                </div>
            </div>

            <div class="flex items-center justify-end">
                <BrandDropDown :title="brand" :dropdown_lists="brands" class="mr-2" @brand:select="setBrand" @brand:remove="removeBrand" :removed_brand="parent_removed_brand"/>
                <VendorDropDown :title="vendor" :dropdown_lists="vendors" class="mr-2" @vendor:select="setVendor" @vendor:remove="removeVendor" :removed_vendor="removed_vendor" />
                <div class="px-4 py-2 bg-pink-700 rounded-md shadow-md text-pink-50 font-bold cursor-pointer flex items-center justify-center" @click="refresh">
                    Clear All
                </div>

                <div class="ml-2 px-4 py-2 bg-pink-700 rounded-md shadow-md text-pink-50 font-bold cursor-pointer flex items-center justify-center" @click="downloadReport">
                    <div class="px-2" v-if="downloadLoading">
                        <svg class="animate-spin h-5 w-5 text-pink-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    <div>
                        <span v-if="downloadLoading">Downloading</span>
                        <span v-if="!downloadLoading">Download</span>
                    </div>
                </div>
            </div>

            
        </div>
        

        <div class="flex flex-wrap items-center justify-start mt-2">
            <!-- months -->
            <div class="flex items-center justify-center bg-gray-300 px-4 py-1 mt-1 ml-1 mb-2 text-gray-700 rounded-full shadow-md text-sm">
                <div class="mr-1">
                    {{selected_month_span}} Months
                </div>
                <div class="cursor-pointer" @click="removeMonthFilter(selected_month_span)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </div>
            </div>
            

            <!-- vendors -->
            <div v-if="vendor_selected" class="flex flex-wrap items-center justify-start">
                <div v-for="(vendor_select, vendor_index) in vendor_selected" :key="vendor_index" class="flex items-center justify-center ml-1 mt-1 mb-1 bg-gray-300 px-4 py-1 text-gray-700 rounded-full shadow-md text-sm">
                    <div class="mr-1">
                        {{vendor_select}}
                    </div>
                    <div class="cursor-pointer" @click="removeVendor(vendor_select)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>

            <!-- brands -->
            <div v-if="brand_selected" class="flex flex-wrap items-center justify-start">
                <div v-for="(brand_select, brand_index) in brand_selected" :key="brand_index" class="flex items-center justify-center ml-1 mt-1 mb-1 bg-gray-300 px-4 py-1 text-gray-700 rounded-full shadow-md text-sm">
                    <div class="mr-1">
                        {{brand_select}}
                    </div>
                    <div class="cursor-pointer" @click="removeBrand(brand_select)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>
            
            
        </div>

        <div v-if="!loading" class="ml-1">
            <div class="bg-pink-200 text-pink-700 rounded-md shadow-sm px-4 py-2 mt-4 text-center">
                You have total <strong>{{total_lots}}</strong> lots expiring within <strong>{{selected_month}}</strong> month(s).
            </div>

            <div class="grid grid-cols-3 gap-4 mt-4">
                <div v-for="lot_id in lot_ids" :key="lot_id._source.lot_id">
                    <div class="bg-pink-200 h-32 text-center rounded-md shadow-lg cursor-pointer" @click="goToProduct(lot_id._source.product_code)">
                        <div class="text-pink-600 h-2/6  pt-2 px-4 text-sm truncate">
                            {{lot_id._source.brand}} / <span class="font-bold">{{lot_id._source.product_name}}</span>
                        </div>

                        <div class="text-pink-600 h-1/6 text-sm text-center truncate">
                            {{lot_id._source.vendor}}
                        </div>

                        <div class="text-pink-600 h-1/6 px-4 text-sm flex justify-between items-center">
                            <div class="font-bold">
                                ({{lot_id._source.product_code}})
                            </div>

                            <div class="font-bold">
                                {{lot_id._source.lot_name}}
                            </div>
                        </div>
                        
                        <div class="flex justify-between items-center px-4 font-bold text-pink-200 h-2/6 border-t border-pink-700 bg-pink-800 rounded-b-md">
                            <div>
                                {{lot_id._source.removal_date}}
                            </div>
                            <div v-if="location_id == '13'">
                                {{lot_id._source.wh_stock}} Pcs / {{lot_id._source.wh_stock * lot_id._source.cost}} Tk
                            </div>

                            <div v-if="location_id == '20'">
                                {{lot_id._source.jfp_stock}} Pcs / {{lot_id._source.jfp_stock * lot_id._source.cost}} Tk
                            </div>

                            <div v-if="location_id == '26'">
                                {{lot_id._source.ssq_stock}} Pcs / {{lot_id._source.ssq_stock * lot_id._source.cost}} Tk
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    </div>

    <div v-if="!total_lots && !loading" class="bg-pink-200 text-pink-700 rounded-md shadow-sm px-4 py-2 mt-4 text-center font-bold">
        You do not have any lot expiring within the given months
    </div>

    <div v-if="loading">
        <div v-if="loading" class="text-pink-700 text-center py-6 flex justify-center items-center">
            <div>
                <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            <div class="px-4">
                Loading the products ...
            </div>
        </div>
    </div>
</template>

<script>
import BrandDropDown from './BrandDropDown.vue'
import VendorDropDown from './VendorDropDown.vue'

export default {
    name: 'ExpiryContent',
    components: {
        BrandDropDown,
        VendorDropDown,
    },
    props: ['month', 'location_id'],
    data() {
        return {
            loading: false,
            lot_ids: [],
            total_lots: 0,
            selected_month: '',
            selected_month_span: '',
            month_list: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            downloadLoading: false,
            brands: [],
            brand: "Brand",
            vendor: "Vendor",
            vendors: [],
            vendor_selected: [],
            brand_selected: [],
            backup_lot_ids: [],
            parent_removed_brand: '',
            removed_vendor: '',
        }
    },
    methods: {
        async refresh() {
            this.vendor_selected.forEach( (item) => {
                setTimeout( () => {
                    this.removed_vendor = ''
                    setTimeout( () => {
                        this.removed_vendor = item
                    }, 5)
                }, 5)
            })

            this.brand_selected.forEach( (item) => {
                setTimeout( () => {
                    this.parent_removed_brand = ''
                    setTimeout( () => {
                        this.parent_removed_brand = item
                    }, 5)
                }, 5)
            })

            this.vendor_selected = []
            this.brand_selected = []
        },
        setBrand(brand) {
            if (!this.brand_selected.includes(brand)) {
                this.brand_selected.push(brand)
            }
        },
        removeBrand(brand) {
            if (this.parent_removed_brand == brand) {
                setTimeout(() => {
                    this.parent_removed_brand = ''
                    setTimeout(() => {
                        this.parent_removed_brand = brand
                    }, 5)
                }, 5)
            } else {
                this.parent_removed_brand = brand
            }
            // this.parent_removed_brand = brand
            this.brand_selected = this.brand_selected.filter(item => item !== brand)
        },
        removeVendor(vendor) {
            if (this.removed_vendor == vendor) {
                setTimeout( () => {
                    this.removed_vendor = ''
                    setTimeout(() => {
                        this.removed_vendor = vendor
                    }, 5)
                }, 5)
            } else {
                this.removed_vendor = vendor
            }
            this.vendor_selected = this.vendor_selected.filter(item => item !== vendor)
        },
        setVendor(vendor) {
            if (!this.vendor_selected.includes(vendor)) {
                this.vendor_selected.push(vendor)
            }
        },
        removeMonthFilter(month) {
            this.selected_month_span = '1'
            this.selected_month = '1'
        },
        async fetchLotExpiry(month) {
            this.loading = true
            let url = process.env.VUE_APP_BACKEND_URL
            const res = await fetch(url + 'api/v2/lot/expiry/'+month+'/search/'+this.location_id, {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
            const data = await res.json()
            this.loading = false
            return data
        },

        setMonth(month) {
            this.selected_month = month
        },

        goToProduct(code) {
            this.$router.push({ name: 'ExpiryStock', query: { location: this.location_id, code: code } })
        },

        downloadReport() {

            this.downloadLoading = true
            setTimeout(() => {
                // define file
                let csvContent = "data:text/csv;charset=utf-8,";

                console.log(this.location_id)
                
                // add header
                if (this.location_id == '13') {
                    let row = "Product Name, SKU, Lot, Vendor, Removal Date (M/D/Y), WH Stock, Unit Cost, Value"
                    csvContent += row + "\r\n"
                }

                if (this.location_id == '20') {
                    let row = "Product Name, SKU, Lot, Vendor, Removal Date (M/D/Y), JFP Stock, Unit Cost, Value"
                    csvContent += row + "\r\n"
                }

                if (this.location_id == '26') {
                    let row = "Product Name, SKU, Lot, Vendor, Removal Date (M/D/Y), SSQ Stock, Unit Cost, Value"
                    csvContent += row + "\r\n"
                }
                
                

                // add contents
                this.lot_ids.forEach( (lot_id) => {

                    if (this.location_id == '13') {
                        let row = lot_id._source.product_name + "," + lot_id._source.product_code + "," + lot_id._source.lot_name + "," + lot_id._source.vendor + "," + lot_id._source.removal_date + "," + lot_id._source.wh_stock + "," + lot_id._source.cost + "," + (lot_id._source.cost * lot_id._source.wh_stock)
                        csvContent += row + "\r\n"
                    }

                    if (this.location_id == '20') {
                        let row = lot_id._source.product_name + "," + lot_id._source.product_code + "," + lot_id._source.lot_name + "," + lot_id._source.vendor + "," + lot_id._source.removal_date + "," + lot_id._source.jfp_stock + "," + lot_id._source.cost + "," + (lot_id._source.cost * lot_id._source.jfp_stock)
                        csvContent += row + "\r\n"
                    }

                    if (this.location_id == '26') {
                        let row = lot_id._source.product_name + "," + lot_id._source.product_code + "," + lot_id._source.lot_name + "," + lot_id._source.vendor + "," + lot_id._source.removal_date + "," + lot_id._source.ssq_stock + "," + lot_id._source.cost + "," + (lot_id._source.cost * lot_id._source.ssq_stock)
                        csvContent += row + "\r\n"
                    }
                })

                // download
                var encodedUri = encodeURI(csvContent)
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "expiry_products.csv");
                link.click()
                this.downloadLoading = false
            }, 2000)
        }
    },

    watch: {
        brand_selected: {
            deep: true,
            handler() {

                if (this.brand_selected.length === 0 && this.vendor_selected.length === 0) {
                    this.lot_ids = this.backup_lot_ids
                    this.total_lots = this.lot_ids.length
                } else {
                    if (this.backup_lot_ids.length === 0 && this.lot_ids.length !== 0) {
                        this.backup_lot_ids = this.lot_ids
                    }

                    let new_lot_ids = []

                    this.backup_lot_ids.forEach( (item) => {
                        if (this.vendor_selected.includes(item._source.vendor)) {
                            new_lot_ids.push(item)
                        } else if (this.brand_selected.includes(item._source.brand)) {
                            new_lot_ids.push(item)
                        }
                    })

                    this.lot_ids = new_lot_ids
                    this.total_lots = this.lot_ids.length
                }
                
                

            }
        },
        vendor_selected: {
            deep: true,
            handler() {
                if (this.brand_selected.length === 0 && this.vendor_selected.length === 0) {
                    this.lot_ids = this.backup_lot_ids
                    this.total_lots = this.lot_ids.length
                } else {
                    if (this.backup_lot_ids.length === 0 && this.lot_ids.length !== 0) {
                        this.backup_lot_ids = this.lot_ids
                    }

                    let new_lot_ids = []

                    this.backup_lot_ids.forEach( (item) => {
                        if (this.vendor_selected.includes(item._source.vendor)) {
                            new_lot_ids.push(item)
                        } else if (this.brand_selected.includes(item._source.brand)) {
                            new_lot_ids.push(item)
                        }
                    })

                    this.lot_ids = new_lot_ids
                    this.total_lots = this.lot_ids.length
                }
            }
        },
        async selected_month(newVal, oldVal) {
            if (this.selected_month) {
                const data = await this.fetchLotExpiry(this.selected_month)
                console.log(data)
                if (data.success) {
                    this.lot_ids = data.lot_ids
                    this.backup_lot_ids = data.lot_ids
                    this.total_lots = this.lot_ids.length
                    this.brands = data.aggs.brand.buckets
                    this.vendors = data.aggs.vendors.buckets
                } else {
                    if (data.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        this.$router.push({ name: 'Login' })
                    }
                }
            }
        },
        month(newVal, oldVal) {
            this.selected_month = this.month
        }
    },

    async created() {

        if (!this.selected_month) {
            if (this.month) {
               this.selected_month = this.month
            } else {
                this.selected_month = '1'
                this.selected_month_span = '1'
            }
        }

        if (this.selected_month) {
            const data = await this.fetchLotExpiry(this.selected_month)
            if (data.success) {
                this.lot_ids = data.lot_ids
                this.backup_lot_ids = data.lot_ids
                this.total_lots = this.lot_ids.length
                this.brands = data.aggs.brand.buckets
                this.vendors = data.aggs.vendors.buckets
            } else {
                if (data.message == 'API Token Invalid') {
                    localStorage.removeItem("api_token");
                    this.$router.push({ name: 'Login' })
                }
            }
        }
    }
}
</script>