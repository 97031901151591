<template>
    
    <div class="relative min-h-screen md:flex">
      
      <Navbar showBack="true" v-if="goBack" />
      <Navbar showBack="false" v-else />

      
      <div class="flex-1 px-4 py-10">
        
        <!-- input product code starts -->
        <div>
            <label for="product_code_name" class="inline-block px-10 bg-pink-100 text-xs font-medium text-pink-800 w-full text-center py-2 rounded-sm shadow-sm mt-4 mb-1">Enter Product Code or Name</label>
            <div class="border border-pink-800 rounded-md px-4 py-3 shadow-sm focus-within:ring-1 focus-within:ring-pink-600 focus-within:border-pink-900">
                <input type="text" name="product_code_name" id="product_code_name" autocomplete="off" @input="fetchProducts" class="block w-full border-0 p-0 text-center bg-white text-pink-900 placeholder-pink-700 focus:ring-0 sm:text-sm focus:outline-none font-bold" v-model="product_code" />
            </div>

            <div v-if="filteredProducts && !fetchProductLoading && !product_selected">
                <ul class="text-center text-pink-600 font-bold mt-2">
                    <li v-for="filteredProduct in filteredProducts" :key="filteredProduct._source.product_code" @click="fetchProductIfrb(filteredProduct._source.product_code, filteredProduct._source.product_name)" class="border px-2 py-2 bg-pink-200 rounded shadow-md mt-1">
                        <div>{{filteredProduct._source.product_name}}</div>
                        <div class="text-xs">SKU {{filteredProduct._source.product_code}}</div>
                    </li>
                </ul>
            </div>

            <div v-if="fetchProductLoading && !product_selected" class="flex items-center justify-center py-3 px-6 mt-4 font-bold text-pink-700">
                <div>
                    <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                <div class="px-4">
                    Loading products ...
                </div>
            </div>

            <!-- ifrb information of the product -->
            <div v-if="product_selected && !submitLoading">

                <div v-if="message" class="w-full text-center bg-green-300 mt-4 rounded-md shadow-md px-4 py-2 text-green-800 font-bold">
                    {{message}}
                </div>

                <div v-if="error" class="w-full text-center bg-red-300 mt-4 rounded-md shadow-md px-4 py-2 text-red-800 font-bold">
                    {{error}}
                </div>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">Product Name</span>
                    </div>

                    <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <span v-if="product_name">{{product_name}}</span>
                    </div>
                </div>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">SKU</span>
                    </div>

                    <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <span v-if="product_selected">{{product_selected}}</span>
                    </div>
                </div>

                <button class="w-full text-center bg-red-700 mt-4 rounded-md shadow-md px-4 py-2 text-red-50 font-bold" @click="changeAll()">
                    Change All
                </button>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">Floor</span>
                    </div>

                    <div class="w-1/3 flex items-center justify-center col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <div v-if="product_ifrb.floor_id && !floor_change">{{product_ifrb.floor_id}}</div>

                        <select v-if="floor_change" id="floor" name="floor" class="focus:outline-none sm:text-sm rounded-md bg-white w-full" v-model="floor_selected">
                            <option disabled value="">Select Option</option>
                            <option v-for="floor in floors" :key="floor.id">{{floor.name}}</option>
                        </select>

                    </div>

                    <div class="w-1/3 flex items-center justify-center col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <button class="bg-pink-600 text-pink-100 px-2 py-1 rounded-md" @click="floor_change = !floor_change">
                            <span v-if="!floor_change">Change</span>
                            <span v-if="floor_change">Back</span>
                        </button>
                    </div>
                </div>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">Rack</span>
                    </div>

                    <div class="w-1/3 flex items-center justify-center col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <div v-if="product_ifrb.rack_id && !rack_change">{{product_ifrb.rack_id}}</div>

                        <select v-if="rack_change" id="rack" name="rack" class="focus:outline-none sm:text-sm rounded-md bg-white w-full" v-model="rack_selected">
                            <option disabled value="">Select Option</option>
                            <option v-for="rack in racks" :key="rack.id">{{rack.name}}</option>
                        </select>

                    </div>

                    <div class="w-1/3 flex items-center justify-center col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <button class="bg-pink-600 text-pink-100 px-2 py-1 rounded-md" @click="rack_change = !rack_change">
                            <span v-if="!rack_change">Change</span>
                            <span v-if="rack_change">Back</span>
                        </button>
                    </div>

                </div>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">Bin</span>
                    </div>

                    <div class="w-1/3 flex items-center justify-center col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <div v-if="product_ifrb.bin_id && !bin_change">{{product_ifrb.bin_id}}</div>

                        <select v-if="bin_change" id="floor" name="floor" class="focus:outline-none sm:text-sm rounded-md bg-white w-full" v-model="bin_selected">
                            <option disabled value="">Select Option</option>
                            <option v-for="bin in bins" :key="bin.id">{{bin.name}}</option>
                        </select>
                    </div>

                    <div class="w-1/3 flex items-center justify-center col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <button class="bg-pink-600 text-pink-100 px-2 py-1 rounded-md" @click="bin_change = !bin_change">
                            <span v-if="!bin_change">Change</span>
                            <span v-if="bin_change">Back</span>
                        </button>
                    </div>
                </div>

                <button class="w-full text-center bg-pink-600 mt-4 rounded-md shadow-md px-4 py-2 text-pink-100 font-bold" @click="submitIfrb()">
                    Submit
                </button>
            </div>

            <div v-if="submitLoading" class="text-pink-700 text-center py-6 flex justify-center items-center">
                <div>
                    <svg class="animate-spin h-5 w-5 text-pink-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                <div class="px-4">
                    Your request is submitting
                </div>
            </div>

            <!-- ifrb information of the product -->

        </div>
        <!-- input product code ends -->

       
        
      </div>

    </div>

</template>

<script>
import { handleError, ref } from 'vue'
import { useRouter } from 'vue-router'
import Navbar from '../components/Navbar.vue'

export default {
    name: 'IfrbProduct',
    components: {
        Navbar
    },
    setup() {

        // refs
        const goBack = ref(false)
        const error = ref(false)
        const floors = ref([])
        const racks = ref([])
        const bins = ref([])
        const product_code = ref('')
        const fetchProductLoading = ref(false)
        const filteredProducts = ref([])
        const product_selected = ref('')
        const floor_selected = ref('')
        const rack_selected = ref('')
        const bin_selected = ref('')
        const product_name = ref('')
        const product_ifrb = ref({})
        const floor_change = ref(false)
        const rack_change = ref(false)
        const bin_change = ref(false)
        const submitLoading = ref(false)
        const message = ref('')
        const router = useRouter()

        // methods
        const getFloors = async () => {
            try {

                let res = await fetch('https://erp.shajgoj.com/api/v2/ifrb/floors', {
                                        method: "GET",
                                        headers: {
                                            'api_token': localStorage.getItem('api_token'),
                                        }
                                    })
                let data = await res.json()

                if (data.success) {

                    floors.value = data.floors

                } else {

                    error.value = data.message

                }
            }
            catch (err) {
                error.value = err.message
            }
        }

        const getRacks = async () => {
            try {

                let res = await fetch('https://erp.shajgoj.com/api/v2/ifrb/racks', {
                                        method: "GET",
                                        headers: {
                                            'api_token': localStorage.getItem('api_token'),
                                        }
                                    })
                let data = await res.json()

                if (data.success) {

                    racks.value = data.racks

                } else {

                    error.value = data.message

                }
            }
            catch (err) {
                error.value = err.message
            }
        }

        const getBins = async () => {
            try {

                let res = await fetch('https://erp.shajgoj.com/api/v2/ifrb/bins', {
                                        method: "GET",
                                        headers: {
                                            'api_token': localStorage.getItem('api_token'),
                                        }
                                    })
                let data = await res.json()

                if (data.success) {

                    bins.value = data.bins

                } else {

                    error.value = data.message

                }
            }
            catch (err) {
                error.value = err.message
            }
        }

        const fetchProducts = async () => {
            product_selected.value = ''
            product_ifrb.value = ''
            message.value = ''
            error.value = ''
            if (product_code.value.length != 0) {
                fetchProductLoading.value = true
                let url = process.env.VUE_APP_BACKEND_URL
                const res = await fetch(url + 'api/v2/ifrb/product/' + product_code.value + '/search', {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
                const data = await res.json()
                if (data.success) {
                    filteredProducts.value = data.product_list
                } else {
                    if (data.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        router.push({ name: 'Login' })
                    }
                }
                fetchProductLoading.value = false
            }
        }

        const fetchProductIfrb = async (code, name) => {
            product_selected.value = code
            product_name.value = name
            // get the current ifrb value for the product that got selected
            let url = process.env.VUE_APP_BACKEND_URL
            const res = await fetch(url + 'api/v2/ifrb/product/' + product_selected.value, {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
            const data = await res.json()
            if (data.success) {
                product_ifrb.value = data.product_ifrb
                if (!product_ifrb.value.floor_id) {
                    product_ifrb.value.floor_id = 'NA'
                }
                if (!product_ifrb.value.rack_id) {
                    product_ifrb.value.rack_id = 'NA'
                }
                if (!product_ifrb.value.bin_id) {
                    product_ifrb.value.bin_id = 'NA'
                }
                getFloors()
                getRacks()
                getBins()
            } else {
                if (data.message == 'API Token Invalid') {
                    localStorage.removeItem("api_token");
                    router.push({ name: 'Login' })
                }
            }
        }

        const submitIfrb = async() => {
            let floor_id = product_ifrb.value.floor_id
            if (floor_selected.value != '') {
                floor_id = floor_selected.value
            }

            if (!floor_id) {
                floor_id = 'NA'
            }

            let rack_id = product_ifrb.value.rack_id
            if (rack_selected.value != '') {
                rack_id = rack_selected.value
            }

            if (!rack_id) {
                rack_id = 'NA'
            }

            let bin_id = product_ifrb.value.bin_id
            if (bin_selected.value != '') {
                bin_id = bin_selected.value
            }

            if (!bin_id) {
                bin_id = 'NA'
            }


            // now we submit the request
            submitLoading.value = true
            const data = {
                        "params": {
                            "floor_id": floor_id,
                            "rack_id": rack_id,
                            "bin_id": bin_id,
                        }
                    }
            try {
                let url = process.env.VUE_APP_BACKEND_URL
                const res = await fetch(url + "api/v2/ifrb/product/" + product_selected.value, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': '*',
                        'api_token': localStorage.getItem('api_token'),
                    },
                    body: JSON.stringify(data),
                    redirect: 'follow',
                });
                const result = await res.json()
                const r = JSON.parse(result.result)
                submitLoading.value = false

                if (r.success) {
                    message.value = 'Your request is posted'
                    fetchProductIfrb(product_selected.value, product_name.value)
                    floor_selected.value = false
                    rack_selected.value = false
                    bin_selected.value = false
                    floor_change.value = false
                    rack_change.value = false
                    bin_change.value = false
                    window.scrollTo(0, 0);
                } else {
                    if (r.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        router.push({ name: 'Login' })
                    }
                    error.value = r.message
                }

            } catch(err) {
                error.value = err
                console.log(err)
            }

        }

        const changeAll = () => {
            floor_change.value = !floor_change.value
            rack_change.value = !rack_change.value
            bin_change.value = !bin_change.value
        }

        // get the floors, racks, bins at setup
        getFloors()
        getRacks()
        getBins()

        return {
            goBack,
            error,
            message,
            floors,
            racks,
            bins,
            product_code,
            fetchProductLoading,
            submitLoading,
            filteredProducts,
            product_selected,
            floor_selected,
            rack_selected,
            bin_selected,
            product_name,
            product_ifrb,
            floor_change,
            rack_change,
            bin_change,
            fetchProducts,
            fetchProductIfrb,
            submitIfrb,
            changeAll
        }
    },
}
</script>