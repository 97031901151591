import inventorycount from '../../store/inventorycount'

const getLocations = (loc_id) => {

    return new Promise(async (resolve, reject) => {
        try {

            let res = await fetch('http://192.144.82.169/api/v3/inventory/'+loc_id+'/childs', {
                                    method: "GET",
                                    headers: {
                                        'api_token': localStorage.getItem('api_token'),
                                    }
                                })

            let data = await res.json()

            if (data.success) {
    
                inventorycount.state.location_list = data.location_list
                resolve(data.location_list)

            } else {

                inventorycount.state.error = data.message
                reject(data.message)

            }
        }
        catch (err) {
            inventorycount.state.error = err.message
            reject(err.message)
        }
    })

}

export default getLocations