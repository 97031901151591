<template>
    <div class="rackparent">
        <button @click="isOpen = !isOpen" class="rackdropdown pl-2 py-2 flex items-center justify-between text-sm bg-pink-200 text-pink-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2">
            <div class="pl-2 pr-1 text-sm">
                Select Rack
            </div>
            <div v-if="racks.length" class="font-bold">({{racks.length}})</div>
            <div class="px-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
        </button>
        <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed inset-0 h-full w-full bg-black bg-opacity-50 cursor-default"></button>
        <div v-if="isOpen" class="absolute mt-2 py-2 w-48 text-sm bg-pink-200 text-pink-800 rounded-md shadow-2xl">
            <ul>
                <li v-for="rack in racks" :key="rack.key" @click="setRack(rack.key)" class="flex items-center justify-between px-4 py-2 text-sm cursor-pointer hover:bg-pink-700 hover:text-pink-100">
                    <div>
                        {{rack.key}} ({{rack.doc_count}})
                    </div>
                    <div v-if="rack_selected.includes(rack.key)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </li>

                <li @click="setRack('NA')" class="flex items-center justify-between px-4 py-2 text-sm cursor-pointer hover:bg-pink-700 hover:text-pink-100">
                    <div>
                        N/A
                    </div>
                    <div v-if="rack_selected.includes('NA')">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
export default {
    props: ['racks'],
    setup(props, context) {
        const isOpen = ref(false)
        const rack_selected = ref([])

        const setRack = (rack_name) => {
            if (!rack_selected.value.includes(rack_name)) {
                rack_selected.value.push(rack_name)
                context.emit('rack:selected', rack_selected)
            } else {
                rack_selected.value = rack_selected.value.filter(item => item !== rack_name)
                context.emit('rack:selected', rack_selected)
            }
            isOpen.value = !isOpen.value
        }

        watch(isOpen, () => {
            if (isOpen.value) {
                document.querySelector('.rackparent').classList.add("relative")
                document.querySelector('.rackdropdown').classList.add("relative", "z-10")
            } else {
                document.querySelector('.rackparent').classList.remove("relative")
                document.querySelector('.rackdropdown').classList.remove("relative", "z-10")
            }
        })

        return {
            isOpen, rack_selected, setRack
        }

    }
}
</script>

<style>

</style>