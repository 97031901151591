<template>
    <div class="relative min-h-screen md:flex">
      <!-- nav bar -->
      
        <Navbar showBack="true" v-if="goBack" />
        <Navbar showBack="false" v-else/>

      <!-- content -->
        <div class="flex-1 px-4 py-10">

            <!-- <StreamBarcodeReader
                @decode="onDecode"
                @loaded="onLoaded"
            ></StreamBarcodeReader>

            Input Value: {{ text || "Nothing" }} -->

            <div v-if="error">
                
                <button @click="error = !error; message = ''">
                    <div class="bg-pink-200 px-4 py-2 rounded-md shadow-md border border-pink-300 text-pink-700 transition delay-150 duration-300 ease-in-out hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        Go Back
                    </div>
                </button>

                <div class="bg-pink-300 px-4 py-2 mt-4 shadow-md rounded-md w-full text-center text-pink-900">
                    {{message}}
                </div>

            </div>

            <div v-if="!error && !return_location_id">
                <div v-if="!loading && location_ids">
                    <div class="text-center text-pink-800">
                        Please select your Inventory Location
                    </div>
                    <div class="flex items-center justify-center mt-4">
                        <div class="grid grid-cols-1 gap-2">
                            <button v-for="location_id in location_ids" :key="location_id.id" @click="setReturnLocationId(location_id)" >
                                <div class="flex items-center justify-center bg-pink-200 px-8 py-4 rounded-lg shadow-sm border-2 border-pink-100 font-bold text-pink-700 hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                                    
                                    {{location_id.name}}
                                
                                </div>
                            </button>
                            
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="text-center text-pink-700 animate-bounce font-bold">
                            ... loading locations, please wait ...
                    </div>
                </div>
            </div>

            <div v-if="!error && return_location_id && !selected_return_lot && !lotLoading">
                
                <button @click="resetReturnLocationId()">
                    <div class="bg-pink-200 px-4 py-2 rounded-md shadow-md border border-pink-300 text-pink-700 transition delay-150 duration-300 ease-in-out hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        Change Location
                    </div>
                </button>

                <span class="font-bold text-pink-700 px-4">({{return_location_name}})</span>

                <div class="grid grid-cols-6 md:grid-cols-4 mt-4 text-gray-400 rounded-lg border border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="col-span-2 md:col-span-1 px-4 flex items-center justify-start border-0 focus:outline-none">
                        <span class="">Lot</span>
                    </div>

                    <div class="col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none">
                        <input class="w-full h-full focus:outline-none text-center text-pink-700" name="lot_id" type="text" autocomplete="off" v-model="lot_id" @keyup.enter="setReturnLotInfo(lot_id)" />
                    </div>

                    
                    <div class="flex items-center justify-end px-4 col-span-2 md:col-span-1 border-0 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                </div>

                <div v-if="lot_list">
                    <ul class="text-center text-pink-600 font-bold mt-2">
                        <li v-for="filteredLot in lot_list" :key="filteredLot._source.lot_id" @click="setReturnLotInfo(filteredLot._source.lot_name)" class="border py-2 bg-pink-200 rounded shadow-md mt-1">
                            <div>{{filteredLot._source.lot_name}}</div>
                            <div class="text-xs">SKU {{filteredLot._source.product_code}}</div>
                        </li>
                    </ul>
                </div>

                
                
            </div>

            <div v-if="lotLoading">
                <div class="text-center text-pink-700 animate-bounce font-bold">
                        ... loading lot details, please wait ...
                </div>
            </div>

            <div v-if="submitLoading">
                <div class="text-center text-pink-700 animate-bounce font-bold">
                        ... submitted your request, please wait ...
                </div>
            </div>

            <div v-if="!error && return_location_id && selected_return_lot && !posted && !submitLoading">

                <button @click="resetSelectedReturnLot()">
                    <div class="bg-pink-200 px-4 py-2 rounded-md shadow-md border border-pink-300 text-pink-700 transition delay-150 duration-300 ease-in-out hover:bg-pink-300 hover:text-pink-800 hover:shadow-md hover:border-2 hover:border-pink-700 focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        Change Lot
                    </div>
                </button>

                <span class="font-bold px-4 py-2 text-pink-700">({{selected_return_lot}})</span>

                
                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">Lot</span>
                    </div>

                    <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <span>{{selected_return_lot}}</span>
                    </div>
                </div>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">Product Code</span>
                    </div>

                    <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <span>{{product_code}}</span>
                    </div>
                </div>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="w-full">Product Name</span>
                    </div>

                    <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <span>{{product_name}}</span>
                    </div>
                </div>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">Return Qty</span>
                    </div>

                    <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <input type="text" v-model="return_qty" class="text-center focus:outline-none w-full">
                    </div>
                </div>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">Previous Rack</span>
                    </div>

                    <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300">
                        <span>{{previous_rack_id}}</span>
                    </div>
                </div>

                <div class="flex mt-4 py-2 rounded-lg border text-pink-700 border-pink-600 focus-within:text-pink-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-pink-500">
                    <div class="w-1/3 px-4 flex items-center justify-center border-0 focus:outline-none">
                        <span class="">Return Rack</span>
                    </div>

                    <div class="w-2/3 col-span-2 py-2 px-2 text-center text-pink-700 font-bold focus:outline-none border-l border-pink-300 ">
                        
                        <select id="rack" name="rack" class="focus:outline-none sm:text-sm rounded-md bg-white w-full" v-model="return_rack">
                            <option disabled value="">Select Rack</option>
                            <option v-for="rack_lot in rack_lots" :key="rack_lot.id">{{rack_lot.name}}</option>
                        </select>

                    </div>
                </div>

                <button class="w-full" @click="submitReturnLot()">
                    <div class="bg-pink-600 mt-4 px-4 py-2 text-center rounded-md shadow-md border border-pink-300 text-pink-200 transition delay-150 duration-300 ease-in-out hover:bg-pink-500 hover:text-pink-50 hover:shadow-md hover:border-2 hover:border-pink-700 focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        Submit
                    </div>
                </button>
            </div>

            <div v-if="!error && return_location_id && selected_return_lot && posted && !submitLoading">
                <div class="text-center text-pink-700 font-bold">
                        Return request is now posted.
                </div>
                
                <button class="mt-2 w-full" @click="goBackResetLot()">
                    <div class="text-center px-4 py-4 bg-pink-300 text-pink-800 rounded-md shadow-md">
                        Go Back
                    </div>
                </button>
                
            </div>
        
        </div> 
    </div>

  
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Navbar from '../components/Navbar.vue'
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: 'ReturnInventory',
  components: {
    Navbar,
    StreamBarcodeReader,
  },
  data() {
      return {
          loading: false,
          location_ids: [],
          goBack: false,
          lot_id: '',
          lot_list: [],
          return_qty: 0,
          previous_rack_id: '',
          product_code: '',
          product_name: '',
          rack_lots: [],
          selected_rack: '',
          return_rack: '',
          error: false,
          message: '',
          lotLoading: false,
          text: '',
          submitLoading: false,
          posted: false,
      }
  },
  computed: {
      ...mapState(['return_location_id', 'return_location_name', 'selected_return_lot'])
  },
  watch: {
      async lot_id(newVal, oldVal) {
            let url = ''
            if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_BACKEND_URL
            }

            if (newVal && typeof newVal !== 'undefined' && newVal !== null) {

                
                const res = await fetch(url + 'api/v2/lot/'+newVal+'/search', {
                                        method: "GET",
                                        headers: {
                                            'api_token': localStorage.getItem('api_token'),
                                        }
                                    })
                const data = await res.json()

                if (data.success) {

                    if (data.lot_list) {
                        this.lot_list = data.lot_list
                    }

                }
            } else {
                this.lot_list = []
            } 
      },
      return_rack(newVal) {
          console.log(newVal)
      }
  },
  methods: {

      ...mapMutations(["setReturnLocationId", "resetReturnLocationId", "setSelectedReturnLot", "resetSelectedReturnLot"]),

      async setReturnLotInfo(lot_name) {
          this.lotLoading = true

          // now we need to get previous rack for this lot
          const data = await this.fetchRackInfo(lot_name)
          if (data.success) {
              this.previous_rack_id = data.rack_ids
              this.product_code = data.product_code
              this.product_name = data.product_name
              // only if we are able to see the lot is right, without any error, we use the lot view
              this.setSelectedReturnLot(lot_name)
              this.lotLoading = false
          } else {
              this.error = true
              this.message = data.message
              this.resetSelectedReturnLot()
              this.lotLoading = false
          }

          const rack_data = await this.fetchLotRacks(this.return_location_id)

          if (data.success) {
              this.rack_lots = rack_data.rack_lots
          } else {
              this.error = true
              this.message = data.message
              this.resetSelectedReturnLot()
          }
      },

       goBackResetLot() {
           this.posted = false
           this.return_rack = ''
           this.return_qty = 0
           this.resetSelectedReturnLot()
       },


      async fetchRackInfo(lot_name) {

          if (lot_name) {
            let url = ''
            if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                    url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
                } else {
                    url = process.env.VUE_APP_BACKEND_URL
                }
            const res = await fetch(url + 'api/v2/lot/'+ this.return_location_id +'/'+ lot_name +'/rack', {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
            const data = await res.json()
            return data
          }

      },

      async fetchLotRacks(location_id) {

          if (location_id) {
            let url = ''
            if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                    url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
                } else {
                    url = process.env.VUE_APP_BACKEND_URL
                }
            const res = await fetch(url + 'api/v2/racklots/'+ this.return_location_id, {
                                method: "GET",
                                headers: {
                                    'api_token': localStorage.getItem('api_token'),
                                }
                            })
            const data = await res.json()
            return data
          }

      },

      async fetchLocations() {
          let url = ''
          if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
            } else {
                url = process.env.VUE_APP_BACKEND_URL
            }
          const res = await fetch(url + 'api/v2/locations/list', {
                            method: "GET",
                            headers: {
                                'api_token': localStorage.getItem('api_token'),
                            }
                        })
          const data = await res.json()
          return data
      },

        async fetchLotData(lot_name) {

        },

        onDecode(a, b, c) {
            console.log(a, b, c);
            this.text = a;
            if (this.id) clearTimeout(this.id);
            this.id = setTimeout(() => {
                if (this.text === a) {
                this.text = "";
                }
            }, 5000);
        },
        onLoaded() {
            console.log("load");
        },

        async submitReturnLot() {
            this.submitLoading = true
            const data = {
                        "params": {
                            "product_id": this.product_code,
                            "lot_id": this.selected_return_lot,
                            "rack_id": this.return_rack,
                            "location_id": this.return_location_id,
                            "qty": this.return_qty,
                        }
                    }

            console.log(data)
            try {
                let url = ''
                if (process.env.VUE_APP_USE_CORS_PROXY == 1) {
                        url = process.env.VUE_APP_CORS_PROXY + process.env.VUE_APP_BACKEND_URL
                    } else {
                        url = process.env.VUE_APP_BACKEND_URL
                    }
                const res = await fetch(url + "api/v2/return/queue", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': '*',
                        'api_token': localStorage.getItem('api_token'),
                    },
                    body: JSON.stringify(data),
                    redirect: 'follow',
                });
                const result = await res.json()
                this.submitLoading = false
                const r = JSON.parse(result.result)

                if (r.success) {
                    this.error = false
                    this.posted = true
                } else {
                    if (r.message == 'API Token Invalid') {
                        localStorage.removeItem("api_token");
                        this.$router.push({ name: 'Login' })
                    }
                    this.error = true
                    this.message = r.message
                }

            } catch(error) {
                console.log(error)
            }
        }

  },
  async created() {

    //   console.log($store.state.return_location_id)

      if (!localStorage.hasOwnProperty('api_token')) {
        this.$router.push({ name: 'Login' })
      }
      window.scrollTo(0, 0);
      this.loading = true
      const data = await this.fetchLocations()
      if (data.success) {
          this.location_ids = data.location_list
          this.loading = false
      } else {
          this.loading = false
          localStorage.removeItem("api_token");
          this.$router.push({ name: 'Login' })
      }
      
      if ((window.history.length - this.$root.historyCount) > 0) {
          this.goBack = true
      }

  },
  mounted() {
    document.title = 'Inventory Count App - Return Inventory'
  }
}
</script>