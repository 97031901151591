<template>
    <div>
        <!-- show errors here -->
        <div v-if="error && !recentLoading" class="my-4 bg-pink-600 text-white px-3 py-3 text-center shadow-sm rounded-md flex justify-center items-center">
            <div v-if="message">
                {{message}}
            </div>
            <div v-if="!message">
                There was an error loading the recent inventory, please try reloading the page
            </div>
        </div>

        <!-- actual content/lot details here -->
        <div v-if="!error && !recentLoading">

            <div class="text-center text-pink-700 font-bold mt-4 underline">
                Recent Inventory
            </div>
            <div class="text-center text-pink-700 font-bold mt-1">
                Total Inventoried Quantity : {{total_recent_inventoried_quantity}}
            </div>

            <div v-if="total_recent_inventory">
                <div class="grid grid-cols-3 gap-2 text-center text-pink-50 mt-4">
                    <!-- table header goes here -->
                    <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
                        Lot
                    </div>
                    <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
                        Qty
                    </div>
                    <div class="bg-pink-500 py-0.5 rounded-sm shadow-sm">
                        Rack
                    </div>
                </div>

                <!-- stock content goes here -->
                <div v-for="recent in recent_inventory" :key="recent.lot_id" class="grid grid-cols-3 gap-2 text-center text-pink-50 mt-1">
                    <div v-if="recent.lot_id == lot" class="bg-pink-200 text-pink-800 py-0.5 px-1 rounded-md font-bold">
                        <span>
                            {{recent.lot_id}}
                        </span>
                    </div>
                    <div v-else class="bg-pink-200 text-pink-800 py-0.5 px-1 rounded-md">
                        <span>
                            {{recent.lot_id}}
                        </span>
                    </div>

                    <div v-if="recent.lot_id == lot" class="bg-pink-200 text-pink-800 py-0.5 px-1 rounded-md font-bold">
                        <span>
                            {{recent.qty}}
                        </span>
                    </div>
                    <div v-else class="bg-pink-200 text-pink-800 py-0.5 px-1 rounded-md">
                        <span>
                            {{recent.qty}}
                        </span>
                    </div>


                    <div class="bg-pink-200 text-pink-800 py-0.5 px-1 rounded-md">
                        <span>{{recent.rack_id}}</span>
                    </div>
                </div>
            </div>
            <div v-if="!total_recent_inventory">
                <div class="my-4 bg-pink-600 text-white px-3 py-3 text-center shadow-sm rounded-md flex justify-center items-center">
                    There is no recent inventory for this product
                </div>
            </div>
            

        </div>

        <!-- loading part here -->
        <div class="my-4 bg-pink-600 text-white px-3 py-3 text-center shadow-sm rounded-md flex justify-center items-center" v-if="recentLoading">
            <svg class="animate-spin h-5 w-5 text-white mx-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading all the recent inventory done by other members for this product today
        </div>

        
    </div>
</template>

<script>
export default {
    name: 'GetProductRecentInventory',
    props: ['lot', 'recentLoading', 'error', 'message', 'recent_inventory', 'total_recent_inventory', 'total_recent_inventoried_quantity'],
    data() {
        return {

        }
    },
    methods: {
        
    },

}
</script>